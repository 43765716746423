import { DefaultTheme } from 'styled-components';

export const sleepioTheme: DefaultTheme = {
  checkBox: {
    backgroundColor: '#F1F9FF',
    color: '#163258',
  },
  infoBox: {
    backgroundColor: '#F1F9FF',
    textColor: '#163258',
  },
  link: {
    color: '#363334',
    fontWeight: 400,
  },
  button: {
    primary: {
      backgroundColor: '#FF8E75',
      disabledBackgroundColor: '#898989',
      textColor: '#163258',
      disabledTextColor: '#FFF',
    },
  },
  colors: {
    primaryNuetral: '#F7F6F5',
  },
};
