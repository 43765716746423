import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Styled from '../ResetPasswordForm/ResetPasswordForm.styles';

type StateType = {
  redirectURL: string;
};

const ResetPasswordForm : React.FC = () => {
  const { state: { redirectURL } } = useLocation<StateType>();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => { window.location.href = redirectURL; }, 3000);
  }, []);

  return (
    <Styled.MainContainer>
      <p>
        {`${t('reset_password_form.redirect_1')} `}
        <a href={redirectURL}>{t('reset_password_form.here')}</a>
        {` ${t('reset_password_form.redirect_2')}`}
      </p>
    </Styled.MainContainer>
  );
};

export default ResetPasswordForm;
