import React from 'react';
import { Popover } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Styled from './OnboardingPopover.styles';

import { CTAButton } from '../CTAButton/CTAButton';

import { useWindowDimensions } from '@/hooks/useWindowDimensions';

interface OnboardingPopoverProps {
    popoverOpen: boolean;
    setPopoverOpen: (arg0: boolean) => void;
}

export const OnboardingPopover: React.FC<OnboardingPopoverProps> = ({
  popoverOpen,
  setPopoverOpen,
  children,
}) => {
  const { width } = useWindowDimensions();
  if (width <= 600 && popoverOpen === true) {
    return (
      <Styled.MobilePopover>
        <CloseIcon
          fontSize="large"
          style={{
            position: 'absolute',
            right: '24px',
            top: '32px',
          }}
          onClick={() => setPopoverOpen(false)}
        />
        {children}
        <CTAButton
          text="Close"
          isDisabled={false}
          handleClick={() => setPopoverOpen(false)}
        />
      </Styled.MobilePopover>
    );
  }
  return (
    <Popover
      style={{
        background: 'rgba(0, 0, 0, 0.30)',
      }}
      open={popoverOpen}
      onClose={() => setPopoverOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <Styled.PopoverDesktopContainer>
        <Styled.CloseButtonWrapper>
          <CloseIcon
            fontSize="large"
            style={{
              position: 'absolute',
              right: '24px',
              top: '32px',
            }}
            onClick={() => setPopoverOpen(false)}
          />
        </Styled.CloseButtonWrapper>
        {children}
        <CTAButton
          text="Close"
          isDisabled={false}
          handleClick={() => setPopoverOpen(false)}
        />
      </Styled.PopoverDesktopContainer>
    </Popover>
  );
};
