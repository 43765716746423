import React from 'react';

import Styled from './Titles.styles';

interface TitlesProps {
    title: string,
    subtitle?: string,
}

export const Titles: React.FC<TitlesProps> = ({
  title,
  subtitle,
}) => (
  <>
    <Styled.Title>{title}</Styled.Title>
    {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
  </>
);
