import { Box } from '@bighealth/react-limbix-ui';
import styled from 'styled-components';

const FooterContainer = styled(Box)`
    font-family: "GT Walsheim";
    padding: 40px 0px;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export default {
  FooterContainer,
};
