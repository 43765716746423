import React, { forwardRef, Ref } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const PhoneInputRef = forwardRef<Ref<HTMLInputElement>, TextFieldProps>((props, ref) => (
  <TextField
    {...props}
    className="input-field"
    variant="outlined"
    margin="normal"
    fullWidth
    required
    id="patientPhoneNumber"
    inputRef={ref}
    label="Phone Number"
  />
));

type PhoneNumberInputProps = {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  error: boolean;
  helperText: string;
};

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  helperText,
}) => (
  <PhoneInput
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    defaultCountry="US"
    countryOptionsOrder={['US', 'GB']}
    inputComponent={PhoneInputRef}
    numberInputProps={{ error, helperText }}
  />
);
