import React from 'react';

import Styled from './CoverageRejection.styles';

import LimbotRainyDay from '@/assets/LimbotRainyDay.svg';

type CoverageRejectionProps = {
  isCaregiver: boolean;
};

export const CoverageRejection: React.FC<CoverageRejectionProps> = ({ isCaregiver }) => {
  const caregiverText = 'Your dependent is not eligible for Spark Direct through your insurance or employer.';
  const patientText = 'You are not eligible for Spark Direct through your insurance or employer.';
  return (
    <>
      <Styled.Title>
        We’re sorry!
      </Styled.Title>
      <Styled.Text>
        { isCaregiver ? caregiverText : patientText }
      </Styled.Text>
      <Styled.Text>
        {
          `If you have any questions or need technical support, 
          please contact us at spark@bighealth.com`
        }
      </Styled.Text>

      <Styled.Gap />
      <Styled.ImageContainer>
        <Styled.LimbotRainyDay src={LimbotRainyDay} />
      </Styled.ImageContainer>
      <Styled.Gap />
      <Styled.Subtitle>
        Experiencing a mental health emergency?
      </Styled.Subtitle>
      <Styled.Text>
        Spark Direct is not an emergency service provider.
        {' '}
        If you are currently experiencing a mental health emergency or
        {' '}
        having thoughts of self harm and suicide, please call 911 or go to the nearest emergency room.
      </Styled.Text>
      <Styled.Text>
        You can also
        {' '}
        <Styled.BoldText>call or text 988,</Styled.BoldText>
        {' '}
        a 24/7 suicide and crisis lifeline. They will connect
        {' '}
        you to a
        {' '}
        <Styled.BoldText>trained crisis worker</Styled.BoldText>
        {' '}
        who can help you find support right away.
      </Styled.Text>
      <Styled.Text>
        If you are not in immediate danger but would like additional help, you can call
        {' '}
        or text a trained crisis worker who can give you immediate support. The services
        {' '}
        below are free and available 24/7, unless noted otherwise:
      </Styled.Text>
      <Styled.SmallTitle>
        988 Suicide and Crisis Lifeline (includes self-harm):
      </Styled.SmallTitle>
      <Styled.Text>
        Call
        <Styled.PhoneTag href="tel:988"> 988</Styled.PhoneTag>
        <br />
        Chat online
        <br />
        <Styled.AnchorTag href="https://988lifeline.org/chat/" target="_blank">
          https://988lifeline.org/chat/
        </Styled.AnchorTag>
        .
      </Styled.Text>
      <Styled.SmallTitle>Crisis Text Line:</Styled.SmallTitle>
      <Styled.Text>
        Text
        {' '}
        <Styled.BoldText>HOME</Styled.BoldText>
        {' '}
        to
        {' '}
        <Styled.PhoneTag href="tel:741741">741741</Styled.PhoneTag>
      </Styled.Text>
      <Styled.SmallTitle>
        Disaster Distress Helpline (natural disasters, terrorism, COVID-19)
      </Styled.SmallTitle>
      <Styled.Text>
        Call
        {' '}
        <Styled.PhoneTag href="tel:1-800-985-5990">
          1-800-985-5990
        </Styled.PhoneTag>
      </Styled.Text>
      <Styled.MediumTitle>
        Resources for trans people of all ages
      </Styled.MediumTitle>
      <Styled.SmallTitle>
        Trans Lifeline
      </Styled.SmallTitle>
      <Styled.Text>
        Not available 24/7. If you are not able to get through, consider trying a different service listed on this page
        Call
        {' '}
        <Styled.PhoneTag href="tel:1-877-565-8860">1-877-565-8860</Styled.PhoneTag>
      </Styled.Text>
      <Styled.MediumTitle>
        Resources for LGBTQ teens and youth
      </Styled.MediumTitle>
      <Styled.SmallTitle>The Trevor Project</Styled.SmallTitle>
      <Styled.Text>
        Call
        {' '}
        <Styled.PhoneTag href="tel:1-866-488-7386">1-866-488-7386</Styled.PhoneTag>
        <br />
        Text
        {' '}
        <Styled.BoldText>START</Styled.BoldText>
        {' '}
        to
        {' '}
        <Styled.PhoneTag href="tel:678-678">678-678</Styled.PhoneTag>
        <br />
        Chat online at:
        <br />
        <Styled.AnchorTag href="www.thetrevorproject.org/get-help/">
          www.thetrevorproject.org/get-help/
        </Styled.AnchorTag>
        .
      </Styled.Text>
      <Styled.MediumTitle>
        For young people of color
      </Styled.MediumTitle>
      <Styled.SmallTitle>
        Crisis Text Line
      </Styled.SmallTitle>
      <Styled.Text>
        Text
        {' '}
        <Styled.BoldText>
          STEVE
        </Styled.BoldText>
        {' '}
        to
        {' '}
        <Styled.PhoneTag href="tel:741741">
          741741
        </Styled.PhoneTag>
      </Styled.Text>
      <Styled.MediumTitle>
        Additional resources for non urgent needs
      </Styled.MediumTitle>
      <Styled.Text>
        Spark Direct was not designed to replace depression treatments, but to help you live well with depression.
      </Styled.Text>
      <Styled.Text>
        {/* eslint-disable-next-line max-len */}
        If you’re looking for treatment options to use alongside Spark Direct, you can find information in the following places:
      </Styled.Text>
      <Styled.SmallTitle>
        The National Institute of Mental Health:
      </Styled.SmallTitle>
      <Styled.AnchorTag href="https://www.nimh.nih.gov/health/publications/depression-listing">
        https://www.nimh.nih.gov/health/publications/depression-listing
      </Styled.AnchorTag>
      <Styled.SmallTitle>
        The American Psychological Association:
      </Styled.SmallTitle>
      <Styled.AnchorTag href="www.apa.org/topics/depression">
        www.apa.org/topics/depression
      </Styled.AnchorTag>
      <Styled.SmallTitle>
        The American Academy of Child and Adolescent Psychiatry:
      </Styled.SmallTitle>
      {/* eslint-disable-next-line max-len */}
      <Styled.AnchorTag href="www.aacap.org/AACAP/Families_and_Youth/Resource_Centers/Depression_Resource_Center/Depression_Resource_Center.aspx">
        {/* eslint-disable-next-line max-len */}
        www.aacap.org/AACAP/Families_and_Youth/Resource_Centers/Depression_Resource_Center/Depression_Resource_Center.aspx
      </Styled.AnchorTag>
      <Styled.SmallTitle>
        The National Alliance on Mental Illness:
      </Styled.SmallTitle>
      <Styled.AnchorTag href="www.nami.org/Your-Journey/Teens-Young-Adults">
        www.nami.org/Your-Journey/Teens-Young-Adults
      </Styled.AnchorTag>

      <Styled.Text>
        {`The third-party service provider resources provided above are not owned or controlled by Big Health. 
          Big Health is not responsible for any actions or inactions between you and any third-party service provider. 
          By using or visiting any of the third-party service provider resources you acknowledge and agree that 
          Big Health is not responsible for and expressly disclaims all liability for the actions or inactions of any 
          third-party service provider.`}
      </Styled.Text>
    </>
  );
};
