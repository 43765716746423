import styled from 'styled-components/macro';
import { Button, TextField } from '@material-ui/core';
import { Box } from '@bighealth/react-limbix-ui';

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  padding: 56px 102px 56px 102px;
  @media only screen and (max-width: 600px) {
    padding: 24px;
  }
`;

const StyledForm = styled.form`
  width: 100%;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 30px;
`;

const FormTextField = styled(TextField)`
  padding: 0;
  margin-bottom: 20px;
`;

const HeaderTextContainer = styled(Box)`
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
`;

const FormContentContainer = styled(Box)`
  flex-direction: column;
  display: flex;
`;

export default {
  FormContainer,
  StyledForm,
  SubmitButton,
  FormTextField,
  HeaderTextContainer,
  FormContentContainer,
};
