import styled from 'styled-components/macro';
import { Box } from '@bighealth/react-limbix-ui';

import KaiserSignupForm from './KaiserSignupForm';

const PageContainer = styled(Box)`
  align-items: center;
  justify-content: center;
  background-color: #F8F8F8;
  display: flex;
  flex-direction: column;
`;

const PageContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100vh;
  width: 100%;
  background-color: #EFFBF8;
`;

const BackgroundGraphicContainer = styled(Box)`
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  display: flex;
`;

const KaiserForm = styled(KaiserSignupForm)`
  max-width: 684px;
  min-width: 550px;
  z-index: 100;
  margin-bottom: 56px;
  @media only screen and (max-width: 600px) {
    width: 100%;
    min-width: 0;
    margin-bottom: 0px;
  }
`;

const SignupContentContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 50px;
  overflow: scrollY;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding-top: 0;
  }
`;

const SignupContentOuterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 1024px;
  @media only screen and (max-width: 600px) {
    height: 100%;
  }
`;

const LogoBanner = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: white;
  align-self: stretch;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const TextContainer = styled(Box)`
  padding: 24px 124px 24px 0;
  max-width: 684px;
  z-index: 100;
  @media only screen and (max-width: 600px) {
    padding: 40px 24px 40px 24px;
  }
`;

const SparkRxLogo = styled.img`
  width: 145px;
  margin-right: 54px;
  @media only screen and (max-width: 600px) {
    margin-right: 0;
  }
`;

const KaiserLogo = styled.img`
  width: 266px;
`;

const LabelingTextContainer = styled(Box)`
  padding: 32px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    padding: 20px 24px 20px 24px;
  }
`;

const BackgroundGraphic = styled.img`
  width: 100%;
  align-self: flex-end;
  position: absolute;
  @media only screen and (max-width: 600px) {
    width: 0;
  }
`;

const MainContainer = styled(Box)`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const MainContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 684px;
  align-items: center;
  margin: 0 16px 0 16px;
`;

const AccountCreatedHeaderText = styled.p`
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin: 0 0 16px 0;
  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const AccountCreatedGraphic = styled.img`
  width: 280px;
  margin-bottom: 48px;
`;

const InvalidURLGraphic = styled.img`
  width: 172px;
  margin-bottom: 48px;
`;

const VerifyEmailGraphic = styled.img`
  width: 306px;
  margin-bottom: 48px;
`;

export default {
  PageContainer,
  PageContentContainer,
  BackgroundGraphicContainer,
  BackgroundGraphic,
  KaiserForm,
  LogoBanner,
  SignupContentContainer,
  TextContainer,
  SparkRxLogo,
  SignupContentOuterContainer,
  LabelingTextContainer,
  MainContainer,
  MainContentContainer,
  KaiserLogo,
  AccountCreatedGraphic,
  InvalidURLGraphic,
  VerifyEmailGraphic,
  AccountCreatedHeaderText,
};
