import React, { ReactElement } from 'react';

import { CaregiverInfo } from './steps/CaregiverInfo/CaregiverInfo';
import { CheckCoverage } from './steps/CheckCoverage/CheckCoverage';
import { CoverageConfirmation } from './steps/CoverageConfirmation/CoverageConfirmation';
import { PatientInfo } from './steps/PatientInfo/PatientInfo';
import { TextMessageSent } from './steps/TextMessageSent/TextMessageSent';
import { WhoAreYouEnrolling } from './steps/WhoAreYouEnrolling/WhoAreYouEnrolling';

type Flow = {
    element: ReactElement,
    breadCrumb?: string,
}[];

export const defaultSleepioFlow: Flow = [
  {
    element: <CheckCoverage />,
  },
  {
    element: <CoverageConfirmation />,
  },
  {
    element: <PatientInfo />,
  },
  {
    element: <TextMessageSent />,
  },
];

export const defaultCaregiverFlow: Flow = [
  {
    element: <WhoAreYouEnrolling />,
    breadCrumb: 'coverage.breadcrumbs.coverage',
  },
  {
    element: <CheckCoverage />,
    breadCrumb: 'coverage.breadcrumbs.coverage',
  },
  {
    element: <CoverageConfirmation />,
    breadCrumb: 'coverage.breadcrumbs.coverage',
  },
  {
    element: <PatientInfo />,
    breadCrumb: 'coverage.breadcrumbs.patient_info',
  },
  {
    element: <CaregiverInfo />,
    breadCrumb: 'coverage.breadcrumbs.caregiver_info',
  },
  {
    element: <TextMessageSent />,
    breadCrumb: 'coverage.breadcrumbs.caregiver_info',
  },
];

export const defaultPatientFlow: Flow = [
  {
    element: <WhoAreYouEnrolling />,
    breadCrumb: 'coverage.breadcrumbs.coverage',
  },
  {
    element: <CheckCoverage />,
    breadCrumb: 'coverage.breadcrumbs.coverage',
  },
  {
    element: <CoverageConfirmation />,
    breadCrumb: 'coverage.breadcrumbs.coverage',
  },
  {
    element: <PatientInfo />,
    breadCrumb: 'coverage.breadcrumbs.info',
  },
  {
    element: <TextMessageSent />,
    breadCrumb: 'coverage.breadcrumbs.download',
  },
];
