import { gql } from '@apollo/client';

export const REQUEST_TOKEN_MUTATION = gql`
  mutation RequestToken($email: String!, $password: String!, $clientType: String!) {
    tokenAuth(email: $email, password: $password, clientType: $clientType) {
      token,
      refreshToken,
      user {
        uid,
        emailVerified,
        firstName,
        language
      }
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token,
      refreshToken
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
      resetPassword(email: $email) {
          success
      }
  }
  `;

export const UPDATE_PASSWORD = gql`
  mutation ResetPassword($userUid: String!, $newPassword: String!, $token: String!) {
      updatePassword(userUid: $userUid, newPassword: $newPassword, token: $token) {
          redirectUrl
      }
  }
`;

export const REPORT_PARTICIPANT_ASSESSMENT_RESULT_MUTATION = gql`
    mutation(
      $pdtUid: String!,
      $assessmentUid: String!,
      $assessmentAnswers: [String]!,
      $assignmentUid: String!,
      $assignmentCompleteTime: DateTime,
    ) {
        reportParticipantAssessmentResult(
            pdtUid: $pdtUid,
            assessmentUid: $assessmentUid,
            assessmentAnswers: $assessmentAnswers,
            assignmentUid: $assignmentUid,
            assignmentCompleteTime: $assignmentCompleteTime,
        ) {
            participantAssessmentResult {
                uid
            }
        }
    }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail($email: String!) {
    resendEmailVerification(email: $email) {
      success
    }
  }
`;

export const ENROLL_PATIENT = gql`
  mutation enrollDirectAccessPatient(
    $email: String!,
    $phone: String!,
    $firstName: String!,
    $lastName: String!,
    $dateOfBirth: DateTime!,
    $pdtUid: String!,
    $organizationSlug: String!,
    $address: String!,
    $state: String!,
    $city: String!,
    $postalCode: String!,
    $emergencyContactName: String,
    $emergencyContactEmail: String,
    $emergencyContactPhone: String,
    $uid: String,
    $safetyAcknowledgedAt: String,
    $termsAndPrivacyAcknowledgedAt: String,
  ) {
    enrollDirectAccessPatient(
      email: $email,
      phone: $phone,
      firstName: $firstName,
      lastName: $lastName,
      dateOfBirth: $dateOfBirth,
      pdtUid: $pdtUid,
      organizationSlug: $organizationSlug,
      address: $address,
      state: $state,
      city: $city,
      postalCode: $postalCode,
      emergencyContactName: $emergencyContactName,
      emergencyContactEmail: $emergencyContactEmail,
      emergencyContactPhone: $emergencyContactPhone,
      uid: $uid,
      safetyAcknowledgedAt: $safetyAcknowledgedAt,
      termsAndPrivacyAcknowledgedAt: $termsAndPrivacyAcknowledgedAt
    ) {
      success
    }
  }

`;
