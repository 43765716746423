import styled from 'styled-components';

const AnchorTag = styled.a`
    font-family: "GT Walsheim";
    color: #363334;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
`;

export default {
  AnchorTag,
};
