import React, { ReactElement, Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import Styled from './BreadCrumbNav.styles';

import BlackCheckMark from '@/assets/BlackCheckMark.svg';
import { useWindowDimensions } from '@/hooks/useWindowDimensions';

interface BreadCrumbNavProps {
    steps: {
        element: ReactElement,
        breadCrumb?: string,
    }[]
    currentStep: {
        element: ReactElement,
        breadCrumb?: string,
    }
    currentStepIndex: number
}

export const BreadCrumbNav: React.FC<BreadCrumbNavProps> = ({ steps, currentStep, currentStepIndex }) => {
  const breadCrumbs: { [key: string]: 'active' | 'incomplete' | 'complete'; } = {};
  const breadCrumbsArr: string[] = [];
  breadCrumbs[currentStep.breadCrumb] = 'active';
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const mobileTextStyle = {
    display: (width < 600) ? 'none' : '',
  };

  steps.forEach((step, ind) => {
    if (!breadCrumbsArr.includes(step.breadCrumb)) {
      breadCrumbsArr.push(step.breadCrumb);
    }
    if (!breadCrumbs[step.breadCrumb]) {
      if (ind > currentStepIndex) {
        breadCrumbs[step.breadCrumb] = 'incomplete';
      } else {
        breadCrumbs[step.breadCrumb] = 'complete';
      }
    }
  });

  return (
    <Styled.BreadCrumbsContainer>
      {breadCrumbsArr.map((breadCrumb, ind, arr) => {
        if (breadCrumbs[breadCrumb] === 'active') {
          return (
            <Fragment key={`${breadCrumb}`}>
              <Styled.BreadCrumbContainer>
                <Styled.ActiveBreadCrumbIcon>{ind + 1}</Styled.ActiveBreadCrumbIcon>
                <Styled.BreadCrumbText>{t(breadCrumb)}</Styled.BreadCrumbText>
              </Styled.BreadCrumbContainer>
              {arr.length - 1 !== ind && (<Styled.LargeDash>-</Styled.LargeDash>)}
            </Fragment>
          );
        }

        if (breadCrumbs[breadCrumb] === 'incomplete') {
          return (
            <Fragment key={`${breadCrumb}`}>
              <Styled.BreadCrumbContainer>
                <Styled.IncompleteBreadCrumbIcon>{ind + 1}</Styled.IncompleteBreadCrumbIcon>
                <Styled.BreadCrumbText
                  style={mobileTextStyle}
                >
                  {t(breadCrumb)}
                </Styled.BreadCrumbText>
              </Styled.BreadCrumbContainer>
              {arr.length - 1 !== ind && (<Styled.LargeDash>-</Styled.LargeDash>)}
            </Fragment>
          );
        }

        if (breadCrumbs[breadCrumb] === 'complete') {
          return (
            <Fragment key={`${breadCrumb}`}>
              <Styled.BreadCrumbContainer>
                <Styled.BreadCrumbCompleteIcon src={BlackCheckMark} />
                <Styled.BreadCrumbText
                  style={mobileTextStyle}
                >
                  {t(breadCrumb)}
                </Styled.BreadCrumbText>
              </Styled.BreadCrumbContainer>
              {arr.length - 1 !== ind && (<Styled.LargeDash>-</Styled.LargeDash>)}
            </Fragment>
          );
        }

        return <></>;
      })}
    </Styled.BreadCrumbsContainer>
  );
};
