import React from 'react';
import { Text } from '@bighealth/react-limbix-ui';

import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { OnboardingPopover } from '../OnboardingPopover';

import { OnboardingParams } from '@/pages/Onboarding/types';
import { getProductEmail } from '@/pages/Onboarding/utils/productHelpers';

const PopoverContent: React.FC<{ personEnrolling: string }> = ({ personEnrolling }) => {
  const { product } = useParams<OnboardingParams>();
  const { t } = useTranslation();
  return (
    <>
      <Text
        fontFamily="GT Walsheim"
        color="#363334"
        fontSize="24px"
        fontWeight="700"
      >
        {t(`coverage.${product}.${personEnrolling}.info_popup.title`)}
      </Text>
      <Text
        fontFamily="GT Walsheim"
        color="#363334"
        fontSize="18px"
        marginTop="24px"
      >
        {
          t(`coverage.${product}.${personEnrolling}.info_popup.disclose`)
        }
      </Text>
      <ul
        style={{
          fontFamily: 'GT Walsheim',
          color: '#363334',
          fontWeight: '600',
          fontSize: '18px',
          marginTop: '24px',
        }}
      >
        <li>{t(`coverage.${product}.${personEnrolling}.info_popup.disclose_list.0`)}</li>
        <li>{t(`coverage.${product}.${personEnrolling}.info_popup.disclose_list.1`)}</li>
      </ul>
      <Text
        fontFamily="GT Walsheim"
        color="#363334"
        fontSize="18px"
        marginTop="24px"
      >
        {t(`coverage.${product}.${personEnrolling}.info_popup.alert`)}
        {' '}
        <b>
          {t(`coverage.${product}.${personEnrolling}.info_popup.safety_risk`)}
        </b>
      </Text>
      <Text
        fontFamily="GT Walsheim"
        color="#363334"
        fontSize="18px"
        marginTop="24px"
      >
        {t(`coverage.${product}.${personEnrolling}.info_popup.questions`)}
        {' '}
        {getProductEmail(product)}
      </Text>
    </>
  );
};

interface WhyDoWeNeedThisInfoProps {
  personEnrolling: 'patient' | 'caregiver';
  popoverOpen: boolean;
  setPopoverOpen: (arg0: boolean) => void;
}

export const WhyDoWeNeedThisInfo: React.FC<WhyDoWeNeedThisInfoProps> = ({
  personEnrolling,
  popoverOpen,
  setPopoverOpen,
}) => (
  <OnboardingPopover
    popoverOpen={popoverOpen}
    setPopoverOpen={setPopoverOpen}
  >
    <PopoverContent personEnrolling={personEnrolling} />
  </OnboardingPopover>
);
