import React, { CSSProperties } from 'react';
import {
  Text,
} from '@bighealth/react-limbix-ui';
import { useTranslation } from 'react-i18next';

import Styled from './KaiserSignupPage.styles';

import InvalidURLGraphic from '@/assets/InvalidURLGraphic.svg';

type Props = {
  style?: CSSProperties;
};

export default ({ style }: Props) => {
  const { t } = useTranslation();
  return (
    <Styled.MainContainer style={style}>
      <Styled.MainContentContainer>
        <Styled.InvalidURLGraphic src={InvalidURLGraphic} alt="invalid-url-graphic" />
        <Text textAlign="center" fontSize="32px" fontWeight={700} marginBottom="16px">
          {t('kaiser_signup.invalid_url')}
        </Text>
        <Text textAlign="center" fontSize="20px">
          {t('kaiser_signup.contact_kaiser_provider')}
        </Text>
      </Styled.MainContentContainer>
    </Styled.MainContainer>
  );
};
