import { Buffer } from 'buffer';

import { isValidMMDDYYYYDate } from './stringValidators';

export const stringToBase64 = (data: string): string => (
  Buffer.from(data).toString('base64')
);

export const base64ToString = (data: string): string => (
  Buffer.from(data, 'base64').toString('ascii')
);

export const convertDOBToDateString = (date: string) => {
  if (isValidMMDDYYYYDate(date)) {
    const dateArr = date.split('/');
    const day = parseInt(dateArr[1], 10);
    const month = parseInt(dateArr[0], 10) - 1;
    const year = parseInt(dateArr[2], 10);
    const UTCDate = new Date(year, month, day);
    const offset = UTCDate.getTimezoneOffset() * 60000;
    return new Date(UTCDate.getTime() - offset).toISOString();
  }
  return `${date} was not able to be parsed into a Date string`;
};
