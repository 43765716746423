import React from 'react';
import { LoadingSpinner } from '@bighealth/react-limbix-ui';

import Styled from './CTAButton.styles';

interface CTAButtonProps {
    text: string;
    isDisabled: boolean;
    handleClick: () => void;
    showLoading?: boolean;
    fullWidth?: boolean;
}

export const CTAButton: React.FC<CTAButtonProps> = ({
  text,
  isDisabled,
  handleClick,
  showLoading = false,
  fullWidth = false,
}) => (
  // TODO: Replace Loading spinner with something better
  <Styled.ButtonContainer>

    <Styled.Button
      disabled={isDisabled || showLoading}
      onClick={handleClick}
      style={{ width: fullWidth ? '100%' : 'fit-content' }}
    >
      <Styled.Text disabled={isDisabled || showLoading}>
        {showLoading ? (
          <LoadingSpinner size="30px" />
        ) : (
          text
        )}
      </Styled.Text>
    </Styled.Button>
  </Styled.ButtonContainer>

);
