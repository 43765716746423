import React, { useContext } from 'react';

import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Titles } from '../../components/Titles/Titles';
import { CTAButton } from '../../components/CTAButton/CTAButton';
import { ListBoxWithIcons } from '../../components/ListBoxWithIcons/ListBoxWithIcons';
import { OnboardingFlowContext } from '../../OnboardingFlowContext';

import { OnboardingParams } from '../../types';

import { SleepioWhatsNext } from './SleepioWhatsNext';

import AnswerQuestionsIcon from '@/assets/AnswerQuestionsIcon.svg';
import BenefitsOfSparkIcon from '@/assets/BenefitsOfSparkIcon.svg';
import DownloadTheAppIcon from '@/assets/DownloadTheAppIcon.svg';

export const CoverageConfirmation: React.FC = () => {
  const { next, onboardingFormData } = useContext(OnboardingFlowContext);
  const { product } = useParams<OnboardingParams>();
  const { t } = useTranslation();
  let listBox = (
    <ListBoxWithIcons
      title={t(`coverage.${product}.${onboardingFormData.personEnrolling}.coverage_confirmation.list_title`)}
      listItems={[
        {
          icon: AnswerQuestionsIcon,
          listText: t(`coverage.${product}.${onboardingFormData.personEnrolling}.coverage_confirmation.list_item0`),
        },
        {
          icon: DownloadTheAppIcon,
          listText: t(`coverage.${product}.${onboardingFormData.personEnrolling}.coverage_confirmation.list_item1`),
        },
        {
          icon: BenefitsOfSparkIcon,
          listText: t(`coverage.${product}.${onboardingFormData.personEnrolling}.coverage_confirmation.list_item2`),
        },
      ]}
    />
  );

  if (product === 'sleepio') {
    listBox = <SleepioWhatsNext />;
  }

  return (
    <>
      <Titles
        title={t(`coverage.${product}.${onboardingFormData.personEnrolling}.coverage_confirmation.title`)}
        subtitle={t(`coverage.${product}.${onboardingFormData.personEnrolling}.coverage_confirmation.subtitle`)}
      />
      {listBox}
      <CTAButton
        text={t('coverage.continue')}
        handleClick={next}
        isDisabled={false}
      />
    </>
  );
};
