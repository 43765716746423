/* eslint-disable */
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
  GenericScalar: any;
  JSONString: string;
  TranslationString: string;
};

/** Activate a study participant */
export type ActivateParticipant = {
  __typename?: 'ActivateParticipant';
  /** Whether the study participant was activated successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Add a CoInvestigator */
export type AddStudyCoInvestigator = {
  __typename?: 'AddStudyCoInvestigator';
  /** Whether the Study CoInvestigator was addedd successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Add a Study Coordinator */
export type AddStudyCoordinator = {
  __typename?: 'AddStudyCoordinator';
  /** Whether the study coordinator was addedd successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Adverse event reported during use of a PDT */
export type AdverseEventType = {
  __typename?: 'AdverseEventType';
  description?: Maybe<Scalars['String']>;
  isCausedByTreatment?: Maybe<Scalars['Boolean']>;
  severity?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

export type AnswerInputType = {
  assessmentValue?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<TextTranslationInputType>;
  uid?: InputMaybe<Scalars['String']>;
};

/** A potential answer to a question on an assessment */
export type AnswerType = {
  __typename?: 'AnswerType';
  assessmentValue?: Maybe<Scalars['Int']>;
  /** Answer text */
  text?: Maybe<Scalars['TranslationString']>;
  /** Answer text translations */
  textTranslations?: Maybe<TextTranslationType>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};


/** A potential answer to a question on an assessment */
export type AnswerTypeTextArgs = {
  language?: InputMaybe<Scalars['String']>;
  withFallback?: InputMaybe<Scalars['Boolean']>;
};

export type AssessmentInputType = {
  contextText?: InputMaybe<TextTranslationInputType>;
  introText?: InputMaybe<TextTranslationInputType>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<TextTranslationInputType>;
  nameInternal?: InputMaybe<Scalars['String']>;
  preQuestionText?: InputMaybe<TextTranslationInputType>;
  questions?: InputMaybe<Array<InputMaybe<QuestionInputType>>>;
  uid?: InputMaybe<Scalars['String']>;
};

/** Result of an Researcher completing an assessment */
export type AssessmentResultItemType = {
  __typename?: 'AssessmentResultItemType';
  /** The numerical assessment value of the response (for scoring) */
  assessmentValue?: Maybe<Scalars['Int']>;
  dateValue?: Maybe<Scalars['DateTime']>;
  question: QuestionType;
  selectedAnswers: Array<AnswerType>;
  textValue?: Maybe<FreeFormTextType>;
  total?: Maybe<Scalars['Int']>;
  /** The answer value as a string */
  value?: Maybe<Scalars['String']>;
};

/** Result of an individual completing an assessment */
export type AssessmentResultType = {
  __typename?: 'AssessmentResultType';
  answers?: Maybe<Scalars['JSONString']>;
  assessment?: Maybe<AssessmentType>;
  assessmentAnswers?: Maybe<Array<Maybe<Scalars['String']>>>;
  assessmentResultItems?: Maybe<Array<Maybe<AssessmentResultItemType>>>;
  assignment?: Maybe<StudyAssignmentsTableType>;
  createdAt: Scalars['DateTime'];
  pdt?: Maybe<PdtType>;
  score?: Maybe<Scalars['Float']>;
  scores?: Maybe<Array<Maybe<Scalars['Float']>>>;
  uid: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Assessment that a study participant or user takes */
export type AssessmentType = {
  __typename?: 'AssessmentType';
  /** The context text for the top of the assessment */
  contextText?: Maybe<Scalars['TranslationString']>;
  contextTextTranslations?: Maybe<TextTranslationType>;
  /** The intro text for the top of the assessment */
  introText?: Maybe<Scalars['TranslationString']>;
  introTextTranslations?: Maybe<TextTranslationType>;
  isArchived: Scalars['Boolean'];
  /** Whether the assessment is being used by a model that locks changes */
  isLocked?: Maybe<Scalars['Boolean']>;
  /** The name of the assessment */
  name?: Maybe<Scalars['TranslationString']>;
  nameInternal: Scalars['String'];
  nameTranslations?: Maybe<TextTranslationType>;
  /** The pre question text for the top of the assessment */
  preQuestionText?: Maybe<Scalars['TranslationString']>;
  preQuestionTextTranslations?: Maybe<TextTranslationType>;
  /** Questions that linked with an assessment */
  questions?: Maybe<Array<Maybe<QuestionType>>>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};


/** Assessment that a study participant or user takes */
export type AssessmentTypeContextTextArgs = {
  language?: InputMaybe<Scalars['String']>;
  withFallback?: InputMaybe<Scalars['Boolean']>;
};


/** Assessment that a study participant or user takes */
export type AssessmentTypeIntroTextArgs = {
  language?: InputMaybe<Scalars['String']>;
  withFallback?: InputMaybe<Scalars['Boolean']>;
};


/** Assessment that a study participant or user takes */
export type AssessmentTypeNameArgs = {
  language?: InputMaybe<Scalars['String']>;
  withFallback?: InputMaybe<Scalars['Boolean']>;
};


/** Assessment that a study participant or user takes */
export type AssessmentTypePreQuestionTextArgs = {
  language?: InputMaybe<Scalars['String']>;
  withFallback?: InputMaybe<Scalars['Boolean']>;
};

/** Create and assign a clinician report to a clinical concern */
export type AssignClinicianReport = {
  __typename?: 'AssignClinicianReport';
  /** clinical concern with assigned clinician report */
  clinicalConcern?: Maybe<ClinicalConcernType>;
};

/** An enumeration. */
export enum AssignmentAccountType {
  /** Adult account */
  Adult = 'ADULT',
  /** Child account */
  Child = 'CHILD',
  /** Parent account */
  Parent = 'PARENT',
  /** Research account */
  Research = 'RESEARCH'
}

/** An enumeration. */
export enum AssignmentScheduleAccountType {
  /** Adult account */
  Adult = 'ADULT',
  /** Child account */
  Child = 'CHILD',
  /** Parent account */
  Parent = 'PARENT',
  /** Research account */
  Research = 'RESEARCH'
}

export type AssignmentScheduleInputType = {
  accountType: Scalars['String'];
  assessmentUid: Scalars['String'];
  canEditAfterCompletion: Scalars['Boolean'];
  canSkipQuestions: Scalars['Boolean'];
  count: Scalars['Int'];
  ordinal: Scalars['Int'];
  repeatFrequencyDays: Scalars['Int'];
  scheduleOnStudyState: Scalars['String'];
  sendEmailNotifications: Scalars['Boolean'];
  sendPushNotifications: Scalars['Boolean'];
  sendSmsNotifications: Scalars['Boolean'];
  startTimeOffsetDays: Scalars['Int'];
  typeOfClientForAssignment: Scalars['String'];
  typeOfNotificationForAssignment: Scalars['String'];
  uid?: InputMaybe<Scalars['String']>;
  visibleDurationDays: Scalars['Int'];
};

/** An enumeration. */
export enum AssignmentScheduleScheduleOnStudyState {
  /** Activation */
  Activation = 'ACTIVATION',
  /** Registration */
  Registration = 'REGISTRATION'
}

/** Assignment Schedule */
export type AssignmentScheduleType = {
  __typename?: 'AssignmentScheduleType';
  accountType: AssignmentScheduleAccountType;
  assessment?: Maybe<AssessmentType>;
  canEditAfterCompletion: Scalars['Boolean'];
  canSkipQuestions: Scalars['Boolean'];
  count: Scalars['Int'];
  isLocked?: Maybe<Scalars['Boolean']>;
  ordinal: Scalars['Int'];
  organization?: Maybe<OrganizationType>;
  repeatFrequencyDays?: Maybe<Scalars['Int']>;
  scheduleOnStudyState: AssignmentScheduleScheduleOnStudyState;
  sendEmailNotifications: Scalars['Boolean'];
  sendNotifications?: Maybe<Scalars['Boolean']>;
  sendPushNotifications: Scalars['Boolean'];
  sendSmsNotifications: Scalars['Boolean'];
  startTimeOffsetDays?: Maybe<Scalars['Int']>;
  study?: Maybe<LimbixStudyType>;
  total?: Maybe<Scalars['Int']>;
  typeForAssignment: AssignmentScheduleTypeForAssignment;
  typeOfClientForAssignment: AssignmentScheduleTypeOfClientForAssignment;
  typeOfNotificationForAssignment: AssignmentScheduleTypeOfNotificationForAssignment;
  uid: Scalars['String'];
  visibleDurationDays?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum AssignmentScheduleTypeForAssignment {
  /** Adverse Event */
  AdverseEvent = 'ADVERSE_EVENT',
  /** Assessment */
  Assessment = 'ASSESSMENT'
}

/** An enumeration. */
export enum AssignmentScheduleTypeOfClientForAssignment {
  /** Mobile Client */
  Mobile = 'MOBILE',
  /** Provider Portal Client */
  ProviderPortal = 'PROVIDER_PORTAL',
  /** ResOps Portal */
  ResopsPortal = 'RESOPS_PORTAL',
  /** Web Portal Client */
  WebPortal = 'WEB_PORTAL'
}

/** An enumeration. */
export enum AssignmentScheduleTypeOfNotificationForAssignment {
  /** Intra-study Notifications */
  IntraStudy = 'INTRA_STUDY',
  /** Post Study Notifications */
  PostStudy = 'POST_STUDY',
  /** Pre Study Notifications */
  PreStudy = 'PRE_STUDY'
}

/** Assignment that a user must complete by a certain time */
export type AssignmentType = {
  __typename?: 'AssignmentType';
  aboutStudyParticipant?: Maybe<StudyParticipantType>;
  aboutUser?: Maybe<UserType>;
  accountType: AssignmentAccountType;
  adverseEvent?: Maybe<AdverseEventType>;
  assessment?: Maybe<AssessmentType>;
  canEditAfterCompletion: Scalars['Boolean'];
  canSkipQuestions: Scalars['Boolean'];
  completeTime?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  ordinal: Scalars['Int'];
  organization?: Maybe<OrganizationType>;
  participantAssessmentResult?: Maybe<ParticipantAssessmentResultType>;
  pdt: PdtType;
  researchAssessmentResult?: Maybe<ResearchAssessmentResultType>;
  startTime?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  study?: Maybe<LimbixStudyType>;
  studyParticipant?: Maybe<StudyParticipantType>;
  total?: Maybe<Scalars['Int']>;
  typeOfAssignment: AssignmentTypeOfAssignment;
  typeOfClient: AssignmentTypeOfClient;
  uid: Scalars['String'];
  user?: Maybe<UserType>;
  visibleDate: Scalars['DateTime'];
  weekInProgram?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum AssignmentTypeOfAssignment {
  /** Adverse Event */
  AdverseEvent = 'ADVERSE_EVENT',
  /** Assessment */
  Assessment = 'ASSESSMENT'
}

/** An enumeration. */
export enum AssignmentTypeOfClient {
  /** Mobile Client */
  Mobile = 'MOBILE',
  /** Provider Portal Client */
  ProviderPortal = 'PROVIDER_PORTAL',
  /** ResOps Portal */
  ResopsPortal = 'RESOPS_PORTAL',
  /** Web Portal Client */
  WebPortal = 'WEB_PORTAL'
}

/** Legacy Behavioral activation task that a user engages in during use of a PDT */
export type BehavioralActivationTaskType = {
  __typename?: 'BehavioralActivationTaskType';
  activity?: Maybe<Scalars['String']>;
  completeTime?: Maybe<Scalars['DateTime']>;
  didCompleteScheduledActivation: Scalars['Boolean'];
  extraData?: Maybe<Scalars['JSONString']>;
  moduleNumber?: Maybe<Scalars['Int']>;
  scheduledDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['DateTime']>;
  taskNumber?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

/** Cancel a particular periodic push notification */
export type CancelPeriodicPushNotification = {
  __typename?: 'CancelPeriodicPushNotification';
  /** Cancelled periodic notification */
  periodicNotification?: Maybe<PeriodicPushNotificationType>;
};

/** Cancel a periodic push notification */
export type CancelPeriodicPushNotificationByType = {
  __typename?: 'CancelPeriodicPushNotificationByType';
  /** Canceled periodic notifications */
  periodicNotifications?: Maybe<Array<Maybe<PeriodicPushNotificationType>>>;
};

/** Cancel a particular push notification */
export type CancelPushNotification = {
  __typename?: 'CancelPushNotification';
  /** Cancelled notification task */
  notificationTask?: Maybe<NotificationTaskType>;
};

/** Cancel push notifications with a particular notification_type */
export type CancelPushNotificationsByType = {
  __typename?: 'CancelPushNotificationsByType';
  /** Cancelled notification tasks */
  notificationTasks?: Maybe<Array<Maybe<NotificationTaskType>>>;
};

/** Care Team */
export type CareTeamType = {
  __typename?: 'CareTeamType';
  assignments?: Maybe<Array<Maybe<AssignmentType>>>;
  canAccessPdt?: Maybe<Scalars['Boolean']>;
  completedTime?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  discontinuedTime?: Maybe<Scalars['DateTime']>;
  enrolledTime?: Maybe<Scalars['DateTime']>;
  hasMobileTokens?: Maybe<Scalars['Boolean']>;
  invitedTime?: Maybe<Scalars['DateTime']>;
  lastNudgeSentAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationType;
  patient: PatientType;
  pdt: PdtType;
  practitioners: Array<PractitionerType>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userPdtData?: Maybe<UserPdtDataType>;
};

/** Clinical Concern related to an event while enrolled in a study */
export type ClinicalConcernType = {
  __typename?: 'ClinicalConcernType';
  answers?: Maybe<Scalars['JSONString']>;
  assessment?: Maybe<AssessmentType>;
  assessmentAnswers?: Maybe<Array<Maybe<Scalars['String']>>>;
  assessmentResultItems?: Maybe<Array<Maybe<AssessmentResultItemType>>>;
  clinicianReport?: Maybe<StudyAssignmentsTableType>;
  createdAt: Scalars['DateTime'];
  study: LimbixStudyType;
  studyParticipant?: Maybe<StudyParticipantType>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: UserType;
};

/** Result of completing an assignment */
export type CompleteAssignment = {
  __typename?: 'CompleteAssignment';
  /** Assignment that was completed */
  assignment?: Maybe<AssignmentType>;
};

/** Mark a study participant as Completed */
export type CompleteParticipant = {
  __typename?: 'CompleteParticipant';
  /** Whether the study participant was completed successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Result of completing a Researchassignment */
export type CompleteResearchAssignment = {
  __typename?: 'CompleteResearchAssignment';
  /**  Research assignment that was completed */
  assignment?: Maybe<AssignmentType>;
};

/** Create user account */
export type CreateAccount = {
  __typename?: 'CreateAccount';
  /** User account that was created */
  user?: Maybe<UserType>;
};

/** Create a new Assessment with new questions, answers, etc. */
export type CreateAssessment = {
  __typename?: 'CreateAssessment';
  assessment?: Maybe<AssessmentType>;
};

/** Legacy create a behavioral activation task */
export type CreateBehavioralActivationTask = {
  __typename?: 'CreateBehavioralActivationTask';
  /** Behavioral activation user engages in during use of a PDT */
  behavioralActivationTask?: Maybe<BehavioralActivationTaskType>;
};

/** Create the base Clinical Concern object with necessary data */
export type CreateClinicalConcern = {
  __typename?: 'CreateClinicalConcern';
  clinicalConcern?: Maybe<ClinicalConcernType>;
};

/** Create free-form text */
export type CreateFreeFormText = {
  __typename?: 'CreateFreeFormText';
  /** Free form text that was created */
  freeFormText?: Maybe<FreeFormTextType>;
};

/**
 * Create clinical concerns with mock data
 *
 * Create the given number of clinical concerns for the given user. If the study has a clinical report, also create
 * mock data for the clinical report.
 */
export type CreateMockClinicalConcerns = {
  __typename?: 'CreateMockClinicalConcerns';
  /** mock clinical concerns created */
  clinicalConcerns?: Maybe<Array<Maybe<ClinicalConcernType>>>;
};

export type CreateMockStudyAssignments = {
  __typename?: 'CreateMockStudyAssignments';
  assignments?: Maybe<Array<Maybe<AssignmentType>>>;
};

/** Legacy method for creating a mood log */
export type CreateMoodLog = {
  __typename?: 'CreateMoodLog';
  /** Mood log user creates during use of a PDT */
  moodLog?: Maybe<MoodLogType>;
};

/** Create an organization with appropriate permissions (currently unused) */
export type CreateOrganization = {
  __typename?: 'CreateOrganization';
  /** The Organization that was created */
  organization?: Maybe<OrganizationType>;
};

/** Create a Patient Account (currently unused) */
export type CreatePatientAccount = {
  __typename?: 'CreatePatientAccount';
  /** The Patient that was created */
  patient?: Maybe<PatientType>;
};

/** Create a new Practitioner (currently unused) */
export type CreatePractitionerAccount = {
  __typename?: 'CreatePractitionerAccount';
  /** The Practitioner that was created */
  practitioner?: Maybe<PractitionerType>;
};

/** Create a TOTP Authenticator and require 2FA for a user account */
export type CreateTotpAuthenticator = {
  __typename?: 'CreateTOTPAuthenticator';
  /** TOTP Authenticator for the current user */
  totpAuthenticator?: Maybe<TotpAuthenticatorType>;
};

/** Create a user activity log */
export type CreateUserActivityLog = {
  __typename?: 'CreateUserActivityLog';
  /** Activity Log user engages in during use of a PDT */
  userActivityLog?: Maybe<UserActivityLogType>;
};

/** Create a log */
export type CreateUserLog = {
  __typename?: 'CreateUserLog';
  /** UserLog user creates during use of a PDT */
  userLog?: Maybe<UserLogType>;
};

/**
 * Decode an organization signup (JWT) token. This separate mutation is needed so that a token can be
 * validated before the remaining user data is collected.
 */
export type DecodeOrganizationSignupToken = {
  __typename?: 'DecodeOrganizationSignupToken';
  /** The existing careteam associated with this token and PDT (if it exists) */
  existingCareTeam?: Maybe<CareTeamType>;
  /** The decoded JSON payload */
  tokenPayload?: Maybe<Scalars['JSONString']>;
};

/** Legacy delete a behavioral activation task */
export type DeleteBehavioralActivationTask = {
  __typename?: 'DeleteBehavioralActivationTask';
  /** Behavioral activation that was deleted */
  behavioralActivationTask?: Maybe<BehavioralActivationTaskType>;
};

/** Delete a clinical concern */
export type DeleteClinicalConcern = {
  __typename?: 'DeleteClinicalConcern';
  /** clinical concern that was successfully deleted */
  clinicalConcern?: Maybe<ClinicalConcernType>;
};

/** Legacy method for deleting a mood log */
export type DeleteMoodLog = {
  __typename?: 'DeleteMoodLog';
  /** Mood log user that was deleted */
  moodLog?: Maybe<MoodLogType>;
};

/** Delete a study participant */
export type DeleteParticipant = {
  __typename?: 'DeleteParticipant';
  /** Whether the study participant was deleted successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Delete a study assignment schedule */
export type DeleteStudyAssignmentSchedule = {
  __typename?: 'DeleteStudyAssignmentSchedule';
  /** Assignment schedule that was deleted */
  assignmentSchedule?: Maybe<AssignmentScheduleType>;
};

/** Delete a user activity log */
export type DeleteUserActivityLog = {
  __typename?: 'DeleteUserActivityLog';
  /** User activity log that was deleted */
  userActivityLog?: Maybe<UserActivityLogType>;
};

/** Delete a log */
export type DeleteUserLog = {
  __typename?: 'DeleteUserLog';
  /** User log user that was deleted */
  userLog?: Maybe<UserLogType>;
};

/** Item that contains list of concerning terms */
export type DenyListItemType = {
  __typename?: 'DenyListItemType';
  terms: Array<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

/** List of deny list items */
export type DenyListType = {
  __typename?: 'DenyListType';
  denyListItems: Array<DenyListItemType>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

/** Discontinue care for a patient */
export type DiscontinueCare = {
  __typename?: 'DiscontinueCare';
  /** Whether the patients mobile access was discontinued successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename?: 'DjangoDebug';
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename?: 'DjangoDebugSQL';
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>;
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>;
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>;
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
};

/** Drop a study participant */
export type DropParticipant = {
  __typename?: 'DropParticipant';
  /** Whether the study participant was dropped successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Edit an existing Assessment and its questions, answers, etc. */
export type EditAssessment = {
  __typename?: 'EditAssessment';
  assessment?: Maybe<AssessmentType>;
};

/** Edit a participant's parents */
export type EditParents = {
  __typename?: 'EditParents';
  /** Whether the parents have been edited successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Edit a Study Participant's Notes, ID, Language, Account Type, First Name, Last Name, Gender,
 * Date of Birth, and/or Referral Source
 */
export type EditParticipantData = {
  __typename?: 'EditParticipantData';
  /** Whether the participant data was updated successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Update a Patient's Info */
export type EditPatientInfo = {
  __typename?: 'EditPatientInfo';
  /** Whether the patient info was updated successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Update a Practitioner's Notes */
export type EditPractitionerNotes = {
  __typename?: 'EditPractitionerNotes';
  /** Whether the notes were updated successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Whether a potential commercial customer is eligible to access a pdt */
export type EligibilityStatusType = {
  __typename?: 'EligibilityStatusType';
  isEligible?: Maybe<Scalars['Boolean']>;
};

export type EnrollDirectAccessPatient = {
  __typename?: 'EnrollDirectAccessPatient';
  /** The patient that was enrolled */
  patient?: Maybe<PatientType>;
  /** Whether patient was enrolled */
  success?: Maybe<Scalars['Boolean']>;
};

export type EnrollPatient = {
  __typename?: 'EnrollPatient';
  /** The Patient that was enrolled */
  patient?: Maybe<PatientType>;
  /** Whether patient was enrolled */
  success?: Maybe<Scalars['Boolean']>;
};

/** Enrollment tracker result related to a study participant */
export type EnrollmentTrackerResultType = {
  __typename?: 'EnrollmentTrackerResultType';
  answers?: Maybe<Scalars['JSONString']>;
  assessment?: Maybe<AssessmentType>;
  assessmentAnswers?: Maybe<Array<Maybe<Scalars['String']>>>;
  assessmentResultItems?: Maybe<Array<Maybe<AssessmentResultItemType>>>;
  createdAt: Scalars['DateTime'];
  study: LimbixStudyType;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: UserType;
};

/** ExpireOutdatedNudges expires any triggered nudges that are not the most recent nudge in their category. */
export type ExpireOutdatedNudges = {
  __typename?: 'ExpireOutdatedNudges';
  /** Nudges expired by the mutation */
  expiredNudges?: Maybe<Array<Maybe<NudgeOpportunityType>>>;
};

/** Renew a patient's access using an external org signup (JWT) token */
export type ExternalOrganizationAccessRenewal = {
  __typename?: 'ExternalOrganizationAccessRenewal';
  /** The Care Team that was modified */
  careTeam?: Maybe<CareTeamType>;
  /** Whether the patient's access was renewed */
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Create a patient account using an external org signup (JWT) token.
 * Note: this mutation currently does not allow users already signed up with an org to sign up again.
 */
export type ExternalOrganizationSignup = {
  __typename?: 'ExternalOrganizationSignup';
  /** The Care Team that was created */
  careTeam?: Maybe<CareTeamType>;
  /** Whether patient was created */
  success?: Maybe<Scalars['Boolean']>;
};

/** Firebase Cloud Messaging device description */
export type FcmDeviceType = {
  __typename?: 'FCMDeviceType';
  /** Inactive devices will not be sent notifications */
  active: Scalars['Boolean'];
  registrationId: Scalars['String'];
  total?: Maybe<Scalars['Int']>;
};

/** Free-form text entered by a user */
export type FreeFormTextType = {
  __typename?: 'FreeFormTextType';
  createdAt: Scalars['DateTime'];
  /** Deny list items that are matched by the free-form text */
  denyListMatches?: Maybe<Array<Maybe<DenyListItemType>>>;
  isEncrypted?: Maybe<Scalars['Boolean']>;
  participantId?: Maybe<Scalars['String']>;
  prompt?: Maybe<Scalars['String']>;
  sensitiveValue?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: UserType;
  value?: Maybe<Scalars['String']>;
};

/** Generate case report forms for a study participant */
export type GenerateCaseReportForm = {
  __typename?: 'GenerateCaseReportForm';
  /** The url for the case report form */
  url?: Maybe<Scalars['String']>;
};

/** Generate case report forms for a study */
export type GenerateCaseReportForms = {
  __typename?: 'GenerateCaseReportForms';
  /** The url for the case report forms */
  url?: Maybe<Scalars['String']>;
};

/** Generate centralized change log CSV */
export type GenerateCentralizedChangeLogCsv = {
  __typename?: 'GenerateCentralizedChangeLogCSV';
  /** The url for the centralized change log csv */
  url?: Maybe<Scalars['String']>;
};

/** Generate clinical concerns csv */
export type GenerateClinicalConcernsCsv = {
  __typename?: 'GenerateClinicalConcernsCSV';
  /** The url for the clinical concerns csv */
  url?: Maybe<Scalars['String']>;
};

/** Get a list of urls for downloading access code CSV files */
export type GetAccessCodeCsvUrls = {
  __typename?: 'GetAccessCodeCsvUrls';
  /** List of pre-signed AWS S3 urls containing pre-generated access codes */
  urls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Grant Provider Portal access to a practitioner */
export type GrantProviderPortalAccess = {
  __typename?: 'GrantProviderPortalAccess';
  /** Whether the practitioner's Provider Portal access was granted successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Issue a new access code for a practitioner's a pdt */
export type IssueNewAccessCode = {
  __typename?: 'IssueNewAccessCode';
  /** Whether the practitioner's new access code was sent to them successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum LanguageType {
  En = 'EN',
  Es = 'ES',
  Ko = 'KO'
}

/** An enumeration. */
export enum LibraryItemCategory {
  /** Provider Portal */
  ProviderPortal = 'PROVIDER_PORTAL',
  /** Resources */
  Resources = 'RESOURCES',
  /** SparkRx */
  Sparkrx = 'SPARKRX'
}

/** Library item */
export type LibraryItemType = {
  __typename?: 'LibraryItemType';
  /** which card */
  cardTitle?: Maybe<Scalars['String']>;
  category: LibraryItemCategory;
  link: Scalars['String'];
  pdt?: Maybe<PdtType>;
  subcategory: Scalars['String'];
  title: Scalars['String'];
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

/**
 * Duplicates the built-in graphql_jwt refresh functionality, but using 'limbix_refresh_token_lazy'
 * instead of 'refresh_token_lazy'
 */
export type LimbixRefresh = {
  __typename?: 'LimbixRefresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

/** Limbix Study */
export type LimbixStudyType = {
  __typename?: 'LimbixStudyType';
  appAccessDurationDays?: Maybe<Scalars['Float']>;
  canChangeStudy?: Maybe<Scalars['Boolean']>;
  canDeleteParticipants?: Maybe<Scalars['Boolean']>;
  canModifyParticipants?: Maybe<Scalars['Boolean']>;
  canViewStudy?: Maybe<Scalars['Boolean']>;
  caseReportFormTemplate?: Maybe<Scalars['String']>;
  clinicalConcernAssessment?: Maybe<AssessmentType>;
  clinicianReportAssessment?: Maybe<AssessmentType>;
  coInvestigators: Array<UserType>;
  deteriorationAlerts?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  endTime?: Maybe<Scalars['DateTime']>;
  enrollmentTrackerAssessment?: Maybe<AssessmentType>;
  isBlinded?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Maybe<LanguageType>>>;
  minimumVersionNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pdt: PdtType;
  principalInvestigators: Array<UserType>;
  startTime?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  studyArms?: Maybe<Array<Maybe<StudyArmType>>>;
  studyCoordinators: Array<UserType>;
  studyParticipants?: Maybe<Array<Maybe<StudyParticipantType>>>;
  textEncryptionEnabled: Scalars['Boolean'];
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

/** An enumeration. */
export enum LimbixUserAccountType {
  /** Adult account */
  Adult = 'ADULT',
  /** Child account */
  Child = 'CHILD',
  /** Parent account */
  Parent = 'PARENT',
  /** Research account */
  Research = 'RESEARCH'
}

/** An enumeration. */
export enum LimbixUserGender {
  /** Female */
  F = 'F',
  /** Male */
  M = 'M',
  /** Other */
  O = 'O',
  /** Non-Binary */
  X = 'X'
}

/** An enumeration. */
export enum LimbixUserLanguage {
  /** US English */
  En = 'EN',
  /** Spanish */
  Es = 'ES',
  /** Korean */
  Ko = 'KO'
}

/** An enumeration. */
export enum LimbixUserLocalTimezone {
  /** Africa/Abidjan */
  AfricaAbidjan = 'AFRICA_ABIDJAN',
  /** Africa/Accra */
  AfricaAccra = 'AFRICA_ACCRA',
  /** Africa/Addis_Ababa */
  AfricaAddisAbaba = 'AFRICA_ADDIS_ABABA',
  /** Africa/Algiers */
  AfricaAlgiers = 'AFRICA_ALGIERS',
  /** Africa/Asmara */
  AfricaAsmara = 'AFRICA_ASMARA',
  /** Africa/Asmera */
  AfricaAsmera = 'AFRICA_ASMERA',
  /** Africa/Bamako */
  AfricaBamako = 'AFRICA_BAMAKO',
  /** Africa/Bangui */
  AfricaBangui = 'AFRICA_BANGUI',
  /** Africa/Banjul */
  AfricaBanjul = 'AFRICA_BANJUL',
  /** Africa/Bissau */
  AfricaBissau = 'AFRICA_BISSAU',
  /** Africa/Blantyre */
  AfricaBlantyre = 'AFRICA_BLANTYRE',
  /** Africa/Brazzaville */
  AfricaBrazzaville = 'AFRICA_BRAZZAVILLE',
  /** Africa/Bujumbura */
  AfricaBujumbura = 'AFRICA_BUJUMBURA',
  /** Africa/Cairo */
  AfricaCairo = 'AFRICA_CAIRO',
  /** Africa/Casablanca */
  AfricaCasablanca = 'AFRICA_CASABLANCA',
  /** Africa/Ceuta */
  AfricaCeuta = 'AFRICA_CEUTA',
  /** Africa/Conakry */
  AfricaConakry = 'AFRICA_CONAKRY',
  /** Africa/Dakar */
  AfricaDakar = 'AFRICA_DAKAR',
  /** Africa/Dar_es_Salaam */
  AfricaDarEsSalaam = 'AFRICA_DAR_ES_SALAAM',
  /** Africa/Djibouti */
  AfricaDjibouti = 'AFRICA_DJIBOUTI',
  /** Africa/Douala */
  AfricaDouala = 'AFRICA_DOUALA',
  /** Africa/El_Aaiun */
  AfricaElAaiun = 'AFRICA_EL_AAIUN',
  /** Africa/Freetown */
  AfricaFreetown = 'AFRICA_FREETOWN',
  /** Africa/Gaborone */
  AfricaGaborone = 'AFRICA_GABORONE',
  /** Africa/Harare */
  AfricaHarare = 'AFRICA_HARARE',
  /** Africa/Johannesburg */
  AfricaJohannesburg = 'AFRICA_JOHANNESBURG',
  /** Africa/Juba */
  AfricaJuba = 'AFRICA_JUBA',
  /** Africa/Kampala */
  AfricaKampala = 'AFRICA_KAMPALA',
  /** Africa/Khartoum */
  AfricaKhartoum = 'AFRICA_KHARTOUM',
  /** Africa/Kigali */
  AfricaKigali = 'AFRICA_KIGALI',
  /** Africa/Kinshasa */
  AfricaKinshasa = 'AFRICA_KINSHASA',
  /** Africa/Lagos */
  AfricaLagos = 'AFRICA_LAGOS',
  /** Africa/Libreville */
  AfricaLibreville = 'AFRICA_LIBREVILLE',
  /** Africa/Lome */
  AfricaLome = 'AFRICA_LOME',
  /** Africa/Luanda */
  AfricaLuanda = 'AFRICA_LUANDA',
  /** Africa/Lubumbashi */
  AfricaLubumbashi = 'AFRICA_LUBUMBASHI',
  /** Africa/Lusaka */
  AfricaLusaka = 'AFRICA_LUSAKA',
  /** Africa/Malabo */
  AfricaMalabo = 'AFRICA_MALABO',
  /** Africa/Maputo */
  AfricaMaputo = 'AFRICA_MAPUTO',
  /** Africa/Maseru */
  AfricaMaseru = 'AFRICA_MASERU',
  /** Africa/Mbabane */
  AfricaMbabane = 'AFRICA_MBABANE',
  /** Africa/Mogadishu */
  AfricaMogadishu = 'AFRICA_MOGADISHU',
  /** Africa/Monrovia */
  AfricaMonrovia = 'AFRICA_MONROVIA',
  /** Africa/Nairobi */
  AfricaNairobi = 'AFRICA_NAIROBI',
  /** Africa/Ndjamena */
  AfricaNdjamena = 'AFRICA_NDJAMENA',
  /** Africa/Niamey */
  AfricaNiamey = 'AFRICA_NIAMEY',
  /** Africa/Nouakchott */
  AfricaNouakchott = 'AFRICA_NOUAKCHOTT',
  /** Africa/Ouagadougou */
  AfricaOuagadougou = 'AFRICA_OUAGADOUGOU',
  /** Africa/Porto-Novo */
  AfricaPortoNovo = 'AFRICA_PORTO_NOVO',
  /** Africa/Sao_Tome */
  AfricaSaoTome = 'AFRICA_SAO_TOME',
  /** Africa/Timbuktu */
  AfricaTimbuktu = 'AFRICA_TIMBUKTU',
  /** Africa/Tripoli */
  AfricaTripoli = 'AFRICA_TRIPOLI',
  /** Africa/Tunis */
  AfricaTunis = 'AFRICA_TUNIS',
  /** Africa/Windhoek */
  AfricaWindhoek = 'AFRICA_WINDHOEK',
  /** America/Adak */
  AmericaAdak = 'AMERICA_ADAK',
  /** America/Anchorage */
  AmericaAnchorage = 'AMERICA_ANCHORAGE',
  /** America/Anguilla */
  AmericaAnguilla = 'AMERICA_ANGUILLA',
  /** America/Antigua */
  AmericaAntigua = 'AMERICA_ANTIGUA',
  /** America/Araguaina */
  AmericaAraguaina = 'AMERICA_ARAGUAINA',
  /** America/Argentina/Buenos_Aires */
  AmericaArgentinaBuenosAires = 'AMERICA_ARGENTINA_BUENOS_AIRES',
  /** America/Argentina/Catamarca */
  AmericaArgentinaCatamarca = 'AMERICA_ARGENTINA_CATAMARCA',
  /** America/Argentina/ComodRivadavia */
  AmericaArgentinaComodrivadavia = 'AMERICA_ARGENTINA_COMODRIVADAVIA',
  /** America/Argentina/Cordoba */
  AmericaArgentinaCordoba = 'AMERICA_ARGENTINA_CORDOBA',
  /** America/Argentina/Jujuy */
  AmericaArgentinaJujuy = 'AMERICA_ARGENTINA_JUJUY',
  /** America/Argentina/La_Rioja */
  AmericaArgentinaLaRioja = 'AMERICA_ARGENTINA_LA_RIOJA',
  /** America/Argentina/Mendoza */
  AmericaArgentinaMendoza = 'AMERICA_ARGENTINA_MENDOZA',
  /** America/Argentina/Rio_Gallegos */
  AmericaArgentinaRioGallegos = 'AMERICA_ARGENTINA_RIO_GALLEGOS',
  /** America/Argentina/Salta */
  AmericaArgentinaSalta = 'AMERICA_ARGENTINA_SALTA',
  /** America/Argentina/San_Juan */
  AmericaArgentinaSanJuan = 'AMERICA_ARGENTINA_SAN_JUAN',
  /** America/Argentina/San_Luis */
  AmericaArgentinaSanLuis = 'AMERICA_ARGENTINA_SAN_LUIS',
  /** America/Argentina/Tucuman */
  AmericaArgentinaTucuman = 'AMERICA_ARGENTINA_TUCUMAN',
  /** America/Argentina/Ushuaia */
  AmericaArgentinaUshuaia = 'AMERICA_ARGENTINA_USHUAIA',
  /** America/Aruba */
  AmericaAruba = 'AMERICA_ARUBA',
  /** America/Asuncion */
  AmericaAsuncion = 'AMERICA_ASUNCION',
  /** America/Atikokan */
  AmericaAtikokan = 'AMERICA_ATIKOKAN',
  /** America/Atka */
  AmericaAtka = 'AMERICA_ATKA',
  /** America/Bahia */
  AmericaBahia = 'AMERICA_BAHIA',
  /** America/Bahia_Banderas */
  AmericaBahiaBanderas = 'AMERICA_BAHIA_BANDERAS',
  /** America/Barbados */
  AmericaBarbados = 'AMERICA_BARBADOS',
  /** America/Belem */
  AmericaBelem = 'AMERICA_BELEM',
  /** America/Belize */
  AmericaBelize = 'AMERICA_BELIZE',
  /** America/Blanc-Sablon */
  AmericaBlancSablon = 'AMERICA_BLANC_SABLON',
  /** America/Boa_Vista */
  AmericaBoaVista = 'AMERICA_BOA_VISTA',
  /** America/Bogota */
  AmericaBogota = 'AMERICA_BOGOTA',
  /** America/Boise */
  AmericaBoise = 'AMERICA_BOISE',
  /** America/Buenos_Aires */
  AmericaBuenosAires = 'AMERICA_BUENOS_AIRES',
  /** America/Cambridge_Bay */
  AmericaCambridgeBay = 'AMERICA_CAMBRIDGE_BAY',
  /** America/Campo_Grande */
  AmericaCampoGrande = 'AMERICA_CAMPO_GRANDE',
  /** America/Cancun */
  AmericaCancun = 'AMERICA_CANCUN',
  /** America/Caracas */
  AmericaCaracas = 'AMERICA_CARACAS',
  /** America/Catamarca */
  AmericaCatamarca = 'AMERICA_CATAMARCA',
  /** America/Cayenne */
  AmericaCayenne = 'AMERICA_CAYENNE',
  /** America/Cayman */
  AmericaCayman = 'AMERICA_CAYMAN',
  /** America/Chicago */
  AmericaChicago = 'AMERICA_CHICAGO',
  /** America/Chihuahua */
  AmericaChihuahua = 'AMERICA_CHIHUAHUA',
  /** America/Ciudad_Juarez */
  AmericaCiudadJuarez = 'AMERICA_CIUDAD_JUAREZ',
  /** America/Coral_Harbour */
  AmericaCoralHarbour = 'AMERICA_CORAL_HARBOUR',
  /** America/Cordoba */
  AmericaCordoba = 'AMERICA_CORDOBA',
  /** America/Costa_Rica */
  AmericaCostaRica = 'AMERICA_COSTA_RICA',
  /** America/Creston */
  AmericaCreston = 'AMERICA_CRESTON',
  /** America/Cuiaba */
  AmericaCuiaba = 'AMERICA_CUIABA',
  /** America/Curacao */
  AmericaCuracao = 'AMERICA_CURACAO',
  /** America/Danmarkshavn */
  AmericaDanmarkshavn = 'AMERICA_DANMARKSHAVN',
  /** America/Dawson */
  AmericaDawson = 'AMERICA_DAWSON',
  /** America/Dawson_Creek */
  AmericaDawsonCreek = 'AMERICA_DAWSON_CREEK',
  /** America/Denver */
  AmericaDenver = 'AMERICA_DENVER',
  /** America/Detroit */
  AmericaDetroit = 'AMERICA_DETROIT',
  /** America/Dominica */
  AmericaDominica = 'AMERICA_DOMINICA',
  /** America/Edmonton */
  AmericaEdmonton = 'AMERICA_EDMONTON',
  /** America/Eirunepe */
  AmericaEirunepe = 'AMERICA_EIRUNEPE',
  /** America/El_Salvador */
  AmericaElSalvador = 'AMERICA_EL_SALVADOR',
  /** America/Ensenada */
  AmericaEnsenada = 'AMERICA_ENSENADA',
  /** America/Fortaleza */
  AmericaFortaleza = 'AMERICA_FORTALEZA',
  /** America/Fort_Nelson */
  AmericaFortNelson = 'AMERICA_FORT_NELSON',
  /** America/Fort_Wayne */
  AmericaFortWayne = 'AMERICA_FORT_WAYNE',
  /** America/Glace_Bay */
  AmericaGlaceBay = 'AMERICA_GLACE_BAY',
  /** America/Godthab */
  AmericaGodthab = 'AMERICA_GODTHAB',
  /** America/Goose_Bay */
  AmericaGooseBay = 'AMERICA_GOOSE_BAY',
  /** America/Grand_Turk */
  AmericaGrandTurk = 'AMERICA_GRAND_TURK',
  /** America/Grenada */
  AmericaGrenada = 'AMERICA_GRENADA',
  /** America/Guadeloupe */
  AmericaGuadeloupe = 'AMERICA_GUADELOUPE',
  /** America/Guatemala */
  AmericaGuatemala = 'AMERICA_GUATEMALA',
  /** America/Guayaquil */
  AmericaGuayaquil = 'AMERICA_GUAYAQUIL',
  /** America/Guyana */
  AmericaGuyana = 'AMERICA_GUYANA',
  /** America/Halifax */
  AmericaHalifax = 'AMERICA_HALIFAX',
  /** America/Havana */
  AmericaHavana = 'AMERICA_HAVANA',
  /** America/Hermosillo */
  AmericaHermosillo = 'AMERICA_HERMOSILLO',
  /** America/Indianapolis */
  AmericaIndianapolis = 'AMERICA_INDIANAPOLIS',
  /** America/Indiana/Indianapolis */
  AmericaIndianaIndianapolis = 'AMERICA_INDIANA_INDIANAPOLIS',
  /** America/Indiana/Knox */
  AmericaIndianaKnox = 'AMERICA_INDIANA_KNOX',
  /** America/Indiana/Marengo */
  AmericaIndianaMarengo = 'AMERICA_INDIANA_MARENGO',
  /** America/Indiana/Petersburg */
  AmericaIndianaPetersburg = 'AMERICA_INDIANA_PETERSBURG',
  /** America/Indiana/Tell_City */
  AmericaIndianaTellCity = 'AMERICA_INDIANA_TELL_CITY',
  /** America/Indiana/Vevay */
  AmericaIndianaVevay = 'AMERICA_INDIANA_VEVAY',
  /** America/Indiana/Vincennes */
  AmericaIndianaVincennes = 'AMERICA_INDIANA_VINCENNES',
  /** America/Indiana/Winamac */
  AmericaIndianaWinamac = 'AMERICA_INDIANA_WINAMAC',
  /** America/Inuvik */
  AmericaInuvik = 'AMERICA_INUVIK',
  /** America/Iqaluit */
  AmericaIqaluit = 'AMERICA_IQALUIT',
  /** America/Jamaica */
  AmericaJamaica = 'AMERICA_JAMAICA',
  /** America/Jujuy */
  AmericaJujuy = 'AMERICA_JUJUY',
  /** America/Juneau */
  AmericaJuneau = 'AMERICA_JUNEAU',
  /** America/Kentucky/Louisville */
  AmericaKentuckyLouisville = 'AMERICA_KENTUCKY_LOUISVILLE',
  /** America/Kentucky/Monticello */
  AmericaKentuckyMonticello = 'AMERICA_KENTUCKY_MONTICELLO',
  /** America/Knox_IN */
  AmericaKnoxIn = 'AMERICA_KNOX_IN',
  /** America/Kralendijk */
  AmericaKralendijk = 'AMERICA_KRALENDIJK',
  /** America/La_Paz */
  AmericaLaPaz = 'AMERICA_LA_PAZ',
  /** America/Lima */
  AmericaLima = 'AMERICA_LIMA',
  /** America/Los_Angeles */
  AmericaLosAngeles = 'AMERICA_LOS_ANGELES',
  /** America/Louisville */
  AmericaLouisville = 'AMERICA_LOUISVILLE',
  /** America/Lower_Princes */
  AmericaLowerPrinces = 'AMERICA_LOWER_PRINCES',
  /** America/Maceio */
  AmericaMaceio = 'AMERICA_MACEIO',
  /** America/Managua */
  AmericaManagua = 'AMERICA_MANAGUA',
  /** America/Manaus */
  AmericaManaus = 'AMERICA_MANAUS',
  /** America/Marigot */
  AmericaMarigot = 'AMERICA_MARIGOT',
  /** America/Martinique */
  AmericaMartinique = 'AMERICA_MARTINIQUE',
  /** America/Matamoros */
  AmericaMatamoros = 'AMERICA_MATAMOROS',
  /** America/Mazatlan */
  AmericaMazatlan = 'AMERICA_MAZATLAN',
  /** America/Mendoza */
  AmericaMendoza = 'AMERICA_MENDOZA',
  /** America/Menominee */
  AmericaMenominee = 'AMERICA_MENOMINEE',
  /** America/Merida */
  AmericaMerida = 'AMERICA_MERIDA',
  /** America/Metlakatla */
  AmericaMetlakatla = 'AMERICA_METLAKATLA',
  /** America/Mexico_City */
  AmericaMexicoCity = 'AMERICA_MEXICO_CITY',
  /** America/Miquelon */
  AmericaMiquelon = 'AMERICA_MIQUELON',
  /** America/Moncton */
  AmericaMoncton = 'AMERICA_MONCTON',
  /** America/Monterrey */
  AmericaMonterrey = 'AMERICA_MONTERREY',
  /** America/Montevideo */
  AmericaMontevideo = 'AMERICA_MONTEVIDEO',
  /** America/Montreal */
  AmericaMontreal = 'AMERICA_MONTREAL',
  /** America/Montserrat */
  AmericaMontserrat = 'AMERICA_MONTSERRAT',
  /** America/Nassau */
  AmericaNassau = 'AMERICA_NASSAU',
  /** America/New_York */
  AmericaNewYork = 'AMERICA_NEW_YORK',
  /** America/Nipigon */
  AmericaNipigon = 'AMERICA_NIPIGON',
  /** America/Nome */
  AmericaNome = 'AMERICA_NOME',
  /** America/Noronha */
  AmericaNoronha = 'AMERICA_NORONHA',
  /** America/North_Dakota/Beulah */
  AmericaNorthDakotaBeulah = 'AMERICA_NORTH_DAKOTA_BEULAH',
  /** America/North_Dakota/Center */
  AmericaNorthDakotaCenter = 'AMERICA_NORTH_DAKOTA_CENTER',
  /** America/North_Dakota/New_Salem */
  AmericaNorthDakotaNewSalem = 'AMERICA_NORTH_DAKOTA_NEW_SALEM',
  /** America/Nuuk */
  AmericaNuuk = 'AMERICA_NUUK',
  /** America/Ojinaga */
  AmericaOjinaga = 'AMERICA_OJINAGA',
  /** America/Panama */
  AmericaPanama = 'AMERICA_PANAMA',
  /** America/Pangnirtung */
  AmericaPangnirtung = 'AMERICA_PANGNIRTUNG',
  /** America/Paramaribo */
  AmericaParamaribo = 'AMERICA_PARAMARIBO',
  /** America/Phoenix */
  AmericaPhoenix = 'AMERICA_PHOENIX',
  /** America/Porto_Acre */
  AmericaPortoAcre = 'AMERICA_PORTO_ACRE',
  /** America/Porto_Velho */
  AmericaPortoVelho = 'AMERICA_PORTO_VELHO',
  /** America/Port-au-Prince */
  AmericaPortAuPrince = 'AMERICA_PORT_AU_PRINCE',
  /** America/Port_of_Spain */
  AmericaPortOfSpain = 'AMERICA_PORT_OF_SPAIN',
  /** America/Puerto_Rico */
  AmericaPuertoRico = 'AMERICA_PUERTO_RICO',
  /** America/Punta_Arenas */
  AmericaPuntaArenas = 'AMERICA_PUNTA_ARENAS',
  /** America/Rainy_River */
  AmericaRainyRiver = 'AMERICA_RAINY_RIVER',
  /** America/Rankin_Inlet */
  AmericaRankinInlet = 'AMERICA_RANKIN_INLET',
  /** America/Recife */
  AmericaRecife = 'AMERICA_RECIFE',
  /** America/Regina */
  AmericaRegina = 'AMERICA_REGINA',
  /** America/Resolute */
  AmericaResolute = 'AMERICA_RESOLUTE',
  /** America/Rio_Branco */
  AmericaRioBranco = 'AMERICA_RIO_BRANCO',
  /** America/Rosario */
  AmericaRosario = 'AMERICA_ROSARIO',
  /** America/Santarem */
  AmericaSantarem = 'AMERICA_SANTAREM',
  /** America/Santa_Isabel */
  AmericaSantaIsabel = 'AMERICA_SANTA_ISABEL',
  /** America/Santiago */
  AmericaSantiago = 'AMERICA_SANTIAGO',
  /** America/Santo_Domingo */
  AmericaSantoDomingo = 'AMERICA_SANTO_DOMINGO',
  /** America/Sao_Paulo */
  AmericaSaoPaulo = 'AMERICA_SAO_PAULO',
  /** America/Scoresbysund */
  AmericaScoresbysund = 'AMERICA_SCORESBYSUND',
  /** America/Shiprock */
  AmericaShiprock = 'AMERICA_SHIPROCK',
  /** America/Sitka */
  AmericaSitka = 'AMERICA_SITKA',
  /** America/St_Barthelemy */
  AmericaStBarthelemy = 'AMERICA_ST_BARTHELEMY',
  /** America/St_Johns */
  AmericaStJohns = 'AMERICA_ST_JOHNS',
  /** America/St_Kitts */
  AmericaStKitts = 'AMERICA_ST_KITTS',
  /** America/St_Lucia */
  AmericaStLucia = 'AMERICA_ST_LUCIA',
  /** America/St_Thomas */
  AmericaStThomas = 'AMERICA_ST_THOMAS',
  /** America/St_Vincent */
  AmericaStVincent = 'AMERICA_ST_VINCENT',
  /** America/Swift_Current */
  AmericaSwiftCurrent = 'AMERICA_SWIFT_CURRENT',
  /** America/Tegucigalpa */
  AmericaTegucigalpa = 'AMERICA_TEGUCIGALPA',
  /** America/Thule */
  AmericaThule = 'AMERICA_THULE',
  /** America/Thunder_Bay */
  AmericaThunderBay = 'AMERICA_THUNDER_BAY',
  /** America/Tijuana */
  AmericaTijuana = 'AMERICA_TIJUANA',
  /** America/Toronto */
  AmericaToronto = 'AMERICA_TORONTO',
  /** America/Tortola */
  AmericaTortola = 'AMERICA_TORTOLA',
  /** America/Vancouver */
  AmericaVancouver = 'AMERICA_VANCOUVER',
  /** America/Virgin */
  AmericaVirgin = 'AMERICA_VIRGIN',
  /** America/Whitehorse */
  AmericaWhitehorse = 'AMERICA_WHITEHORSE',
  /** America/Winnipeg */
  AmericaWinnipeg = 'AMERICA_WINNIPEG',
  /** America/Yakutat */
  AmericaYakutat = 'AMERICA_YAKUTAT',
  /** America/Yellowknife */
  AmericaYellowknife = 'AMERICA_YELLOWKNIFE',
  /** Antarctica/Casey */
  AntarcticaCasey = 'ANTARCTICA_CASEY',
  /** Antarctica/Davis */
  AntarcticaDavis = 'ANTARCTICA_DAVIS',
  /** Antarctica/DumontDUrville */
  AntarcticaDumontdurville = 'ANTARCTICA_DUMONTDURVILLE',
  /** Antarctica/Macquarie */
  AntarcticaMacquarie = 'ANTARCTICA_MACQUARIE',
  /** Antarctica/Mawson */
  AntarcticaMawson = 'ANTARCTICA_MAWSON',
  /** Antarctica/McMurdo */
  AntarcticaMcmurdo = 'ANTARCTICA_MCMURDO',
  /** Antarctica/Palmer */
  AntarcticaPalmer = 'ANTARCTICA_PALMER',
  /** Antarctica/Rothera */
  AntarcticaRothera = 'ANTARCTICA_ROTHERA',
  /** Antarctica/South_Pole */
  AntarcticaSouthPole = 'ANTARCTICA_SOUTH_POLE',
  /** Antarctica/Syowa */
  AntarcticaSyowa = 'ANTARCTICA_SYOWA',
  /** Antarctica/Troll */
  AntarcticaTroll = 'ANTARCTICA_TROLL',
  /** Antarctica/Vostok */
  AntarcticaVostok = 'ANTARCTICA_VOSTOK',
  /** Arctic/Longyearbyen */
  ArcticLongyearbyen = 'ARCTIC_LONGYEARBYEN',
  /** Asia/Aden */
  AsiaAden = 'ASIA_ADEN',
  /** Asia/Almaty */
  AsiaAlmaty = 'ASIA_ALMATY',
  /** Asia/Amman */
  AsiaAmman = 'ASIA_AMMAN',
  /** Asia/Anadyr */
  AsiaAnadyr = 'ASIA_ANADYR',
  /** Asia/Aqtau */
  AsiaAqtau = 'ASIA_AQTAU',
  /** Asia/Aqtobe */
  AsiaAqtobe = 'ASIA_AQTOBE',
  /** Asia/Ashgabat */
  AsiaAshgabat = 'ASIA_ASHGABAT',
  /** Asia/Ashkhabad */
  AsiaAshkhabad = 'ASIA_ASHKHABAD',
  /** Asia/Atyrau */
  AsiaAtyrau = 'ASIA_ATYRAU',
  /** Asia/Baghdad */
  AsiaBaghdad = 'ASIA_BAGHDAD',
  /** Asia/Bahrain */
  AsiaBahrain = 'ASIA_BAHRAIN',
  /** Asia/Baku */
  AsiaBaku = 'ASIA_BAKU',
  /** Asia/Bangkok */
  AsiaBangkok = 'ASIA_BANGKOK',
  /** Asia/Barnaul */
  AsiaBarnaul = 'ASIA_BARNAUL',
  /** Asia/Beirut */
  AsiaBeirut = 'ASIA_BEIRUT',
  /** Asia/Bishkek */
  AsiaBishkek = 'ASIA_BISHKEK',
  /** Asia/Brunei */
  AsiaBrunei = 'ASIA_BRUNEI',
  /** Asia/Calcutta */
  AsiaCalcutta = 'ASIA_CALCUTTA',
  /** Asia/Chita */
  AsiaChita = 'ASIA_CHITA',
  /** Asia/Choibalsan */
  AsiaChoibalsan = 'ASIA_CHOIBALSAN',
  /** Asia/Chongqing */
  AsiaChongqing = 'ASIA_CHONGQING',
  /** Asia/Chungking */
  AsiaChungking = 'ASIA_CHUNGKING',
  /** Asia/Colombo */
  AsiaColombo = 'ASIA_COLOMBO',
  /** Asia/Dacca */
  AsiaDacca = 'ASIA_DACCA',
  /** Asia/Damascus */
  AsiaDamascus = 'ASIA_DAMASCUS',
  /** Asia/Dhaka */
  AsiaDhaka = 'ASIA_DHAKA',
  /** Asia/Dili */
  AsiaDili = 'ASIA_DILI',
  /** Asia/Dubai */
  AsiaDubai = 'ASIA_DUBAI',
  /** Asia/Dushanbe */
  AsiaDushanbe = 'ASIA_DUSHANBE',
  /** Asia/Famagusta */
  AsiaFamagusta = 'ASIA_FAMAGUSTA',
  /** Asia/Gaza */
  AsiaGaza = 'ASIA_GAZA',
  /** Asia/Harbin */
  AsiaHarbin = 'ASIA_HARBIN',
  /** Asia/Hebron */
  AsiaHebron = 'ASIA_HEBRON',
  /** Asia/Hong_Kong */
  AsiaHongKong = 'ASIA_HONG_KONG',
  /** Asia/Hovd */
  AsiaHovd = 'ASIA_HOVD',
  /** Asia/Ho_Chi_Minh */
  AsiaHoChiMinh = 'ASIA_HO_CHI_MINH',
  /** Asia/Irkutsk */
  AsiaIrkutsk = 'ASIA_IRKUTSK',
  /** Asia/Istanbul */
  AsiaIstanbul = 'ASIA_ISTANBUL',
  /** Asia/Jakarta */
  AsiaJakarta = 'ASIA_JAKARTA',
  /** Asia/Jayapura */
  AsiaJayapura = 'ASIA_JAYAPURA',
  /** Asia/Jerusalem */
  AsiaJerusalem = 'ASIA_JERUSALEM',
  /** Asia/Kabul */
  AsiaKabul = 'ASIA_KABUL',
  /** Asia/Kamchatka */
  AsiaKamchatka = 'ASIA_KAMCHATKA',
  /** Asia/Karachi */
  AsiaKarachi = 'ASIA_KARACHI',
  /** Asia/Kashgar */
  AsiaKashgar = 'ASIA_KASHGAR',
  /** Asia/Kathmandu */
  AsiaKathmandu = 'ASIA_KATHMANDU',
  /** Asia/Katmandu */
  AsiaKatmandu = 'ASIA_KATMANDU',
  /** Asia/Khandyga */
  AsiaKhandyga = 'ASIA_KHANDYGA',
  /** Asia/Kolkata */
  AsiaKolkata = 'ASIA_KOLKATA',
  /** Asia/Krasnoyarsk */
  AsiaKrasnoyarsk = 'ASIA_KRASNOYARSK',
  /** Asia/Kuala_Lumpur */
  AsiaKualaLumpur = 'ASIA_KUALA_LUMPUR',
  /** Asia/Kuching */
  AsiaKuching = 'ASIA_KUCHING',
  /** Asia/Kuwait */
  AsiaKuwait = 'ASIA_KUWAIT',
  /** Asia/Macao */
  AsiaMacao = 'ASIA_MACAO',
  /** Asia/Macau */
  AsiaMacau = 'ASIA_MACAU',
  /** Asia/Magadan */
  AsiaMagadan = 'ASIA_MAGADAN',
  /** Asia/Makassar */
  AsiaMakassar = 'ASIA_MAKASSAR',
  /** Asia/Manila */
  AsiaManila = 'ASIA_MANILA',
  /** Asia/Muscat */
  AsiaMuscat = 'ASIA_MUSCAT',
  /** Asia/Nicosia */
  AsiaNicosia = 'ASIA_NICOSIA',
  /** Asia/Novokuznetsk */
  AsiaNovokuznetsk = 'ASIA_NOVOKUZNETSK',
  /** Asia/Novosibirsk */
  AsiaNovosibirsk = 'ASIA_NOVOSIBIRSK',
  /** Asia/Omsk */
  AsiaOmsk = 'ASIA_OMSK',
  /** Asia/Oral */
  AsiaOral = 'ASIA_ORAL',
  /** Asia/Phnom_Penh */
  AsiaPhnomPenh = 'ASIA_PHNOM_PENH',
  /** Asia/Pontianak */
  AsiaPontianak = 'ASIA_PONTIANAK',
  /** Asia/Pyongyang */
  AsiaPyongyang = 'ASIA_PYONGYANG',
  /** Asia/Qatar */
  AsiaQatar = 'ASIA_QATAR',
  /** Asia/Qostanay */
  AsiaQostanay = 'ASIA_QOSTANAY',
  /** Asia/Qyzylorda */
  AsiaQyzylorda = 'ASIA_QYZYLORDA',
  /** Asia/Rangoon */
  AsiaRangoon = 'ASIA_RANGOON',
  /** Asia/Riyadh */
  AsiaRiyadh = 'ASIA_RIYADH',
  /** Asia/Saigon */
  AsiaSaigon = 'ASIA_SAIGON',
  /** Asia/Sakhalin */
  AsiaSakhalin = 'ASIA_SAKHALIN',
  /** Asia/Samarkand */
  AsiaSamarkand = 'ASIA_SAMARKAND',
  /** Asia/Seoul */
  AsiaSeoul = 'ASIA_SEOUL',
  /** Asia/Shanghai */
  AsiaShanghai = 'ASIA_SHANGHAI',
  /** Asia/Singapore */
  AsiaSingapore = 'ASIA_SINGAPORE',
  /** Asia/Srednekolymsk */
  AsiaSrednekolymsk = 'ASIA_SREDNEKOLYMSK',
  /** Asia/Taipei */
  AsiaTaipei = 'ASIA_TAIPEI',
  /** Asia/Tashkent */
  AsiaTashkent = 'ASIA_TASHKENT',
  /** Asia/Tbilisi */
  AsiaTbilisi = 'ASIA_TBILISI',
  /** Asia/Tehran */
  AsiaTehran = 'ASIA_TEHRAN',
  /** Asia/Tel_Aviv */
  AsiaTelAviv = 'ASIA_TEL_AVIV',
  /** Asia/Thimbu */
  AsiaThimbu = 'ASIA_THIMBU',
  /** Asia/Thimphu */
  AsiaThimphu = 'ASIA_THIMPHU',
  /** Asia/Tokyo */
  AsiaTokyo = 'ASIA_TOKYO',
  /** Asia/Tomsk */
  AsiaTomsk = 'ASIA_TOMSK',
  /** Asia/Ujung_Pandang */
  AsiaUjungPandang = 'ASIA_UJUNG_PANDANG',
  /** Asia/Ulaanbaatar */
  AsiaUlaanbaatar = 'ASIA_ULAANBAATAR',
  /** Asia/Ulan_Bator */
  AsiaUlanBator = 'ASIA_ULAN_BATOR',
  /** Asia/Urumqi */
  AsiaUrumqi = 'ASIA_URUMQI',
  /** Asia/Ust-Nera */
  AsiaUstNera = 'ASIA_UST_NERA',
  /** Asia/Vientiane */
  AsiaVientiane = 'ASIA_VIENTIANE',
  /** Asia/Vladivostok */
  AsiaVladivostok = 'ASIA_VLADIVOSTOK',
  /** Asia/Yakutsk */
  AsiaYakutsk = 'ASIA_YAKUTSK',
  /** Asia/Yangon */
  AsiaYangon = 'ASIA_YANGON',
  /** Asia/Yekaterinburg */
  AsiaYekaterinburg = 'ASIA_YEKATERINBURG',
  /** Asia/Yerevan */
  AsiaYerevan = 'ASIA_YEREVAN',
  /** Atlantic/Azores */
  AtlanticAzores = 'ATLANTIC_AZORES',
  /** Atlantic/Bermuda */
  AtlanticBermuda = 'ATLANTIC_BERMUDA',
  /** Atlantic/Canary */
  AtlanticCanary = 'ATLANTIC_CANARY',
  /** Atlantic/Cape_Verde */
  AtlanticCapeVerde = 'ATLANTIC_CAPE_VERDE',
  /** Atlantic/Faeroe */
  AtlanticFaeroe = 'ATLANTIC_FAEROE',
  /** Atlantic/Faroe */
  AtlanticFaroe = 'ATLANTIC_FAROE',
  /** Atlantic/Jan_Mayen */
  AtlanticJanMayen = 'ATLANTIC_JAN_MAYEN',
  /** Atlantic/Madeira */
  AtlanticMadeira = 'ATLANTIC_MADEIRA',
  /** Atlantic/Reykjavik */
  AtlanticReykjavik = 'ATLANTIC_REYKJAVIK',
  /** Atlantic/South_Georgia */
  AtlanticSouthGeorgia = 'ATLANTIC_SOUTH_GEORGIA',
  /** Atlantic/Stanley */
  AtlanticStanley = 'ATLANTIC_STANLEY',
  /** Atlantic/St_Helena */
  AtlanticStHelena = 'ATLANTIC_ST_HELENA',
  /** Australia/ACT */
  AustraliaAct = 'AUSTRALIA_ACT',
  /** Australia/Adelaide */
  AustraliaAdelaide = 'AUSTRALIA_ADELAIDE',
  /** Australia/Brisbane */
  AustraliaBrisbane = 'AUSTRALIA_BRISBANE',
  /** Australia/Broken_Hill */
  AustraliaBrokenHill = 'AUSTRALIA_BROKEN_HILL',
  /** Australia/Canberra */
  AustraliaCanberra = 'AUSTRALIA_CANBERRA',
  /** Australia/Currie */
  AustraliaCurrie = 'AUSTRALIA_CURRIE',
  /** Australia/Darwin */
  AustraliaDarwin = 'AUSTRALIA_DARWIN',
  /** Australia/Eucla */
  AustraliaEucla = 'AUSTRALIA_EUCLA',
  /** Australia/Hobart */
  AustraliaHobart = 'AUSTRALIA_HOBART',
  /** Australia/LHI */
  AustraliaLhi = 'AUSTRALIA_LHI',
  /** Australia/Lindeman */
  AustraliaLindeman = 'AUSTRALIA_LINDEMAN',
  /** Australia/Lord_Howe */
  AustraliaLordHowe = 'AUSTRALIA_LORD_HOWE',
  /** Australia/Melbourne */
  AustraliaMelbourne = 'AUSTRALIA_MELBOURNE',
  /** Australia/North */
  AustraliaNorth = 'AUSTRALIA_NORTH',
  /** Australia/NSW */
  AustraliaNsw = 'AUSTRALIA_NSW',
  /** Australia/Perth */
  AustraliaPerth = 'AUSTRALIA_PERTH',
  /** Australia/Queensland */
  AustraliaQueensland = 'AUSTRALIA_QUEENSLAND',
  /** Australia/South */
  AustraliaSouth = 'AUSTRALIA_SOUTH',
  /** Australia/Sydney */
  AustraliaSydney = 'AUSTRALIA_SYDNEY',
  /** Australia/Tasmania */
  AustraliaTasmania = 'AUSTRALIA_TASMANIA',
  /** Australia/Victoria */
  AustraliaVictoria = 'AUSTRALIA_VICTORIA',
  /** Australia/West */
  AustraliaWest = 'AUSTRALIA_WEST',
  /** Australia/Yancowinna */
  AustraliaYancowinna = 'AUSTRALIA_YANCOWINNA',
  /** Brazil/Acre */
  BrazilAcre = 'BRAZIL_ACRE',
  /** Brazil/DeNoronha */
  BrazilDenoronha = 'BRAZIL_DENORONHA',
  /** Brazil/East */
  BrazilEast = 'BRAZIL_EAST',
  /** Brazil/West */
  BrazilWest = 'BRAZIL_WEST',
  /** Canada/Atlantic */
  CanadaAtlantic = 'CANADA_ATLANTIC',
  /** Canada/Central */
  CanadaCentral = 'CANADA_CENTRAL',
  /** Canada/Eastern */
  CanadaEastern = 'CANADA_EASTERN',
  /** Canada/Mountain */
  CanadaMountain = 'CANADA_MOUNTAIN',
  /** Canada/Newfoundland */
  CanadaNewfoundland = 'CANADA_NEWFOUNDLAND',
  /** Canada/Pacific */
  CanadaPacific = 'CANADA_PACIFIC',
  /** Canada/Saskatchewan */
  CanadaSaskatchewan = 'CANADA_SASKATCHEWAN',
  /** Canada/Yukon */
  CanadaYukon = 'CANADA_YUKON',
  /** CET */
  Cet = 'CET',
  /** Chile/Continental */
  ChileContinental = 'CHILE_CONTINENTAL',
  /** Chile/EasterIsland */
  ChileEasterisland = 'CHILE_EASTERISLAND',
  /** CST6CDT */
  Cst6Cdt = 'CST6CDT',
  /** Cuba */
  Cuba = 'CUBA',
  /** EET */
  Eet = 'EET',
  /** Egypt */
  Egypt = 'EGYPT',
  /** Eire */
  Eire = 'EIRE',
  /** EST */
  Est = 'EST',
  /** EST5EDT */
  Est5Edt = 'EST5EDT',
  /** Etc/GMT */
  EtcGmt = 'ETC_GMT',
  /** Etc/GMT0 */
  EtcGmt0 = 'ETC_GMT0',
  /** Etc/GMT+0 */
  EtcGmt_0 = 'ETC_GMT_0',
  /** Etc/GMT-0 */
  EtcGmt_0_405 = 'ETC_GMT_0_405',
  /** Etc/GMT+1 */
  EtcGmt_1 = 'ETC_GMT_1',
  /** Etc/GMT-1 */
  EtcGmt_1_406 = 'ETC_GMT_1_406',
  /** Etc/GMT+2 */
  EtcGmt_2 = 'ETC_GMT_2',
  /** Etc/GMT-2 */
  EtcGmt_2_412 = 'ETC_GMT_2_412',
  /** Etc/GMT+3 */
  EtcGmt_3 = 'ETC_GMT_3',
  /** Etc/GMT-3 */
  EtcGmt_3_413 = 'ETC_GMT_3_413',
  /** Etc/GMT+4 */
  EtcGmt_4 = 'ETC_GMT_4',
  /** Etc/GMT-4 */
  EtcGmt_4_414 = 'ETC_GMT_4_414',
  /** Etc/GMT+5 */
  EtcGmt_5 = 'ETC_GMT_5',
  /** Etc/GMT-5 */
  EtcGmt_5_415 = 'ETC_GMT_5_415',
  /** Etc/GMT+6 */
  EtcGmt_6 = 'ETC_GMT_6',
  /** Etc/GMT-6 */
  EtcGmt_6_416 = 'ETC_GMT_6_416',
  /** Etc/GMT+7 */
  EtcGmt_7 = 'ETC_GMT_7',
  /** Etc/GMT-7 */
  EtcGmt_7_417 = 'ETC_GMT_7_417',
  /** Etc/GMT+8 */
  EtcGmt_8 = 'ETC_GMT_8',
  /** Etc/GMT-8 */
  EtcGmt_8_418 = 'ETC_GMT_8_418',
  /** Etc/GMT+9 */
  EtcGmt_9 = 'ETC_GMT_9',
  /** Etc/GMT-9 */
  EtcGmt_9_419 = 'ETC_GMT_9_419',
  /** Etc/GMT+10 */
  EtcGmt_10 = 'ETC_GMT_10',
  /** Etc/GMT-10 */
  EtcGmt_10_407 = 'ETC_GMT_10_407',
  /** Etc/GMT+11 */
  EtcGmt_11 = 'ETC_GMT_11',
  /** Etc/GMT-11 */
  EtcGmt_11_408 = 'ETC_GMT_11_408',
  /** Etc/GMT+12 */
  EtcGmt_12 = 'ETC_GMT_12',
  /** Etc/GMT-12 */
  EtcGmt_12_409 = 'ETC_GMT_12_409',
  /** Etc/GMT-13 */
  EtcGmt_13 = 'ETC_GMT_13',
  /** Etc/GMT-14 */
  EtcGmt_14 = 'ETC_GMT_14',
  /** Etc/Greenwich */
  EtcGreenwich = 'ETC_GREENWICH',
  /** Etc/UCT */
  EtcUct = 'ETC_UCT',
  /** Etc/Universal */
  EtcUniversal = 'ETC_UNIVERSAL',
  /** Etc/UTC */
  EtcUtc = 'ETC_UTC',
  /** Etc/Zulu */
  EtcZulu = 'ETC_ZULU',
  /** Europe/Amsterdam */
  EuropeAmsterdam = 'EUROPE_AMSTERDAM',
  /** Europe/Andorra */
  EuropeAndorra = 'EUROPE_ANDORRA',
  /** Europe/Astrakhan */
  EuropeAstrakhan = 'EUROPE_ASTRAKHAN',
  /** Europe/Athens */
  EuropeAthens = 'EUROPE_ATHENS',
  /** Europe/Belfast */
  EuropeBelfast = 'EUROPE_BELFAST',
  /** Europe/Belgrade */
  EuropeBelgrade = 'EUROPE_BELGRADE',
  /** Europe/Berlin */
  EuropeBerlin = 'EUROPE_BERLIN',
  /** Europe/Bratislava */
  EuropeBratislava = 'EUROPE_BRATISLAVA',
  /** Europe/Brussels */
  EuropeBrussels = 'EUROPE_BRUSSELS',
  /** Europe/Bucharest */
  EuropeBucharest = 'EUROPE_BUCHAREST',
  /** Europe/Budapest */
  EuropeBudapest = 'EUROPE_BUDAPEST',
  /** Europe/Busingen */
  EuropeBusingen = 'EUROPE_BUSINGEN',
  /** Europe/Chisinau */
  EuropeChisinau = 'EUROPE_CHISINAU',
  /** Europe/Copenhagen */
  EuropeCopenhagen = 'EUROPE_COPENHAGEN',
  /** Europe/Dublin */
  EuropeDublin = 'EUROPE_DUBLIN',
  /** Europe/Gibraltar */
  EuropeGibraltar = 'EUROPE_GIBRALTAR',
  /** Europe/Guernsey */
  EuropeGuernsey = 'EUROPE_GUERNSEY',
  /** Europe/Helsinki */
  EuropeHelsinki = 'EUROPE_HELSINKI',
  /** Europe/Isle_of_Man */
  EuropeIsleOfMan = 'EUROPE_ISLE_OF_MAN',
  /** Europe/Istanbul */
  EuropeIstanbul = 'EUROPE_ISTANBUL',
  /** Europe/Jersey */
  EuropeJersey = 'EUROPE_JERSEY',
  /** Europe/Kaliningrad */
  EuropeKaliningrad = 'EUROPE_KALININGRAD',
  /** Europe/Kiev */
  EuropeKiev = 'EUROPE_KIEV',
  /** Europe/Kirov */
  EuropeKirov = 'EUROPE_KIROV',
  /** Europe/Kyiv */
  EuropeKyiv = 'EUROPE_KYIV',
  /** Europe/Lisbon */
  EuropeLisbon = 'EUROPE_LISBON',
  /** Europe/Ljubljana */
  EuropeLjubljana = 'EUROPE_LJUBLJANA',
  /** Europe/London */
  EuropeLondon = 'EUROPE_LONDON',
  /** Europe/Luxembourg */
  EuropeLuxembourg = 'EUROPE_LUXEMBOURG',
  /** Europe/Madrid */
  EuropeMadrid = 'EUROPE_MADRID',
  /** Europe/Malta */
  EuropeMalta = 'EUROPE_MALTA',
  /** Europe/Mariehamn */
  EuropeMariehamn = 'EUROPE_MARIEHAMN',
  /** Europe/Minsk */
  EuropeMinsk = 'EUROPE_MINSK',
  /** Europe/Monaco */
  EuropeMonaco = 'EUROPE_MONACO',
  /** Europe/Moscow */
  EuropeMoscow = 'EUROPE_MOSCOW',
  /** Europe/Nicosia */
  EuropeNicosia = 'EUROPE_NICOSIA',
  /** Europe/Oslo */
  EuropeOslo = 'EUROPE_OSLO',
  /** Europe/Paris */
  EuropeParis = 'EUROPE_PARIS',
  /** Europe/Podgorica */
  EuropePodgorica = 'EUROPE_PODGORICA',
  /** Europe/Prague */
  EuropePrague = 'EUROPE_PRAGUE',
  /** Europe/Riga */
  EuropeRiga = 'EUROPE_RIGA',
  /** Europe/Rome */
  EuropeRome = 'EUROPE_ROME',
  /** Europe/Samara */
  EuropeSamara = 'EUROPE_SAMARA',
  /** Europe/San_Marino */
  EuropeSanMarino = 'EUROPE_SAN_MARINO',
  /** Europe/Sarajevo */
  EuropeSarajevo = 'EUROPE_SARAJEVO',
  /** Europe/Saratov */
  EuropeSaratov = 'EUROPE_SARATOV',
  /** Europe/Simferopol */
  EuropeSimferopol = 'EUROPE_SIMFEROPOL',
  /** Europe/Skopje */
  EuropeSkopje = 'EUROPE_SKOPJE',
  /** Europe/Sofia */
  EuropeSofia = 'EUROPE_SOFIA',
  /** Europe/Stockholm */
  EuropeStockholm = 'EUROPE_STOCKHOLM',
  /** Europe/Tallinn */
  EuropeTallinn = 'EUROPE_TALLINN',
  /** Europe/Tirane */
  EuropeTirane = 'EUROPE_TIRANE',
  /** Europe/Tiraspol */
  EuropeTiraspol = 'EUROPE_TIRASPOL',
  /** Europe/Ulyanovsk */
  EuropeUlyanovsk = 'EUROPE_ULYANOVSK',
  /** Europe/Uzhgorod */
  EuropeUzhgorod = 'EUROPE_UZHGOROD',
  /** Europe/Vaduz */
  EuropeVaduz = 'EUROPE_VADUZ',
  /** Europe/Vatican */
  EuropeVatican = 'EUROPE_VATICAN',
  /** Europe/Vienna */
  EuropeVienna = 'EUROPE_VIENNA',
  /** Europe/Vilnius */
  EuropeVilnius = 'EUROPE_VILNIUS',
  /** Europe/Volgograd */
  EuropeVolgograd = 'EUROPE_VOLGOGRAD',
  /** Europe/Warsaw */
  EuropeWarsaw = 'EUROPE_WARSAW',
  /** Europe/Zagreb */
  EuropeZagreb = 'EUROPE_ZAGREB',
  /** Europe/Zaporozhye */
  EuropeZaporozhye = 'EUROPE_ZAPOROZHYE',
  /** Europe/Zurich */
  EuropeZurich = 'EUROPE_ZURICH',
  /** GB */
  Gb = 'GB',
  /** GB-Eire */
  GbEire = 'GB_EIRE',
  /** GMT */
  Gmt = 'GMT',
  /** GMT0 */
  Gmt0 = 'GMT0',
  /** GMT+0 */
  Gmt_0 = 'GMT_0',
  /** GMT-0 */
  Gmt_0_494 = 'GMT_0_494',
  /** Greenwich */
  Greenwich = 'GREENWICH',
  /** Hongkong */
  Hongkong = 'HONGKONG',
  /** HST */
  Hst = 'HST',
  /** Iceland */
  Iceland = 'ICELAND',
  /** Indian/Antananarivo */
  IndianAntananarivo = 'INDIAN_ANTANANARIVO',
  /** Indian/Chagos */
  IndianChagos = 'INDIAN_CHAGOS',
  /** Indian/Christmas */
  IndianChristmas = 'INDIAN_CHRISTMAS',
  /** Indian/Cocos */
  IndianCocos = 'INDIAN_COCOS',
  /** Indian/Comoro */
  IndianComoro = 'INDIAN_COMORO',
  /** Indian/Kerguelen */
  IndianKerguelen = 'INDIAN_KERGUELEN',
  /** Indian/Mahe */
  IndianMahe = 'INDIAN_MAHE',
  /** Indian/Maldives */
  IndianMaldives = 'INDIAN_MALDIVES',
  /** Indian/Mauritius */
  IndianMauritius = 'INDIAN_MAURITIUS',
  /** Indian/Mayotte */
  IndianMayotte = 'INDIAN_MAYOTTE',
  /** Indian/Reunion */
  IndianReunion = 'INDIAN_REUNION',
  /** Iran */
  Iran = 'IRAN',
  /** Israel */
  Israel = 'ISRAEL',
  /** Jamaica */
  Jamaica = 'JAMAICA',
  /** Japan */
  Japan = 'JAPAN',
  /** Kwajalein */
  Kwajalein = 'KWAJALEIN',
  /** Libya */
  Libya = 'LIBYA',
  /** MET */
  Met = 'MET',
  /** Mexico/BajaNorte */
  MexicoBajanorte = 'MEXICO_BAJANORTE',
  /** Mexico/BajaSur */
  MexicoBajasur = 'MEXICO_BAJASUR',
  /** Mexico/General */
  MexicoGeneral = 'MEXICO_GENERAL',
  /** MST */
  Mst = 'MST',
  /** MST7MDT */
  Mst7Mdt = 'MST7MDT',
  /** Navajo */
  Navajo = 'NAVAJO',
  /** NZ */
  Nz = 'NZ',
  /** NZ-CHAT */
  NzChat = 'NZ_CHAT',
  /** Pacific/Apia */
  PacificApia = 'PACIFIC_APIA',
  /** Pacific/Auckland */
  PacificAuckland = 'PACIFIC_AUCKLAND',
  /** Pacific/Bougainville */
  PacificBougainville = 'PACIFIC_BOUGAINVILLE',
  /** Pacific/Chatham */
  PacificChatham = 'PACIFIC_CHATHAM',
  /** Pacific/Chuuk */
  PacificChuuk = 'PACIFIC_CHUUK',
  /** Pacific/Easter */
  PacificEaster = 'PACIFIC_EASTER',
  /** Pacific/Efate */
  PacificEfate = 'PACIFIC_EFATE',
  /** Pacific/Enderbury */
  PacificEnderbury = 'PACIFIC_ENDERBURY',
  /** Pacific/Fakaofo */
  PacificFakaofo = 'PACIFIC_FAKAOFO',
  /** Pacific/Fiji */
  PacificFiji = 'PACIFIC_FIJI',
  /** Pacific/Funafuti */
  PacificFunafuti = 'PACIFIC_FUNAFUTI',
  /** Pacific/Galapagos */
  PacificGalapagos = 'PACIFIC_GALAPAGOS',
  /** Pacific/Gambier */
  PacificGambier = 'PACIFIC_GAMBIER',
  /** Pacific/Guadalcanal */
  PacificGuadalcanal = 'PACIFIC_GUADALCANAL',
  /** Pacific/Guam */
  PacificGuam = 'PACIFIC_GUAM',
  /** Pacific/Honolulu */
  PacificHonolulu = 'PACIFIC_HONOLULU',
  /** Pacific/Johnston */
  PacificJohnston = 'PACIFIC_JOHNSTON',
  /** Pacific/Kanton */
  PacificKanton = 'PACIFIC_KANTON',
  /** Pacific/Kiritimati */
  PacificKiritimati = 'PACIFIC_KIRITIMATI',
  /** Pacific/Kosrae */
  PacificKosrae = 'PACIFIC_KOSRAE',
  /** Pacific/Kwajalein */
  PacificKwajalein = 'PACIFIC_KWAJALEIN',
  /** Pacific/Majuro */
  PacificMajuro = 'PACIFIC_MAJURO',
  /** Pacific/Marquesas */
  PacificMarquesas = 'PACIFIC_MARQUESAS',
  /** Pacific/Midway */
  PacificMidway = 'PACIFIC_MIDWAY',
  /** Pacific/Nauru */
  PacificNauru = 'PACIFIC_NAURU',
  /** Pacific/Niue */
  PacificNiue = 'PACIFIC_NIUE',
  /** Pacific/Norfolk */
  PacificNorfolk = 'PACIFIC_NORFOLK',
  /** Pacific/Noumea */
  PacificNoumea = 'PACIFIC_NOUMEA',
  /** Pacific/Pago_Pago */
  PacificPagoPago = 'PACIFIC_PAGO_PAGO',
  /** Pacific/Palau */
  PacificPalau = 'PACIFIC_PALAU',
  /** Pacific/Pitcairn */
  PacificPitcairn = 'PACIFIC_PITCAIRN',
  /** Pacific/Pohnpei */
  PacificPohnpei = 'PACIFIC_POHNPEI',
  /** Pacific/Ponape */
  PacificPonape = 'PACIFIC_PONAPE',
  /** Pacific/Port_Moresby */
  PacificPortMoresby = 'PACIFIC_PORT_MORESBY',
  /** Pacific/Rarotonga */
  PacificRarotonga = 'PACIFIC_RAROTONGA',
  /** Pacific/Saipan */
  PacificSaipan = 'PACIFIC_SAIPAN',
  /** Pacific/Samoa */
  PacificSamoa = 'PACIFIC_SAMOA',
  /** Pacific/Tahiti */
  PacificTahiti = 'PACIFIC_TAHITI',
  /** Pacific/Tarawa */
  PacificTarawa = 'PACIFIC_TARAWA',
  /** Pacific/Tongatapu */
  PacificTongatapu = 'PACIFIC_TONGATAPU',
  /** Pacific/Truk */
  PacificTruk = 'PACIFIC_TRUK',
  /** Pacific/Wake */
  PacificWake = 'PACIFIC_WAKE',
  /** Pacific/Wallis */
  PacificWallis = 'PACIFIC_WALLIS',
  /** Pacific/Yap */
  PacificYap = 'PACIFIC_YAP',
  /** Poland */
  Poland = 'POLAND',
  /** Portugal */
  Portugal = 'PORTUGAL',
  /** PRC */
  Prc = 'PRC',
  /** PST8PDT */
  Pst8Pdt = 'PST8PDT',
  /** ROC */
  Roc = 'ROC',
  /** ROK */
  Rok = 'ROK',
  /** Singapore */
  Singapore = 'SINGAPORE',
  /** Turkey */
  Turkey = 'TURKEY',
  /** UCT */
  Uct = 'UCT',
  /** Universal */
  Universal = 'UNIVERSAL',
  /** US/Alaska */
  UsAlaska = 'US_ALASKA',
  /** US/Aleutian */
  UsAleutian = 'US_ALEUTIAN',
  /** US/Arizona */
  UsArizona = 'US_ARIZONA',
  /** US/Central */
  UsCentral = 'US_CENTRAL',
  /** US/Eastern */
  UsEastern = 'US_EASTERN',
  /** US/East-Indiana */
  UsEastIndiana = 'US_EAST_INDIANA',
  /** US/Hawaii */
  UsHawaii = 'US_HAWAII',
  /** US/Indiana-Starke */
  UsIndianaStarke = 'US_INDIANA_STARKE',
  /** US/Michigan */
  UsMichigan = 'US_MICHIGAN',
  /** US/Mountain */
  UsMountain = 'US_MOUNTAIN',
  /** US/Pacific */
  UsPacific = 'US_PACIFIC',
  /** US/Samoa */
  UsSamoa = 'US_SAMOA',
  /** UTC */
  Utc = 'UTC',
  /** WET */
  Wet = 'WET',
  /** W-SU */
  WSu = 'W_SU',
  /** Zulu */
  Zulu = 'ZULU'
}

/** An enumeration. */
export enum LocationCountry {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** \u00c5land Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barth\u00e9lemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei Darussalam */
  Bn = 'BN',
  /** Bolivia, Plurinational State of */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo, the Democratic Republic of the */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** C\u00f4te d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cape Verde */
  Cv = 'CV',
  /** Cura\u00e7ao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czech Republic */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia, Federated States of */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran, Islamic Republic of */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Korea, Democratic People's Republic of */
  Kp = 'KP',
  /** Korea, Republic of */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Lao People's Democratic Republic */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova, Republic of */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** Macedonia, the Former Yugoslav Republic of */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** R\u00e9union */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russian Federation */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syrian Arab Republic */
  Sy = 'SY',
  /** Swaziland */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan, Province of China */
  Tw = 'TW',
  /** Tanzania, United Republic of */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See (Vatican City State) */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela, Bolivarian Republic of */
  Ve = 'VE',
  /** Virgin Islands, British */
  Vg = 'VG',
  /** Virgin Islands, U.S. */
  Vi = 'VI',
  /** Viet Nam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** An enumeration. */
export enum LocationState {
  /** Alabama */
  Alabama = 'ALABAMA',
  /** Alaska */
  Alaska = 'ALASKA',
  /** Arizona */
  Arizona = 'ARIZONA',
  /** Arkansas */
  Arkansas = 'ARKANSAS',
  /** - */
  A = 'A_',
  /** California */
  California = 'CALIFORNIA',
  /** Colorado */
  Colorado = 'COLORADO',
  /** Connecticut */
  Connecticut = 'CONNECTICUT',
  /** Delaware */
  Delaware = 'DELAWARE',
  /** Florida */
  Florida = 'FLORIDA',
  /** Georgia */
  Georgia = 'GEORGIA',
  /** Hawaii */
  Hawaii = 'HAWAII',
  /** Idaho */
  Idaho = 'IDAHO',
  /** Illinois */
  Illinois = 'ILLINOIS',
  /** Indiana */
  Indiana = 'INDIANA',
  /** Iowa */
  Iowa = 'IOWA',
  /** Kansas */
  Kansas = 'KANSAS',
  /** Kentucky */
  Kentucky = 'KENTUCKY',
  /** Louisiana */
  Louisiana = 'LOUISIANA',
  /** Maine */
  Maine = 'MAINE',
  /** Maryland */
  Maryland = 'MARYLAND',
  /** Massachusetts */
  Massachusetts = 'MASSACHUSETTS',
  /** Michigan */
  Michigan = 'MICHIGAN',
  /** Minnesota */
  Minnesota = 'MINNESOTA',
  /** Mississippi */
  Mississippi = 'MISSISSIPPI',
  /** Missouri */
  Missouri = 'MISSOURI',
  /** Montana */
  Montana = 'MONTANA',
  /** Nebraska */
  Nebraska = 'NEBRASKA',
  /** Nevada */
  Nevada = 'NEVADA',
  /** New Hampshire */
  NewHampshire = 'NEW_HAMPSHIRE',
  /** New Jersey */
  NewJersey = 'NEW_JERSEY',
  /** New Mexico */
  NewMexico = 'NEW_MEXICO',
  /** New York */
  NewYork = 'NEW_YORK',
  /** North Carolina */
  NorthCarolina = 'NORTH_CAROLINA',
  /** North Dakota */
  NorthDakota = 'NORTH_DAKOTA',
  /** Ohio */
  Ohio = 'OHIO',
  /** Oklahoma */
  Oklahoma = 'OKLAHOMA',
  /** Oregon */
  Oregon = 'OREGON',
  /** Pennsylvania */
  Pennsylvania = 'PENNSYLVANIA',
  /** Rhode Island */
  RhodeIsland = 'RHODE_ISLAND',
  /** South Carolina */
  SouthCarolina = 'SOUTH_CAROLINA',
  /** South Dakota */
  SouthDakota = 'SOUTH_DAKOTA',
  /** Tennessee */
  Tennessee = 'TENNESSEE',
  /** Texas */
  Texas = 'TEXAS',
  /** Utah */
  Utah = 'UTAH',
  /** Vermont */
  Vermont = 'VERMONT',
  /** Virginia */
  Virginia = 'VIRGINIA',
  /** Washington */
  Washington = 'WASHINGTON',
  /** West Virginia */
  WestVirginia = 'WEST_VIRGINIA',
  /** Wisconsin */
  Wisconsin = 'WISCONSIN',
  /** Wyoming */
  Wyoming = 'WYOMING'
}

/** Location */
export type LocationType = {
  __typename?: 'LocationType';
  city: Scalars['String'];
  country: LocationCountry;
  line: Array<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state: LocationState;
  /** The address as a single string */
  text?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

/**
 * LogEvent logs an event sent from a pdt for a given care team and creates and/or expires nudge opportunities affected
 * by the event.
 */
export type LogEvent = {
  __typename?: 'LogEvent';
  /** Successfully logged event */
  success?: Maybe<Scalars['Boolean']>;
};

/** MarkNudgesSeen marks nudges in patient inbox as seen. */
export type MarkNudgesSeen = {
  __typename?: 'MarkNudgesSeen';
  /** Successfully marked nudges seen */
  success?: Maybe<Scalars['Boolean']>;
};

/** Mark a study participant as Lost to Follow-up */
export type MarkParticipantAsLost = {
  __typename?: 'MarkParticipantAsLost';
  /** Whether the study participant was marked as lost to follow-up successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Mark a study participant as a screen fail */
export type MarkParticipantAsScreenFail = {
  __typename?: 'MarkParticipantAsScreenFail';
  /** Whether the study participant was marked as screen fail successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** (Deprecated) Mood log that a user creates during use of a PDT */
export type MoodLogType = {
  __typename?: 'MoodLogType';
  createdAt: Scalars['DateTime'];
  date?: Maybe<Scalars['DateTime']>;
  extraData?: Maybe<Scalars['JSONString']>;
  mood?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Activate study participant */
  activateParticipant?: Maybe<ActivateParticipant>;
  /** Add a study CoInvestigator to a study */
  addStudyCoInvestigator?: Maybe<AddStudyCoInvestigator>;
  /** Add a study coordinator to a study */
  addStudyCoordinator?: Maybe<AddStudyCoordinator>;
  /** Assign a clinician report to a clinical concern */
  assignClinicianReport?: Maybe<AssignClinicianReport>;
  /** Cancel a periodic push notification */
  cancelPeriodicPushNotification?: Maybe<CancelPeriodicPushNotification>;
  /** Cancel periodic push notifications by type */
  cancelPeriodicPushNotificationsByType?: Maybe<CancelPeriodicPushNotificationByType>;
  /** Cancel a push notification */
  cancelPushNotification?: Maybe<CancelPushNotification>;
  /** Cancel push notifications by type */
  cancelPushNotificationsByType?: Maybe<CancelPushNotificationsByType>;
  /** Mark when an assignment was completed */
  completeAssignment?: Maybe<CompleteAssignment>;
  /** Complete a study participant */
  completeParticipant?: Maybe<CompleteParticipant>;
  /** Mark when a research assignment was completed */
  completeResearchAssignment?: Maybe<CompleteResearchAssignment>;
  /** Create user account */
  createAccount?: Maybe<CreateAccount>;
  /** Create a new assessment */
  createAssessment?: Maybe<CreateAssessment>;
  /** Create a BA task */
  createBehavioralActivationTask?: Maybe<CreateBehavioralActivationTask>;
  /** Create the base Clinical Concern object with necessary data */
  createClinicalConcern?: Maybe<CreateClinicalConcern>;
  /** Create a free-form text */
  createFreeFormText?: Maybe<CreateFreeFormText>;
  /** Create clinical concerns with mock data */
  createMockClinicalConcerns?: Maybe<CreateMockClinicalConcerns>;
  /** Create mock assignments for a study based on its assignment schedules */
  createMockStudyAssignments?: Maybe<CreateMockStudyAssignments>;
  /** Create a mood log */
  createMoodLog?: Maybe<CreateMoodLog>;
  /** Create a new organization */
  createOrganization?: Maybe<CreateOrganization>;
  /** Create a new limbix user and a Patient tied to it */
  createPatientAccount?: Maybe<CreatePatientAccount>;
  /** Create a new limbix user and a Practitioner tied to it */
  createPractitionerAccount?: Maybe<CreatePractitionerAccount>;
  /** Create totp authenticator for current user */
  createTotpAuthenticator?: Maybe<CreateTotpAuthenticator>;
  /** Create a new user activity log */
  createUserActivityLog?: Maybe<CreateUserActivityLog>;
  /** Create a user log */
  createUserLog?: Maybe<CreateUserLog>;
  /** Decode an organization signup token */
  decodeOrganizationSignupToken?: Maybe<DecodeOrganizationSignupToken>;
  /** Delete an existing BA task */
  deleteBehavioralActivationTask?: Maybe<DeleteBehavioralActivationTask>;
  /** Delete a clinical concern */
  deleteClinicalConcern?: Maybe<DeleteClinicalConcern>;
  /** Delete a mood log */
  deleteMoodLog?: Maybe<DeleteMoodLog>;
  /** Delete study participant */
  deleteParticipant?: Maybe<DeleteParticipant>;
  /** Delete a study assignment schedule */
  deleteStudyAssignmentSchedule?: Maybe<DeleteStudyAssignmentSchedule>;
  /** Delete an user activity log */
  deleteUserActivityLog?: Maybe<DeleteUserActivityLog>;
  /** Delete a user log */
  deleteUserLog?: Maybe<DeleteUserLog>;
  /** Revoke a patient's mobile access and mobile refresh tokens */
  discontinueCare?: Maybe<DiscontinueCare>;
  /** Drop a study participant */
  dropParticipant?: Maybe<DropParticipant>;
  /** Edit a pre-existing assessment */
  editAssessment?: Maybe<EditAssessment>;
  /** Edit a user's parents */
  editParents?: Maybe<EditParents>;
  /** Edit a Study Participant's Notes, ID, Language, Account Type, First Name, Last Name, Gender, Date of Birth, and/or Referral Source */
  editParticipantData?: Maybe<EditParticipantData>;
  /** Edit a patient's info */
  editPatientInfo?: Maybe<EditPatientInfo>;
  /** Edit a patient's notes */
  editPractitionerNotes?: Maybe<EditPractitionerNotes>;
  /** Create new commercial patient and enroll them with PDT */
  enrollDirectAccessPatient?: Maybe<EnrollDirectAccessPatient>;
  /** Create new patient and enroll them with PDT */
  enrollPatient?: Maybe<EnrollPatient>;
  /** Expire triggered nudges that are not the most recent nudge in their category */
  expireOutdatedNudges?: Maybe<ExpireOutdatedNudges>;
  /** Renew a patient's access to a PDT using an external organization token */
  externalOrganizationAccessRenewal?: Maybe<ExternalOrganizationAccessRenewal>;
  /** Create a user account using an external organization signup token */
  externalOrganizationSignup?: Maybe<ExternalOrganizationSignup>;
  /** Generate a participant's case report form */
  generateCaseReportForm?: Maybe<GenerateCaseReportForm>;
  /** Generate the case report forms for all participants in a study */
  generateCaseReportForms?: Maybe<GenerateCaseReportForms>;
  /** Generate the centralized change log for all study objects */
  generateCentralizedChangeLogCsv?: Maybe<GenerateCentralizedChangeLogCsv>;
  /** Generate the clinical concerns for all participants in a study */
  generateClinicalConcernsCsv?: Maybe<GenerateClinicalConcernsCsv>;
  /** Get a list of urls for downloading access code CSV files */
  getAccessCodeCsvUrls?: Maybe<GetAccessCodeCsvUrls>;
  /** Grant a practitioner Provider Portal access */
  grantProviderPortalAccess?: Maybe<GrantProviderPortalAccess>;
  /** Issue a new access code for a practitioner's a pdt */
  issueNewAccessCode?: Maybe<IssueNewAccessCode>;
  /** Log an event for a given care team */
  logEvent?: Maybe<LogEvent>;
  /** Mark nudge as seen by caller */
  markNudgesSeen?: Maybe<MarkNudgesSeen>;
  /** Mark a study participant as Lost to Follow-up */
  markParticipantAsLost?: Maybe<MarkParticipantAsLost>;
  /** Mark a study participant as screen fail */
  markParticipantAsScreenFail?: Maybe<MarkParticipantAsScreenFail>;
  /** Refresh an auth token given a refresh token */
  refreshToken?: Maybe<LimbixRefresh>;
  /** Refresh a mobile auth token given a refresh token */
  refreshTokenMobile?: Maybe<RefreshTokenMobile>;
  /** Register an existing user */
  registerExistingUser?: Maybe<RegisterExistingUser>;
  /** Create and register a new user */
  registerNewUser?: Maybe<RegisterNewUser>;
  /** Remove a Study CoInvestigator from a study */
  removeStudyCoInvestigator?: Maybe<RemoveStudyCoInvestigator>;
  /** Remove a study coordinator from a study */
  removeStudyCoordinator?: Maybe<RemoveStudyCoordinator>;
  /** Report an adverse event */
  reportAdverseEvent?: Maybe<ReportAdverseEvent>;
  /** Report an assessment result */
  reportAssessmentResult?: Maybe<ReportAssessmentResult>;
  /** Report assessment answers for a Clinical Concern */
  reportClinicalConcern?: Maybe<ReportClinicalConcern>;
  /** Report an assessment result */
  reportParticipantAssessmentResult?: Maybe<ReportParticipantAssessmentResult>;
  /** Report a research assessment result */
  reportResearchAssessmentResult?: Maybe<ReportResearchAssessmentResult>;
  /** Report when a user completes a task */
  reportTaskComplete?: Maybe<ReportTaskComplete>;
  /** Report when a user starts a task */
  reportTaskStart?: Maybe<ReportTaskStart>;
  /** Resend a practitioner's access code for a pdt */
  resendAccessCode?: Maybe<ResendAccessCode>;
  /** Resend verification email */
  resendEmailVerification?: Maybe<ResendEmailVerification>;
  /** Reset users login access attempts */
  resetAccessAttempts?: Maybe<ResetAccessAttempts>;
  /** Send reset password email for account */
  resetPassword?: Maybe<ResetPassword>;
  /** Reset a patient's access attempts */
  resetPatientAccessAttempts?: Maybe<ResetPatientAccessAttempts>;
  /** Revoke a practitioner's access code for a pdt */
  revokeAccessCode?: Maybe<RevokeAccessCode>;
  /** Revoke a participant's mobile access */
  revokeMobileAccess?: Maybe<RevokeMobileAccess>;
  /** Revoke a patient's mobile access and mobile refresh tokens */
  revokePatientMobileAccess?: Maybe<RevokePatientMobileAccess>;
  /** Revoke a practitioner's Provider Portal access */
  revokeProviderPortalAccess?: Maybe<RevokeProviderPortalAccess>;
  revokeToken?: Maybe<Revoke>;
  /** Schedule a periodic push notification */
  schedulePeriodicPushNotification?: Maybe<SchedulePeriodicPushNotification>;
  /** Schedule a push notification */
  schedulePushNotification?: Maybe<SchedulePushNotification>;
  /** Create a study participant account with an access code */
  selfServeCreateParticipant?: Maybe<SelfServeCreateParticipant>;
  /** Create a new limbix user and a Patient tied to it */
  selfServeCreatePatient?: Maybe<SelfServeCreatePatient>;
  /** Create a new limbix user and a Practitioner tied to it */
  selfServeCreatePractitioner?: Maybe<SelfServeCreatePractitioner>;
  /** Mark nudge as sent by caller and store when the nudge was sent */
  sendNudge?: Maybe<SendNudge>;
  /** Sets a user as blinded or unblinded for a particular study */
  setUserBlinded?: Maybe<SetUserBlinded>;
  /** Mark when an assignment was started */
  startAssignment?: Maybe<StartAssignment>;
  /** Mark when a research assignment was started */
  startResearchAssignment?: Maybe<StartResearchAssignment>;
  /** Get auth token for an account given valid credentials */
  tokenAuth?: Maybe<TokenAuth>;
  /** Get auth token for an account given valid credentials and one time password */
  tokenAuthMfa?: Maybe<TokenAuthMfa>;
  /** Get mobile auth token for an account given valid credentials */
  tokenAuthMobile?: Maybe<TokenAuthMobile>;
  /** Transition AccountType.CHILD to ADULT */
  transitionToAdult?: Maybe<TransitionToAdult>;
  /** Update user's app start date for a given PDT */
  updateAppStartDate?: Maybe<UpdateAppStartDate>;
  /** Update an existing BA task */
  updateBehavioralActivationTask?: Maybe<UpdateBehavioralActivationTask>;
  /** Update user's current module and task number for a given PDT */
  updateCurrentModuleAndTask?: Maybe<UpdateCurrentModuleAndTask>;
  /** Update enrollment tracker data for a participant */
  updateEnrollmentTracker?: Maybe<UpdateEnrollmentTracker>;
  /** Update user's last engagement date/time for a given PDT */
  updateLastEngagementTime?: Maybe<UpdateLastEngagementTime>;
  /** Update local timezone associated with user account */
  updateLocalTimezone?: Maybe<UpdateLocalTimezone>;
  /** Update a mood log */
  updateMoodLog?: Maybe<UpdateMoodLog>;
  /** Update or create a FCM device */
  updateOrCreateFcmDevice?: Maybe<UpdateOrCreateFcmDevice>;
  /** Update user's password */
  updatePassword?: Maybe<UpdatePassword>;
  /** Update a study assignment schedule if it exists, otherwise create one */
  updateStudyAssignmentSchedule?: Maybe<UpdateStudyAssignmentSchedule>;
  /** Update a study's name */
  updateStudyName?: Maybe<UpdateStudyName>;
  /** Update additional data associated with a task */
  updateTaskAppData?: Maybe<UpdateTaskAppData>;
  /** Update an existing user activity log */
  updateUserActivityLog?: Maybe<UpdateUserActivityLog>;
  /** Update language associated with user account */
  updateUserLanguage?: Maybe<UpdateUserLanguage>;
  /** Update a user log */
  updateUserLog?: Maybe<UpdateUserLog>;
  /** Update user's settings for a given PDT */
  updateUserSettings?: Maybe<UpdateUserSettings>;
  /** Verify an access code */
  verifyAccessCode?: Maybe<VerifyAccessCode>;
  verifyToken?: Maybe<Verify>;
  /** Withdraw a study participant */
  withdrawParticipant?: Maybe<WithdrawParticipant>;
};


export type MutationActivateParticipantArgs = {
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationAddStudyCoInvestigatorArgs = {
  blinded?: InputMaybe<Scalars['Boolean']>;
  studyUid: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationAddStudyCoordinatorArgs = {
  blinded?: InputMaybe<Scalars['Boolean']>;
  studyUid: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationAssignClinicianReportArgs = {
  clinicalConcernUid: Scalars['String'];
  studyUid: Scalars['String'];
};


export type MutationCancelPeriodicPushNotificationArgs = {
  periodicNotificationUid: Scalars['String'];
};


export type MutationCancelPeriodicPushNotificationsByTypeArgs = {
  notificationType: Scalars['String'];
  pdtUid: Scalars['String'];
};


export type MutationCancelPushNotificationArgs = {
  notificationTaskUid: Scalars['String'];
};


export type MutationCancelPushNotificationsByTypeArgs = {
  notificationType: Scalars['String'];
  pdtUid: Scalars['String'];
};


export type MutationCompleteAssignmentArgs = {
  assignmentUid: Scalars['String'];
  completeTime: Scalars['DateTime'];
  pdtUid?: InputMaybe<Scalars['String']>;
};


export type MutationCompleteParticipantArgs = {
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationCompleteResearchAssignmentArgs = {
  assignmentUid: Scalars['String'];
  completeTime: Scalars['DateTime'];
  studyUid: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateAssessmentArgs = {
  assessmentData: AssessmentInputType;
};


export type MutationCreateBehavioralActivationTaskArgs = {
  activity?: InputMaybe<Scalars['String']>;
  behavioralActivationTaskUid?: InputMaybe<Scalars['String']>;
  completeTime?: InputMaybe<Scalars['DateTime']>;
  didCompleteScheduledActivation?: InputMaybe<Scalars['Boolean']>;
  extraData?: InputMaybe<Scalars['JSONString']>;
  pdtUid: Scalars['String'];
  scheduledDate?: InputMaybe<Scalars['DateTime']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};


export type MutationCreateClinicalConcernArgs = {
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationCreateFreeFormTextArgs = {
  denyListMatches?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  prompt: Scalars['String'];
  value: Scalars['String'];
};


export type MutationCreateMockClinicalConcernsArgs = {
  count?: InputMaybe<Scalars['Int']>;
  studyUid: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationCreateMockStudyAssignmentsArgs = {
  adultParticipantEmail?: InputMaybe<Scalars['String']>;
  childParticipantEmail?: InputMaybe<Scalars['String']>;
  parentParticipantEmail?: InputMaybe<Scalars['String']>;
  researchUserEmail?: InputMaybe<Scalars['String']>;
  studyUid: Scalars['String'];
  withAutogeneratedAnswers?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateMoodLogArgs = {
  date: Scalars['DateTime'];
  extraData?: InputMaybe<Scalars['JSONString']>;
  mood: Scalars['String'];
  moodLogUid?: InputMaybe<Scalars['String']>;
  pdtUid: Scalars['String'];
  text?: InputMaybe<Scalars['String']>;
};


export type MutationCreateOrganizationArgs = {
  address: Scalars['String'];
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  identifier?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationType?: InputMaybe<Scalars['String']>;
  parentOrganizationUid?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
};


export type MutationCreatePatientAccountArgs = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  emergencyContactName?: InputMaybe<Scalars['String']>;
  emergencyContactPhone?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};


export type MutationCreatePractitionerAccountArgs = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  identifier?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  organizationUid?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  practitionerRole?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};


export type MutationCreateUserActivityLogArgs = {
  activity?: InputMaybe<Scalars['String']>;
  activityLogUid?: InputMaybe<Scalars['String']>;
  activityType: Scalars['String'];
  completeTime?: InputMaybe<Scalars['DateTime']>;
  didCompleteScheduledActivation?: InputMaybe<Scalars['Boolean']>;
  extraData?: InputMaybe<Scalars['JSONString']>;
  pdtUid: Scalars['String'];
  scheduledDate?: InputMaybe<Scalars['DateTime']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};


export type MutationCreateUserLogArgs = {
  category?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['DateTime']>;
  extraData?: InputMaybe<Scalars['JSONString']>;
  logUid?: InputMaybe<Scalars['String']>;
  mood?: InputMaybe<Scalars['String']>;
  pdtUid: Scalars['String'];
  sudsRating?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
};


export type MutationDecodeOrganizationSignupTokenArgs = {
  pdtUid: Scalars['String'];
  token: Scalars['String'];
  tokenIssuer: Scalars['String'];
};


export type MutationDeleteBehavioralActivationTaskArgs = {
  behavioralActivationTaskUid: Scalars['String'];
};


export type MutationDeleteClinicalConcernArgs = {
  changeReason: Scalars['String'];
  clinicalConcernUid: Scalars['String'];
  studyUid: Scalars['String'];
};


export type MutationDeleteMoodLogArgs = {
  moodLogUid: Scalars['String'];
};


export type MutationDeleteParticipantArgs = {
  changeReason: Scalars['String'];
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationDeleteStudyAssignmentScheduleArgs = {
  assignmentScheduleUid: Scalars['String'];
  changeReason?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteUserActivityLogArgs = {
  activityLogUid: Scalars['String'];
};


export type MutationDeleteUserLogArgs = {
  logUid: Scalars['String'];
};


export type MutationDiscontinueCareArgs = {
  careteamUid: Scalars['String'];
};


export type MutationDropParticipantArgs = {
  changeReason: Scalars['String'];
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationEditAssessmentArgs = {
  assessmentData?: InputMaybe<AssessmentInputType>;
  changeReason: Scalars['String'];
};


export type MutationEditParentsArgs = {
  changeReason: Scalars['String'];
  childUid: Scalars['String'];
  parentUids: Array<InputMaybe<Scalars['String']>>;
  studyUid: Scalars['String'];
};


export type MutationEditParticipantDataArgs = {
  accountType?: InputMaybe<Scalars['String']>;
  changeReason: Scalars['String'];
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  referralSource?: InputMaybe<Scalars['String']>;
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationEditPatientInfoArgs = {
  changeReason: Scalars['String'];
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patientUid: Scalars['String'];
};


export type MutationEditPractitionerNotesArgs = {
  changeReason: Scalars['String'];
  notes: Scalars['String'];
  practitionerUid: Scalars['String'];
};


export type MutationEnrollDirectAccessPatientArgs = {
  address: Scalars['String'];
  city: Scalars['String'];
  dateOfBirth: Scalars['DateTime'];
  email: Scalars['String'];
  emergencyContactEmail?: InputMaybe<Scalars['String']>;
  emergencyContactName?: InputMaybe<Scalars['String']>;
  emergencyContactPhone?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organizationSlug: Scalars['String'];
  pdtUid: Scalars['String'];
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
};


export type MutationEnrollPatientArgs = {
  dateOfBirth: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  pdtUid: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};


export type MutationExternalOrganizationAccessRenewalArgs = {
  externalIdentifier: Scalars['String'];
  pdtUid: Scalars['String'];
  token: Scalars['String'];
  tokenIssuer: Scalars['String'];
};


export type MutationExternalOrganizationSignupArgs = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  externalIdentifier?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  initialAppVersion?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  pdtUid: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  tokenIssuer: Scalars['String'];
};


export type MutationGenerateCaseReportFormArgs = {
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationGenerateCaseReportFormsArgs = {
  studyUid: Scalars['String'];
};


export type MutationGenerateCentralizedChangeLogCsvArgs = {
  studyUid: Scalars['String'];
};


export type MutationGenerateClinicalConcernsCsvArgs = {
  participantAttributes?: InputMaybe<Scalars['JSONString']>;
  studyUid: Scalars['String'];
  userUid?: InputMaybe<Scalars['String']>;
};


export type MutationGrantProviderPortalAccessArgs = {
  changeReason: Scalars['String'];
  portalAccessTime: Scalars['DateTime'];
  practitionerUid: Scalars['String'];
};


export type MutationIssueNewAccessCodeArgs = {
  pdtUid: Scalars['String'];
  practitionerUid: Scalars['String'];
};


export type MutationLogEventArgs = {
  eventName: Scalars['String'];
  happenedAt: Scalars['DateTime'];
  pdtUid: Scalars['String'];
};


export type MutationMarkNudgesSeenArgs = {
  pdtUid: Scalars['String'];
};


export type MutationMarkParticipantAsLostArgs = {
  changeReason: Scalars['String'];
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationMarkParticipantAsScreenFailArgs = {
  changeReason: Scalars['String'];
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};


export type MutationRefreshTokenMobileArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};


export type MutationRegisterExistingUserArgs = {
  referralSource?: InputMaybe<Scalars['String']>;
  studyUid: Scalars['String'];
  userEmail?: InputMaybe<Scalars['String']>;
  userUid?: InputMaybe<Scalars['String']>;
};


export type MutationRegisterNewUserArgs = {
  accountType: Scalars['String'];
  dateOfBirth: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  referralSource?: InputMaybe<Scalars['String']>;
  studyUid: Scalars['String'];
};


export type MutationRemoveStudyCoInvestigatorArgs = {
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationRemoveStudyCoordinatorArgs = {
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationReportAdverseEventArgs = {
  assignmentUid?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  isCausedByTreatment: Scalars['Boolean'];
  pdtUid: Scalars['String'];
  severity: Scalars['Int'];
};


export type MutationReportAssessmentResultArgs = {
  assessmentAnswers: Array<InputMaybe<Scalars['String']>>;
  assessmentResultUid?: InputMaybe<Scalars['String']>;
  assessmentUid: Scalars['String'];
  assignmentUid: Scalars['String'];
  pdtUid: Scalars['String'];
};


export type MutationReportClinicalConcernArgs = {
  assessmentAnswers: Array<InputMaybe<Scalars['String']>>;
  changeReason: Scalars['String'];
  clinicalConcernUid: Scalars['String'];
  studyUid: Scalars['String'];
};


export type MutationReportParticipantAssessmentResultArgs = {
  assessmentAnswers: Array<InputMaybe<Scalars['String']>>;
  assessmentResultUid?: InputMaybe<Scalars['String']>;
  assessmentUid: Scalars['String'];
  assignmentCompleteTime?: InputMaybe<Scalars['DateTime']>;
  assignmentUid: Scalars['String'];
  pdtUid: Scalars['String'];
};


export type MutationReportResearchAssessmentResultArgs = {
  assessmentAnswers: Array<InputMaybe<Scalars['String']>>;
  assessmentResultUid?: InputMaybe<Scalars['String']>;
  assignmentUid: Scalars['String'];
  changeReason?: InputMaybe<Scalars['String']>;
  studyUid: Scalars['String'];
};


export type MutationReportTaskCompleteArgs = {
  completeTime: Scalars['DateTime'];
  moduleNumber: Scalars['Int'];
  pdtUid: Scalars['String'];
  taskNumber: Scalars['Int'];
};


export type MutationReportTaskStartArgs = {
  moduleNumber: Scalars['Int'];
  pdtUid: Scalars['String'];
  startTime: Scalars['DateTime'];
  taskNumber: Scalars['Int'];
};


export type MutationResendAccessCodeArgs = {
  pdtUid: Scalars['String'];
  practitionerUid: Scalars['String'];
};


export type MutationResendEmailVerificationArgs = {
  email: Scalars['String'];
};


export type MutationResetAccessAttemptsArgs = {
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPatientAccessAttemptsArgs = {
  patientUid: Scalars['String'];
  pdtUid: Scalars['String'];
};


export type MutationRevokeAccessCodeArgs = {
  changeReason: Scalars['String'];
  pdtUid: Scalars['String'];
  practitionerUid: Scalars['String'];
};


export type MutationRevokeMobileAccessArgs = {
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationRevokePatientMobileAccessArgs = {
  changeReason: Scalars['String'];
  patientUid: Scalars['String'];
  pdtUid: Scalars['String'];
};


export type MutationRevokeProviderPortalAccessArgs = {
  changeReason: Scalars['String'];
  practitionerUid: Scalars['String'];
};


export type MutationRevokeTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};


export type MutationSchedulePeriodicPushNotificationArgs = {
  endTime?: InputMaybe<Scalars['DateTime']>;
  frequencyHours: Scalars['Int'];
  notificationContent: Array<InputMaybe<Array<InputMaybe<Scalars['String']>>>>;
  notificationType: Scalars['String'];
  pdtUid: Scalars['String'];
  startTime: Scalars['DateTime'];
  uid?: InputMaybe<Scalars['String']>;
};


export type MutationSchedulePushNotificationArgs = {
  eta: Scalars['DateTime'];
  extraData?: InputMaybe<Scalars['JSONString']>;
  message: Scalars['String'];
  notificationType: Scalars['String'];
  pdtUid: Scalars['String'];
  title: Scalars['String'];
};


export type MutationSelfServeCreateParticipantArgs = {
  accessCode: Scalars['String'];
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  participantId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  pdtUid: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};


export type MutationSelfServeCreatePatientArgs = {
  accessCode: Scalars['String'];
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  dateOfBirth: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  initialAppVersion?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  pdtUid: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};


export type MutationSelfServeCreatePractitionerArgs = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  identifierState?: InputMaybe<Scalars['String']>;
  identifierSystem: Scalars['String'];
  identifierValue: Scalars['String'];
  lastName: Scalars['String'];
  organizationEmail: Scalars['String'];
  organizationName: Scalars['String'];
  organizationPhone: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  pdtUid: Scalars['String'];
  phone: Scalars['String'];
  position?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  preferredContactMethod?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};


export type MutationSendNudgeArgs = {
  careteamUid: Scalars['String'];
  nudgeUid: Scalars['String'];
};


export type MutationSetUserBlindedArgs = {
  blinded: Scalars['Boolean'];
  studyUid: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationStartAssignmentArgs = {
  assignmentUid: Scalars['String'];
  pdtUid?: InputMaybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
};


export type MutationStartResearchAssignmentArgs = {
  assignmentUid: Scalars['String'];
  startTime: Scalars['DateTime'];
  studyUid: Scalars['String'];
};


export type MutationTokenAuthArgs = {
  clientType?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationTokenAuthMfaArgs = {
  clientType?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  mfaCode?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};


export type MutationTokenAuthMobileArgs = {
  email: Scalars['String'];
  initialAppVersion?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  pdtUid: Scalars['String'];
};


export type MutationTransitionToAdultArgs = {
  changeReason: Scalars['String'];
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationUpdateAppStartDateArgs = {
  appStartDate: Scalars['DateTime'];
  pdtUid: Scalars['String'];
};


export type MutationUpdateBehavioralActivationTaskArgs = {
  activity?: InputMaybe<Scalars['String']>;
  behavioralActivationTaskUid: Scalars['String'];
  completeTime?: InputMaybe<Scalars['DateTime']>;
  didCompleteScheduledActivation?: InputMaybe<Scalars['Boolean']>;
  extraData?: InputMaybe<Scalars['JSONString']>;
  scheduledDate?: InputMaybe<Scalars['DateTime']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};


export type MutationUpdateCurrentModuleAndTaskArgs = {
  currentModuleNumber: Scalars['Int'];
  currentTaskNumber: Scalars['Int'];
  pdtUid: Scalars['String'];
};


export type MutationUpdateEnrollmentTrackerArgs = {
  assessmentAnswers: Array<InputMaybe<Scalars['String']>>;
  changeReason: Scalars['String'];
  enrollmentTrackerResultUid: Scalars['String'];
  studyUid: Scalars['String'];
};


export type MutationUpdateLastEngagementTimeArgs = {
  lastEngagementTime: Scalars['DateTime'];
  pdtUid: Scalars['String'];
};


export type MutationUpdateLocalTimezoneArgs = {
  localTimezone: Scalars['String'];
};


export type MutationUpdateMoodLogArgs = {
  date?: InputMaybe<Scalars['DateTime']>;
  extraData?: InputMaybe<Scalars['JSONString']>;
  mood?: InputMaybe<Scalars['String']>;
  moodLogUid: Scalars['String'];
  text?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateOrCreateFcmDeviceArgs = {
  active: Scalars['Boolean'];
  registrationId: Scalars['String'];
};


export type MutationUpdatePasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
  userUid: Scalars['String'];
};


export type MutationUpdateStudyAssignmentScheduleArgs = {
  assignmentScheduleData: AssignmentScheduleInputType;
  changeReason: Scalars['String'];
  studyUid: Scalars['String'];
};


export type MutationUpdateStudyNameArgs = {
  name: Scalars['String'];
  studyUid: Scalars['String'];
};


export type MutationUpdateTaskAppDataArgs = {
  appData: Scalars['JSONString'];
  moduleNumber: Scalars['Int'];
  pdtUid: Scalars['String'];
  taskNumber: Scalars['Int'];
};


export type MutationUpdateUserActivityLogArgs = {
  activity?: InputMaybe<Scalars['String']>;
  activityLogUid: Scalars['String'];
  activityType?: InputMaybe<Scalars['String']>;
  completeTime?: InputMaybe<Scalars['DateTime']>;
  didCompleteScheduledActivation?: InputMaybe<Scalars['Boolean']>;
  extraData?: InputMaybe<Scalars['JSONString']>;
  scheduledDate?: InputMaybe<Scalars['DateTime']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};


export type MutationUpdateUserLanguageArgs = {
  language: Scalars['String'];
};


export type MutationUpdateUserLogArgs = {
  category?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['DateTime']>;
  extraData?: InputMaybe<Scalars['JSONString']>;
  logUid: Scalars['String'];
  mood?: InputMaybe<Scalars['String']>;
  sudsRating?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateUserSettingsArgs = {
  appSettings: Scalars['JSONString'];
  pdtUid: Scalars['String'];
};


export type MutationVerifyAccessCodeArgs = {
  accessCode: Scalars['String'];
  isStudyCode?: InputMaybe<Scalars['Boolean']>;
  pdtUid: Scalars['String'];
};


export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type MutationWithdrawParticipantArgs = {
  changeReason: Scalars['String'];
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};

/** An enumeration. */
export enum NotificationTaskPlatformType {
  /** Email */
  Email = 'EMAIL',
  /** Push Notification */
  Push = 'PUSH',
  /** SMS */
  Sms = 'SMS'
}

/** Scheduled notification */
export type NotificationTaskType = {
  __typename?: 'NotificationTaskType';
  eta: Scalars['DateTime'];
  notificationType: Scalars['String'];
  pdt?: Maybe<PdtType>;
  platformType: NotificationTaskPlatformType;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
  user: UserType;
};

export type NudgeMessageType = {
  __typename?: 'NudgeMessageType';
  body?: Maybe<Scalars['String']>;
  /** Body text */
  bodyTemplate?: Maybe<Scalars['TranslationString']>;
  bodyTemplateTranslations?: Maybe<TextTranslationType>;
  header?: Maybe<Scalars['String']>;
  /** Header text */
  headerTemplate?: Maybe<Scalars['TranslationString']>;
  headerTemplateTranslations?: Maybe<TextTranslationType>;
  nudgeOpportunityConfig?: Maybe<NudgeOpportunityConfigType>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};


export type NudgeMessageTypeBodyArgs = {
  nudgeOpportunityUid: Scalars['String'];
};


export type NudgeMessageTypeBodyTemplateArgs = {
  language?: InputMaybe<Scalars['String']>;
  withFallback?: InputMaybe<Scalars['Boolean']>;
};


export type NudgeMessageTypeHeaderArgs = {
  nudgeOpportunityUid: Scalars['String'];
};


export type NudgeMessageTypeHeaderTemplateArgs = {
  language?: InputMaybe<Scalars['String']>;
  withFallback?: InputMaybe<Scalars['Boolean']>;
};

export type NudgeOpportunityConfigType = {
  __typename?: 'NudgeOpportunityConfigType';
  active: Scalars['Boolean'];
  /** DEPRECATED: category of the nudge config */
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  internalName: Scalars['String'];
  /** category of the nudge config */
  nudgeCategory?: Maybe<Scalars['String']>;
  /** nudge message options */
  nudgeMessages?: Maybe<Array<Maybe<NudgeMessageType>>>;
  pdt: PdtType;
  providerNotificationTemplate: TextTranslationType;
  providerNotificationTemplateTranslations?: Maybe<TextTranslationType>;
  /** nudge reason */
  reason?: Maybe<Scalars['TranslationString']>;
  reasonTranslations?: Maybe<TextTranslationType>;
  total?: Maybe<Scalars['Int']>;
  triggerTimeOffset: Scalars['Float'];
  uid: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type NudgeOpportunityConfigTypeReasonArgs = {
  language?: InputMaybe<Scalars['String']>;
  withFallback?: InputMaybe<Scalars['Boolean']>;
};

export type NudgeOpportunityType = {
  __typename?: 'NudgeOpportunityType';
  /** body text */
  body?: Maybe<Scalars['String']>;
  careTeam?: Maybe<CareTeamType>;
  createdAt: Scalars['DateTime'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  /** header text */
  header?: Maybe<Scalars['String']>;
  nudgeMessage: NudgeMessageType;
  nudgeOpportunityConfig: NudgeOpportunityConfigType;
  /** provider notification text */
  providerNotification?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<UserType>;
  sentAt?: Maybe<Scalars['DateTime']>;
  /** status of the nudge opportunity */
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  triggeredAt: Scalars['DateTime'];
  uid: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum OrganizationOrganizationType {
  /** Non-Healthcare Business or Corporation */
  Bus = 'BUS',
  /** Community Group */
  Cg = 'CG',
  /** Clinical Research Sponsor */
  Crs = 'CRS',
  /** Hospital Department */
  Dept = 'DEPT',
  /** Educational Institute */
  Edu = 'EDU',
  /** Government */
  Govt = 'GOVT',
  /** Insurance Company */
  Ins = 'INS',
  /** Other */
  Other = 'OTHER',
  /** Payer */
  Pay = 'PAY',
  /** Healthcare Provider */
  Prov = 'PROV',
  /** Religious Institution */
  Reli = 'RELI',
  /** Organizational team */
  Team = 'TEAM'
}

/** Organization PDT Config */
export type OrganizationPdtConfigType = {
  __typename?: 'OrganizationPdtConfigType';
  adherenceThresholds: Array<Scalars['Float']>;
  appAccessDurationDays?: Maybe<Scalars['Int']>;
  organization: OrganizationType;
  pdt: PdtType;
  textEncryptionEnabled: Scalars['Boolean'];
  total?: Maybe<Scalars['Int']>;
};

/** Organization */
export type OrganizationType = {
  __typename?: 'OrganizationType';
  email?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  isLimbix: Scalars['Boolean'];
  location?: Maybe<LocationType>;
  name?: Maybe<Scalars['String']>;
  organizationType?: Maybe<OrganizationOrganizationType>;
  parentOrganization?: Maybe<OrganizationType>;
  pdtConfigs?: Maybe<Array<Maybe<OrganizationPdtConfigType>>>;
  phone?: Maybe<Scalars['String']>;
  practitioners?: Maybe<Array<Maybe<PractitionerType>>>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

/** Result of an individual completing an assessment */
export type ParticipantAssessmentResultType = {
  __typename?: 'ParticipantAssessmentResultType';
  answers?: Maybe<Scalars['JSONString']>;
  assessment?: Maybe<AssessmentType>;
  assessmentAnswers?: Maybe<Array<Maybe<Scalars['String']>>>;
  assessmentResultItems?: Maybe<Array<Maybe<AssessmentResultItemType>>>;
  assignment?: Maybe<StudyAssignmentsTableType>;
  createdAt: Scalars['DateTime'];
  lastUserToEdit?: Maybe<UserType>;
  pdt?: Maybe<PdtType>;
  score?: Maybe<Scalars['Float']>;
  scores?: Maybe<Array<Maybe<Scalars['Float']>>>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Patient */
export type PatientType = {
  __typename?: 'PatientType';
  careteams?: Maybe<Array<Maybe<CareTeamType>>>;
  emergencyContactEmail?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactPhone?: Maybe<Scalars['String']>;
  location?: Maybe<LocationType>;
  notes?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
  user: UserType;
};

/** PDT app */
export type PdtType = {
  __typename?: 'PdtType';
  minimumVersionNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

/** Scheduled notification */
export type PeriodicPushNotificationType = {
  __typename?: 'PeriodicPushNotificationType';
  endTime?: Maybe<Scalars['DateTime']>;
  frequency?: Maybe<Scalars['Int']>;
  notificationContent: Array<Maybe<Array<Scalars['String']>>>;
  notificationType: Scalars['String'];
  pdt?: Maybe<PdtType>;
  startTime: Scalars['DateTime'];
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
  user: UserType;
};

/** Access Code */
export type PractitionerAccessCodeType = {
  __typename?: 'PractitionerAccessCodeType';
  code: Scalars['String'];
  expirationTime?: Maybe<Scalars['DateTime']>;
  pdt?: Maybe<PdtType>;
  practitioner?: Maybe<PractitionerType>;
  total?: Maybe<Scalars['Int']>;
};

/** Practitioner Dashboard Table */
export type PractitionerDashboardTableType = {
  __typename?: 'PractitionerDashboardTableType';
  baselinePhqScore?: Maybe<Scalars['String']>;
  baselineQuestionsAnswered?: Maybe<Scalars['Int']>;
  completedPhqCount?: Maybe<Scalars['Int']>;
  patientDateOfBirth?: Maybe<Scalars['DateTime']>;
  patientFirstName?: Maybe<Scalars['String']>;
  patientLastName?: Maybe<Scalars['String']>;
  programStatusDate?: Maybe<Scalars['DateTime']>;
  programStatusText?: Maybe<Scalars['String']>;
  recentPhqScore?: Maybe<Scalars['String']>;
  recentQuestionsAnswered?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

/** An enumeration. */
export enum PractitionerIdentifierState {
  /** Alabama */
  Alabama = 'ALABAMA',
  /** Alaska */
  Alaska = 'ALASKA',
  /** Arizona */
  Arizona = 'ARIZONA',
  /** Arkansas */
  Arkansas = 'ARKANSAS',
  /** - */
  A = 'A_',
  /** California */
  California = 'CALIFORNIA',
  /** Colorado */
  Colorado = 'COLORADO',
  /** Connecticut */
  Connecticut = 'CONNECTICUT',
  /** Delaware */
  Delaware = 'DELAWARE',
  /** Florida */
  Florida = 'FLORIDA',
  /** Georgia */
  Georgia = 'GEORGIA',
  /** Hawaii */
  Hawaii = 'HAWAII',
  /** Idaho */
  Idaho = 'IDAHO',
  /** Illinois */
  Illinois = 'ILLINOIS',
  /** Indiana */
  Indiana = 'INDIANA',
  /** Iowa */
  Iowa = 'IOWA',
  /** Kansas */
  Kansas = 'KANSAS',
  /** Kentucky */
  Kentucky = 'KENTUCKY',
  /** Louisiana */
  Louisiana = 'LOUISIANA',
  /** Maine */
  Maine = 'MAINE',
  /** Maryland */
  Maryland = 'MARYLAND',
  /** Massachusetts */
  Massachusetts = 'MASSACHUSETTS',
  /** Michigan */
  Michigan = 'MICHIGAN',
  /** Minnesota */
  Minnesota = 'MINNESOTA',
  /** Mississippi */
  Mississippi = 'MISSISSIPPI',
  /** Missouri */
  Missouri = 'MISSOURI',
  /** Montana */
  Montana = 'MONTANA',
  /** Nebraska */
  Nebraska = 'NEBRASKA',
  /** Nevada */
  Nevada = 'NEVADA',
  /** New Hampshire */
  NewHampshire = 'NEW_HAMPSHIRE',
  /** New Jersey */
  NewJersey = 'NEW_JERSEY',
  /** New Mexico */
  NewMexico = 'NEW_MEXICO',
  /** New York */
  NewYork = 'NEW_YORK',
  /** North Carolina */
  NorthCarolina = 'NORTH_CAROLINA',
  /** North Dakota */
  NorthDakota = 'NORTH_DAKOTA',
  /** Ohio */
  Ohio = 'OHIO',
  /** Oklahoma */
  Oklahoma = 'OKLAHOMA',
  /** Oregon */
  Oregon = 'OREGON',
  /** Pennsylvania */
  Pennsylvania = 'PENNSYLVANIA',
  /** Rhode Island */
  RhodeIsland = 'RHODE_ISLAND',
  /** South Carolina */
  SouthCarolina = 'SOUTH_CAROLINA',
  /** South Dakota */
  SouthDakota = 'SOUTH_DAKOTA',
  /** Tennessee */
  Tennessee = 'TENNESSEE',
  /** Texas */
  Texas = 'TEXAS',
  /** Utah */
  Utah = 'UTAH',
  /** Vermont */
  Vermont = 'VERMONT',
  /** Virginia */
  Virginia = 'VIRGINIA',
  /** Washington */
  Washington = 'WASHINGTON',
  /** West Virginia */
  WestVirginia = 'WEST_VIRGINIA',
  /** Wisconsin */
  Wisconsin = 'WISCONSIN',
  /** Wyoming */
  Wyoming = 'WYOMING'
}

/** An enumeration. */
export enum PractitionerIdentifierSystemName {
  /** NPI */
  Npi = 'NPI',
  /** State License */
  StateLicense = 'STATE_LICENSE'
}

export type PractitionerIdentifierType = {
  __typename?: 'PractitionerIdentifierType';
  practitioner: PractitionerType;
  state?: Maybe<PractitionerIdentifierState>;
  systemName: PractitionerIdentifierSystemName;
  total?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
};

/** Practitioner Nudges Table */
export type PractitionerNudgesTableType = {
  __typename?: 'PractitionerNudgesTableType';
  careTeam?: Maybe<CareTeamType>;
  category?: Maybe<Scalars['String']>;
  nudgeOpportunityConfig: NudgeOpportunityConfigType;
  patientDateOfBirth?: Maybe<Scalars['DateTime']>;
  patientFirstName?: Maybe<Scalars['String']>;
  patientLastName?: Maybe<Scalars['String']>;
  providerNotification?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  triggeredAt: Scalars['DateTime'];
  uid: Scalars['String'];
};

/** An enumeration. */
export enum PractitionerPractitionerRole {
  /** Administrator */
  Admin = 'ADMIN',
  /** Doctor */
  Doctor = 'DOCTOR',
  /** ICT professional */
  Ict = 'ICT',
  /** Nurse */
  Nurse = 'NURSE',
  /** Researcher */
  Researcher = 'RESEARCHER',
  /** Teacher/educator */
  Teacher = 'TEACHER'
}

/** An enumeration. */
export enum PractitionerPreferredContactMethod {
  /** Email */
  Email = 'EMAIL',
  /** Phone */
  Phone = 'PHONE'
}

/** Practitioner */
export type PractitionerType = {
  __typename?: 'PractitionerType';
  accessCodes?: Maybe<Array<Maybe<PractitionerAccessCodeType>>>;
  careteams?: Maybe<Array<Maybe<CareTeamType>>>;
  identifiers?: Maybe<Array<Maybe<PractitionerIdentifierType>>>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationType>;
  notes?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationType>;
  patients?: Maybe<Array<Maybe<PatientType>>>;
  portalAccessTime?: Maybe<Scalars['DateTime']>;
  position?: Maybe<Scalars['String']>;
  practitionerRole?: Maybe<PractitionerPractitionerRole>;
  preferredContactMethod?: Maybe<PractitionerPreferredContactMethod>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
  user: UserType;
};

export type Query = {
  __typename?: 'Query';
  _debug?: Maybe<DjangoDebug>;
  /** Specific assessment given an assessment UID */
  assessment?: Maybe<AssessmentType>;
  /** Assessment results for the current user given a specific PDT */
  assessmentResults?: Maybe<Array<Maybe<AssessmentResultType>>>;
  /** List of assessments available to a specific PDT */
  assessments?: Maybe<Array<Maybe<AssessmentType>>>;
  /** A specific Assignment's details */
  assignment?: Maybe<AssignmentType>;
  /** List of assignments for the current user */
  assignments?: Maybe<Array<Maybe<AssignmentType>>>;
  /** Specific BA task given the task UID */
  behavioralActivationTask?: Maybe<BehavioralActivationTaskType>;
  /** Behavioral activation tasks for the current user */
  behavioralActivationTasks?: Maybe<Array<Maybe<BehavioralActivationTaskType>>>;
  /** Get nudge history for the given care_team identified by uid */
  careTeamNudgeHistory?: Maybe<Array<Maybe<NudgeOpportunityType>>>;
  /** Get information about a Care Team given a Patients UID and a PDT UID or given the Careteam UID */
  careteam?: Maybe<CareTeamType>;
  /** Get a list of careteams from the PDT UID */
  careteams?: Maybe<Array<Maybe<CareTeamType>>>;
  /** Specific clinical concern log for a participant */
  clinicalConcern?: Maybe<ClinicalConcernType>;
  /** List of deny list items */
  denyList?: Maybe<DenyListType>;
  /** Whether the potential commercial patient is eligible for given pdt */
  eligibilityStatus?: Maybe<EligibilityStatusType>;
  /** Enrollment tracker result for a study participant */
  enrollmentTrackerResult?: Maybe<EnrollmentTrackerResultType>;
  /** Free-form text with the given PDT UID and UID */
  freeFormText?: Maybe<FreeFormTextType>;
  /** Free-form texts associated by PDT UID and User UID. Requires operations permissions. */
  freeFormTexts?: Maybe<Array<Maybe<FreeFormTextType>>>;
  /** list of all library items */
  libraryItems?: Maybe<Array<Maybe<LibraryItemType>>>;
  limbixStudies?: Maybe<Array<Maybe<LimbixStudyType>>>;
  limbixStudy?: Maybe<LimbixStudyType>;
  /** Fetch information about a location */
  location?: Maybe<LocationType>;
  /** Fetch account information for current user */
  me?: Maybe<UserType>;
  /** Specific mood log given the mood log UID */
  moodLog?: Maybe<MoodLogType>;
  /** List of mood logs for the current user */
  moodLogs?: Maybe<Array<Maybe<MoodLogType>>>;
  /** Specific nudge opportunity given a UID */
  nudgeOpportunity?: Maybe<NudgeOpportunityType>;
  /** Get information about a given Organization if you are a member of the organization hierarchy */
  organization?: Maybe<OrganizationType>;
  /** Find all the assignment schedules for a particular organization */
  organizationAssignmentSchedules?: Maybe<Array<Maybe<AssignmentScheduleType>>>;
  /** A List of all organizations, only accessible by Limbix */
  organizations?: Maybe<Array<Maybe<OrganizationType>>>;
  /** Assessment results for the current user given a specific PDT */
  participantAssessmentResults?: Maybe<Array<Maybe<ParticipantAssessmentResultType>>>;
  /** List of assignments for a study participant */
  participantAssignments?: Maybe<Array<Maybe<AssignmentType>>>;
  /** List of Clinical Concerns filtered by Limbix Study and Limbix User */
  participantClinicalConcerns?: Maybe<Array<Maybe<ClinicalConcernType>>>;
  /** List of Free Form Texts for a participant */
  participantFreeFormTexts?: Maybe<Array<Maybe<FreeFormTextType>>>;
  /** get patient information via patients uid */
  patient?: Maybe<PatientType>;
  /** Assessment results for a given Patient and specific PDT */
  patientAssessmentResults?: Maybe<Array<Maybe<ParticipantAssessmentResultType>>>;
  /** List of Assignments for a given patient uid and pdt uid */
  patientAssignments?: Maybe<Array<Maybe<AssignmentType>>>;
  /** Behavioral activation tasks for a Patient */
  patientBehavioralActivationTasks?: Maybe<Array<Maybe<BehavioralActivationTaskType>>>;
  /** List of mood logs for a Patient */
  patientMoodLogs?: Maybe<Array<Maybe<MoodLogType>>>;
  /** Returns list of nudges sent to caller for a given pdt */
  patientNudges?: Maybe<Array<Maybe<NudgeOpportunityType>>>;
  /** Get Patient Session information */
  patientSessions?: Maybe<Array<Maybe<SessionType>>>;
  /** Patient's user data for a given PDT and Patient UID */
  patientUserData?: Maybe<UserPdtDataType>;
  pdts?: Maybe<Array<Maybe<PdtType>>>;
  /** Fetch list of practitioners from given pdt UID */
  periodicPushNotifications?: Maybe<Array<Maybe<PeriodicPushNotificationType>>>;
  /** Fetch information about a practitioner */
  practitioner?: Maybe<PractitionerType>;
  /** Get a list of a practitioner's dashboard table from the PDT UID */
  practitionerDashboardTable?: Maybe<Array<Maybe<PractitionerDashboardTableType>>>;
  /** Get a list of a practitioner's nudges table rows for pdt identified by given uid */
  practitionerNudgesTable?: Maybe<Array<Maybe<PractitionerNudgesTableType>>>;
  /** Fetch list of practitioners from given pdt UID */
  practitioners?: Maybe<Array<Maybe<PractitionerType>>>;
  /** Research assessment results given a specific study */
  researchAssessmentResults?: Maybe<Array<Maybe<ResearchAssessmentResultType>>>;
  /** Current study arm that current user is in */
  studyArm?: Maybe<StudyArmType>;
  /** Find all the assignment schedules for a particular study */
  studyAssignmentSchedules?: Maybe<Array<Maybe<AssignmentScheduleType>>>;
  /** List of participant assignments for a study */
  studyAssignments?: Maybe<Array<Maybe<AssignmentType>>>;
  /** List of assignments for a study */
  studyAssignmentsTable?: Maybe<Array<Maybe<StudyAssignmentsTableType>>>;
  /** List of Clinical Concerns filtered by Limbix Study */
  studyClinicalConcerns?: Maybe<Array<Maybe<ClinicalConcernType>>>;
  /** List of enrollment tracker results filtered by Limbix Study */
  studyEnrollmentTrackerResults?: Maybe<Array<Maybe<EnrollmentTrackerResultType>>>;
  /** List of Free Form Texts for a study */
  studyFreeFormTexts?: Maybe<Array<Maybe<FreeFormTextType>>>;
  studyParticipant?: Maybe<StudyParticipantType>;
  studyParticipants?: Maybe<Array<Maybe<StudyParticipantType>>>;
  /** List of research assignments for a study */
  studyResearchAssignments?: Maybe<Array<Maybe<AssignmentType>>>;
  /** Fetch a task given the associated PDT, module number, and task number */
  taskData?: Maybe<TaskDataType>;
  /** List of tasks for the current user */
  tasksData?: Maybe<Array<Maybe<TaskDataType>>>;
  /** TOTP Authenticator for the current user */
  totpAuthenticator?: Maybe<TotpAuthenticatorType>;
  /** Specific translation given a UID */
  translation?: Maybe<TextTranslationType>;
  /** Specific user activity log given the UID */
  userActivityLog?: Maybe<UserActivityLogType>;
  /** Activities for the current user */
  userActivityLogs?: Maybe<Array<Maybe<UserActivityLogType>>>;
  /** Current user's data for a given PDT */
  userData?: Maybe<UserPdtDataType>;
  /** Specific user log given the log UID */
  userLog?: Maybe<UserLogType>;
  /** List of user logs for the current user */
  userLogs?: Maybe<Array<Maybe<UserLogType>>>;
};


export type QueryAssessmentArgs = {
  assessmentName?: InputMaybe<Scalars['String']>;
  assessmentUid?: InputMaybe<Scalars['String']>;
};


export type QueryAssessmentResultsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryAssessmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid?: InputMaybe<Scalars['String']>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAssignmentArgs = {
  assignmentUid?: InputMaybe<Scalars['String']>;
  studyUid?: InputMaybe<Scalars['String']>;
};


export type QueryAssignmentsArgs = {
  clientType: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  onlyVisible: Scalars['Boolean'];
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid?: InputMaybe<Scalars['String']>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryBehavioralActivationTaskArgs = {
  behavioralActivationTaskUid: Scalars['String'];
  pdtUid: Scalars['String'];
};


export type QueryBehavioralActivationTasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryCareTeamNudgeHistoryArgs = {
  careteamUid: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryCareteamArgs = {
  careteamUid?: InputMaybe<Scalars['String']>;
  patientUid?: InputMaybe<Scalars['String']>;
  pdtUid?: InputMaybe<Scalars['String']>;
};


export type QueryCareteamsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryClinicalConcernArgs = {
  clinicalConcernUid: Scalars['String'];
  studyUid: Scalars['String'];
};


export type QueryDenyListArgs = {
  pdtUid: Scalars['String'];
};


export type QueryEligibilityStatusArgs = {
  dateOfBirth: Scalars['DateTime'];
  employeeId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organizationSlug: Scalars['String'];
  pdtUid: Scalars['String'];
  postalCode: Scalars['String'];
};


export type QueryEnrollmentTrackerResultArgs = {
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type QueryFreeFormTextArgs = {
  freeFormTextUid: Scalars['String'];
  pdtUid: Scalars['String'];
};


export type QueryFreeFormTextsArgs = {
  date: Scalars['DateTime'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
  userUid: Scalars['String'];
};


export type QueryLibraryItemsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  liveItemsOnly?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryLimbixStudiesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryLimbixStudyArgs = {
  studyUid: Scalars['String'];
};


export type QueryLocationArgs = {
  locationUid?: InputMaybe<Scalars['String']>;
};


export type QueryMoodLogArgs = {
  moodLogUid: Scalars['String'];
  pdtUid: Scalars['String'];
};


export type QueryMoodLogsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryNudgeOpportunityArgs = {
  careteamUid: Scalars['String'];
  nudgeOpportunityUid: Scalars['String'];
};


export type QueryOrganizationArgs = {
  organizationUid: Scalars['String'];
};


export type QueryOrganizationAssignmentSchedulesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  organizationUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryOrganizationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryParticipantAssessmentResultsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryParticipantAssignmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
  studyUid?: InputMaybe<Scalars['String']>;
  userUid?: InputMaybe<Scalars['String']>;
};


export type QueryParticipantClinicalConcernsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type QueryParticipantFreeFormTextsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
  studyUid: Scalars['String'];
  userUid?: InputMaybe<Scalars['String']>;
};


export type QueryPatientArgs = {
  patientUid: Scalars['String'];
};


export type QueryPatientAssessmentResultsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  patientUid: Scalars['String'];
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryPatientAssignmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  patientUid: Scalars['String'];
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryPatientBehavioralActivationTasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  patientUid: Scalars['String'];
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryPatientMoodLogsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  patientUid: Scalars['String'];
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryPatientNudgesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryPatientSessionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  patientUid: Scalars['String'];
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryPatientUserDataArgs = {
  patientUid: Scalars['String'];
  pdtUid: Scalars['String'];
};


export type QueryPdtsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryPeriodicPushNotificationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  notificationType?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
  showExpired?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPractitionerArgs = {
  practitionerUid?: InputMaybe<Scalars['String']>;
};


export type QueryPractitionerDashboardTableArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryPractitionerNudgesTableArgs = {
  careteamUid?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryPractitionersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryResearchAssessmentResultsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
  studyUid: Scalars['String'];
};


export type QueryStudyArmArgs = {
  pdtUid: Scalars['String'];
};


export type QueryStudyAssignmentSchedulesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
  studyUid: Scalars['String'];
};


export type QueryStudyAssignmentsArgs = {
  accountType?: InputMaybe<Scalars['String']>;
  assessmentUids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overdue?: InputMaybe<Scalars['Boolean']>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
  status?: InputMaybe<Scalars['String']>;
  studyUid: Scalars['String'];
  userUid?: InputMaybe<Scalars['String']>;
};


export type QueryStudyAssignmentsTableArgs = {
  accountType?: InputMaybe<Scalars['String']>;
  assessmentUids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  overdue?: InputMaybe<Scalars['Boolean']>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
  status?: InputMaybe<Scalars['String']>;
  studyUid: Scalars['String'];
  userUid?: InputMaybe<Scalars['String']>;
};


export type QueryStudyClinicalConcernsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
  studyUid: Scalars['String'];
};


export type QueryStudyEnrollmentTrackerResultsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
  studyUid: Scalars['String'];
};


export type QueryStudyFreeFormTextsArgs = {
  date?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
  studyUid: Scalars['String'];
};


export type QueryStudyParticipantArgs = {
  studyUid: Scalars['String'];
  userUid: Scalars['String'];
};


export type QueryStudyParticipantsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchFilters?: InputMaybe<Scalars['JSONString']>;
  studyUid: Scalars['String'];
};


export type QueryStudyResearchAssignmentsArgs = {
  aboutUserUid: Scalars['String'];
  studyUid: Scalars['String'];
  userUid?: InputMaybe<Scalars['String']>;
};


export type QueryTaskDataArgs = {
  moduleNumber: Scalars['Int'];
  pdtUid: Scalars['String'];
  taskNumber: Scalars['Int'];
};


export type QueryTasksDataArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryTranslationArgs = {
  uid: Scalars['String'];
};


export type QueryUserActivityLogArgs = {
  activityLogUid: Scalars['String'];
  pdtUid: Scalars['String'];
};


export type QueryUserActivityLogsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};


export type QueryUserDataArgs = {
  pdtUid: Scalars['String'];
};


export type QueryUserLogArgs = {
  logUid: Scalars['String'];
  pdtUid: Scalars['String'];
};


export type QueryUserLogsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pdtUid: Scalars['String'];
  searchFilters?: InputMaybe<Scalars['JSONString']>;
};

export type QuestionConditionInputType = {
  answer?: InputMaybe<Scalars['String']>;
  isNegated?: InputMaybe<Scalars['Boolean']>;
  question?: InputMaybe<Scalars['Int']>;
  uid?: InputMaybe<Scalars['String']>;
};

/** Condition for rendering a question */
export type QuestionConditionType = {
  __typename?: 'QuestionConditionType';
  dependsOnAnswer: AnswerType;
  dependsOnQuestion: QuestionType;
  isNegated: Scalars['Boolean'];
  ordinal: Scalars['Int'];
  question: QuestionType;
  total?: Maybe<Scalars['Int']>;
};

export type QuestionInputType = {
  answers?: InputMaybe<Array<InputMaybe<AnswerInputType>>>;
  conditions?: InputMaybe<Array<InputMaybe<QuestionConditionInputType>>>;
  isOptional?: InputMaybe<Scalars['Boolean']>;
  isSensitive?: InputMaybe<Scalars['Boolean']>;
  keyString?: InputMaybe<Scalars['String']>;
  questionType?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<TextTranslationInputType>;
  uid?: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum QuestionQuestionType {
  /** Date Field */
  DateField = 'DATE_FIELD',
  /** Free-form Text */
  FreeFormText = 'FREE_FORM_TEXT',
  /** Multiple Choice */
  MultipleChoice = 'MULTIPLE_CHOICE',
  /** Multi Select */
  MultiSelect = 'MULTI_SELECT'
}

/** Question on an assessment */
export type QuestionType = {
  __typename?: 'QuestionType';
  /** Answers that are linked with a question */
  answers?: Maybe<Array<Maybe<AnswerType>>>;
  assessment: AssessmentType;
  /** Condition for rendering a question */
  conditions?: Maybe<Array<Maybe<QuestionConditionType>>>;
  isOptional: Scalars['Boolean'];
  isSensitive: Scalars['Boolean'];
  keyString?: Maybe<Scalars['String']>;
  ordinal: Scalars['Int'];
  questionType: QuestionQuestionType;
  /** Question text */
  text?: Maybe<Scalars['TranslationString']>;
  /** Question text translations */
  textTranslations?: Maybe<TextTranslationType>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};


/** Question on an assessment */
export type QuestionTypeTextArgs = {
  language?: InputMaybe<Scalars['String']>;
  withFallback?: InputMaybe<Scalars['Boolean']>;
};

/** Refresh an auth token given a refresh token */
export type RefreshTokenMobile = {
  __typename?: 'RefreshTokenMobile';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

/** Register an existing user */
export type RegisterExistingUser = {
  __typename?: 'RegisterExistingUser';
  /** Whether the study participant was registered successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Create a new account and register that user */
export type RegisterNewUser = {
  __typename?: 'RegisterNewUser';
  /** Whether the user was created and registered successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Remove a CoInvestigator */
export type RemoveStudyCoInvestigator = {
  __typename?: 'RemoveStudyCoInvestigator';
  /** Whether the Study CoInvestigator was removed successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Remove a Study Coordinator */
export type RemoveStudyCoordinator = {
  __typename?: 'RemoveStudyCoordinator';
  /** Whether the study coordinator was removed successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Result of reporting an adverse event */
export type ReportAdverseEvent = {
  __typename?: 'ReportAdverseEvent';
  /** Adverse event reported during use of a PDT */
  adverseEvent?: Maybe<AdverseEventType>;
};

/** Result of reporting a assessment result */
export type ReportAssessmentResult = {
  __typename?: 'ReportAssessmentResult';
  /** Reported assessment result */
  assessmentResult?: Maybe<AssessmentResultType>;
};

/** Report assessment answers for a Clinical Concern */
export type ReportClinicalConcern = {
  __typename?: 'ReportClinicalConcern';
  clinicalConcern?: Maybe<ClinicalConcernType>;
};

/** Result of reporting a assessment result */
export type ReportParticipantAssessmentResult = {
  __typename?: 'ReportParticipantAssessmentResult';
  /** Reported assessment result */
  participantAssessmentResult?: Maybe<ParticipantAssessmentResultType>;
};

/** Result of reporting a research assessment result */
export type ReportResearchAssessmentResult = {
  __typename?: 'ReportResearchAssessmentResult';
  /** Reported research assessment result */
  researchAssessmentResult?: Maybe<ResearchAssessmentResultType>;
};

/** Report when a user completes a task */
export type ReportTaskComplete = {
  __typename?: 'ReportTaskComplete';
  /** Task that was completed */
  taskData?: Maybe<TaskDataType>;
};

/** Report when a user starts a task */
export type ReportTaskStart = {
  __typename?: 'ReportTaskStart';
  /** Task that was started */
  taskData?: Maybe<TaskDataType>;
};

/** Result of an Researcher completing an assessment */
export type ResearchAssessmentResultType = {
  __typename?: 'ResearchAssessmentResultType';
  answers?: Maybe<Scalars['JSONString']>;
  assessment?: Maybe<AssessmentType>;
  assessmentAnswers?: Maybe<Array<Maybe<Scalars['String']>>>;
  assessmentResultItems?: Maybe<Array<Maybe<AssessmentResultItemType>>>;
  assignment?: Maybe<StudyAssignmentsTableType>;
  createdAt: Scalars['DateTime'];
  lastUserToEdit?: Maybe<UserType>;
  /** Score of the assessment result */
  score?: Maybe<Scalars['Float']>;
  scores?: Maybe<Array<Maybe<Scalars['Float']>>>;
  study: LimbixStudyType;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Resend a practitioner's access code for a pdt */
export type ResendAccessCode = {
  __typename?: 'ResendAccessCode';
  /** Whether the practitioner's access code was sent to them successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Resend verification email */
export type ResendEmailVerification = {
  __typename?: 'ResendEmailVerification';
  /** Whether the verification email successfully sent */
  success?: Maybe<Scalars['Boolean']>;
};

/** Reset users access attempts */
export type ResetAccessAttempts = {
  __typename?: 'ResetAccessAttempts';
  /** Whether the access attempts were reset successfully sent */
  success?: Maybe<Scalars['Boolean']>;
};

/** Send password reset email for user account */
export type ResetPassword = {
  __typename?: 'ResetPassword';
  /** Did the reset password email successfully send */
  success?: Maybe<Scalars['Boolean']>;
};

/** Reset a patient's access attempts */
export type ResetPatientAccessAttempts = {
  __typename?: 'ResetPatientAccessAttempts';
  /** Whether the access attempts were reset successfully sent */
  success?: Maybe<Scalars['Boolean']>;
};

export type Revoke = {
  __typename?: 'Revoke';
  revoked: Scalars['Int'];
};

/** Revoke a practitioner's access code for a pdt */
export type RevokeAccessCode = {
  __typename?: 'RevokeAccessCode';
  /** Whether the practitioner's access code was revoked successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Revoke a participant's mobile access and mobile refresh tokens */
export type RevokeMobileAccess = {
  __typename?: 'RevokeMobileAccess';
  /** Whether the all mobile access has been revoked successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Revoke a patient's mobile access and mobile refresh tokens */
export type RevokePatientMobileAccess = {
  __typename?: 'RevokePatientMobileAccess';
  /** Whether the all mobile access has been revoked successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Revoke a practitioner's Provider Portal access */
export type RevokeProviderPortalAccess = {
  __typename?: 'RevokeProviderPortalAccess';
  /** Whether the practitioner's Provider Portal access was revoked successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Schedule a periodic push notification */
export type SchedulePeriodicPushNotification = {
  __typename?: 'SchedulePeriodicPushNotification';
  /** Scheduled periodic notification */
  periodicNotification?: Maybe<PeriodicPushNotificationType>;
};

/** Schedule a push notification */
export type SchedulePushNotification = {
  __typename?: 'SchedulePushNotification';
  /** Scheduled notification task */
  notificationTask?: Maybe<NotificationTaskType>;
};

/** Allows study participants to create their own accounts in app with an access code */
export type SelfServeCreateParticipant = {
  __typename?: 'SelfServeCreateParticipant';
  /** The study participant that was created */
  studyParticipant?: Maybe<StudyParticipantType>;
};

/** Allows patients to create their own accounts in app */
export type SelfServeCreatePatient = {
  __typename?: 'SelfServeCreatePatient';
  /** The Patient that was created */
  patient?: Maybe<PatientType>;
  /** Whether patient was created */
  success?: Maybe<Scalars['Boolean']>;
};

/** Allows practitioners to create their own accounts */
export type SelfServeCreatePractitioner = {
  __typename?: 'SelfServeCreatePractitioner';
  /** The outcome of creating a Practitioner */
  success?: Maybe<Scalars['Boolean']>;
};

/** SendNudge marks who sent a nudge and when it was sent */
export type SendNudge = {
  __typename?: 'SendNudge';
  /** Nudge sent */
  nudge?: Maybe<NudgeOpportunityType>;
  /** Whether the nudge was sent */
  success?: Maybe<Scalars['Boolean']>;
};

/** Sessions */
export type SessionType = {
  __typename?: 'SessionType';
  deviceBrandName: Scalars['String'];
  deviceModelName: Scalars['String'];
  deviceOperatingSystem: Scalars['String'];
  deviceOperatingSystemVersion: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
  pdt: PdtType;
  startTime: Scalars['DateTime'];
  total?: Maybe<Scalars['Int']>;
  user: UserType;
};

/** Blinds or unblinds a user for a particular study */
export type SetUserBlinded = {
  __typename?: 'SetUserBlinded';
  /** Whether the user was blinded/unblinded successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Result of starting an assignment */
export type StartAssignment = {
  __typename?: 'StartAssignment';
  /** Assignment that was started */
  assignment?: Maybe<AssignmentType>;
};

/** Result of starting an assignment */
export type StartResearchAssignment = {
  __typename?: 'StartResearchAssignment';
  /** Assignment that was started */
  assignment?: Maybe<AssignmentType>;
};

/** Arm of a Limbix study */
export type StudyArmType = {
  __typename?: 'StudyArmType';
  isControl: Scalars['Boolean'];
  isInitialArm: Scalars['Boolean'];
  name: Scalars['String'];
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

/** Study Assignments Table */
export type StudyAssignmentsTableType = {
  __typename?: 'StudyAssignmentsTableType';
  aboutParticipantId?: Maybe<Scalars['String']>;
  aboutUser?: Maybe<UserType>;
  accountType: AssignmentAccountType;
  assessment?: Maybe<AssessmentType>;
  canEditAfterCompletion: Scalars['Boolean'];
  canSkipQuestions: Scalars['Boolean'];
  completeTime?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  ordinal: Scalars['Int'];
  organization?: Maybe<OrganizationType>;
  participantId?: Maybe<Scalars['String']>;
  participantStatus?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  study?: Maybe<LimbixStudyType>;
  total?: Maybe<Scalars['Int']>;
  typeOfAssignment: AssignmentTypeOfAssignment;
  typeOfClient: AssignmentTypeOfClient;
  uid: Scalars['String'];
  user?: Maybe<UserType>;
  visibleDate: Scalars['DateTime'];
  weekInProgram?: Maybe<Scalars['String']>;
};

/** Study Participant */
export type StudyParticipantType = {
  __typename?: 'StudyParticipantType';
  activationTime?: Maybe<Scalars['DateTime']>;
  behavioralActivations?: Maybe<Array<Maybe<BehavioralActivationTaskType>>>;
  canAccessPdt?: Maybe<Scalars['Boolean']>;
  canActivate?: Maybe<Scalars['Boolean']>;
  canBeDropped?: Maybe<Scalars['Boolean']>;
  canBeLostToFollowup?: Maybe<Scalars['Boolean']>;
  canComplete?: Maybe<Scalars['Boolean']>;
  canScreenFail?: Maybe<Scalars['Boolean']>;
  canWithdraw?: Maybe<Scalars['Boolean']>;
  caseReportFormData?: Maybe<Scalars['String']>;
  completeTime?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  currentArmStartTime?: Maybe<Scalars['DateTime']>;
  deteriorationAlerts?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  droppedTime?: Maybe<Scalars['DateTime']>;
  enrollmentTrackerResult?: Maybe<EnrollmentTrackerResultType>;
  exitReason?: Maybe<Scalars['String']>;
  hasMobileTokens?: Maybe<Scalars['Boolean']>;
  lostToFollowupTime?: Maybe<Scalars['DateTime']>;
  moodLogs?: Maybe<Array<Maybe<MoodLogType>>>;
  notes?: Maybe<Scalars['String']>;
  participantId?: Maybe<Scalars['String']>;
  referralSource?: Maybe<Scalars['String']>;
  screenFailTime?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  study: LimbixStudyType;
  studyArm?: Maybe<StudyArmType>;
  tasks?: Maybe<Array<Maybe<TaskDataType>>>;
  total?: Maybe<Scalars['Int']>;
  turnsEighteenWhileEnrolled?: Maybe<Scalars['Boolean']>;
  user: UserType;
  userLogs?: Maybe<Array<Maybe<UserLogType>>>;
  userPdtData?: Maybe<UserPdtDataType>;
  withdrawTime?: Maybe<Scalars['DateTime']>;
};

/** Task that a user engages in during use of a PDT */
export type TaskDataType = {
  __typename?: 'TaskDataType';
  appData?: Maybe<Scalars['JSONString']>;
  completeTime?: Maybe<Scalars['DateTime']>;
  moduleNumber: Scalars['Int'];
  startTime?: Maybe<Scalars['DateTime']>;
  taskNumber: Scalars['Int'];
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

export type TextTranslationInputType = {
  en?: InputMaybe<Scalars['String']>;
  es?: InputMaybe<Scalars['String']>;
  ko?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

/** A translation object */
export type TextTranslationType = {
  __typename?: 'TextTranslationType';
  en: Scalars['String'];
  es?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

export type TokenAuth = {
  __typename?: 'TokenAuth';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
  /** User account associated with credentials */
  user?: Maybe<UserType>;
};

export type TokenAuthMfa = {
  __typename?: 'TokenAuthMFA';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
  /** User account associated with credentials */
  user?: Maybe<UserType>;
};

/** Get mobile auth token for an account given valid credentials */
export type TokenAuthMobile = {
  __typename?: 'TokenAuthMobile';
  /** Whether the current user can access the given PDT */
  canAccessPdt?: Maybe<Scalars['Boolean']>;
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
  /** User account associated with credentials */
  user?: Maybe<UserType>;
};

/** TOTP Authenticator */
export type TotpAuthenticatorType = {
  __typename?: 'TotpAuthenticatorType';
  qrcodeSvg?: Maybe<Scalars['String']>;
};

/** Transition User with Account Type 'Child' To 'Adult' */
export type TransitionToAdult = {
  __typename?: 'TransitionToAdult';
  /** Whether the user was transitioned to adult successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Update when app usage started */
export type UpdateAppStartDate = {
  __typename?: 'UpdateAppStartDate';
  /** User data with updated app start date */
  userData?: Maybe<UserPdtDataType>;
};

/** Legacy update a behavioral activation task */
export type UpdateBehavioralActivationTask = {
  __typename?: 'UpdateBehavioralActivationTask';
  /** Behavioral activation user engages in during use of a PDT */
  behavioralActivationTask?: Maybe<BehavioralActivationTaskType>;
};

/** Update user's current module and task number */
export type UpdateCurrentModuleAndTask = {
  __typename?: 'UpdateCurrentModuleAndTask';
  /** User data with updated current module and task number */
  userData?: Maybe<UserPdtDataType>;
};

/** Report assessment answers for an Enrollment Tracker assessment */
export type UpdateEnrollmentTracker = {
  __typename?: 'UpdateEnrollmentTracker';
  enrollmentTrackerResult?: Maybe<EnrollmentTrackerResultType>;
};

/** Update the last date/time user engaged with the application */
export type UpdateLastEngagementTime = {
  __typename?: 'UpdateLastEngagementTime';
  /** User data with updated last engagement date/time */
  userData?: Maybe<UserPdtDataType>;
};

/** Update local timezone associated with user account */
export type UpdateLocalTimezone = {
  __typename?: 'UpdateLocalTimezone';
  /** User account that was updated */
  user?: Maybe<UserType>;
};

/** Legacy method for updating a mood log */
export type UpdateMoodLog = {
  __typename?: 'UpdateMoodLog';
  /** Mood log that was updated */
  moodLog?: Maybe<MoodLogType>;
};

/** Create/Edit an FCM device */
export type UpdateOrCreateFcmDevice = {
  __typename?: 'UpdateOrCreateFCMDevice';
  /** Device that was created or edited */
  fcmDevice?: Maybe<FcmDeviceType>;
};

/** Reset the password for user account */
export type UpdatePassword = {
  __typename?: 'UpdatePassword';
  /** URL to redirect to on success */
  redirectUrl?: Maybe<Scalars['String']>;
};

/** Update or Create Assignment Schedules for a given Study UID */
export type UpdateStudyAssignmentSchedule = {
  __typename?: 'UpdateStudyAssignmentSchedule';
  assignmentSchedule?: Maybe<AssignmentScheduleType>;
};

/** Update the Name of a Study */
export type UpdateStudyName = {
  __typename?: 'UpdateStudyName';
  /** Whether the study's name was updated successfully */
  success?: Maybe<Scalars['Boolean']>;
};

/** Update additional data associated with a task */
export type UpdateTaskAppData = {
  __typename?: 'UpdateTaskAppData';
  taskData?: Maybe<TaskDataType>;
};

/** Update a user activity log */
export type UpdateUserActivityLog = {
  __typename?: 'UpdateUserActivityLog';
  /** User activity log user engages in during use of a PDT */
  userActivityLog?: Maybe<UserActivityLogType>;
};

/** Update language associated with user account */
export type UpdateUserLanguage = {
  __typename?: 'UpdateUserLanguage';
  /** User whose language was updated */
  user?: Maybe<UserType>;
};

/** Update a log */
export type UpdateUserLog = {
  __typename?: 'UpdateUserLog';
  /** UserLog that was updated */
  userLog?: Maybe<UserLogType>;
};

/** Update user's settings for a given PDT */
export type UpdateUserSettings = {
  __typename?: 'UpdateUserSettings';
  /** User PDT specific data that was updated */
  userData?: Maybe<UserPdtDataType>;
};

/** An enumeration. */
export enum UserActivityLogActivityType {
  /** Behavioral Activation */
  Ba = 'BA',
  /** Exposure */
  Exposure = 'EXPOSURE'
}

/** Activity that a user engages in during use of a PDT */
export type UserActivityLogType = {
  __typename?: 'UserActivityLogType';
  activity?: Maybe<Scalars['String']>;
  activityType: UserActivityLogActivityType;
  completeTime?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  didCompleteScheduledActivation: Scalars['Boolean'];
  extraData?: Maybe<Scalars['JSONString']>;
  scheduledDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['DateTime']>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

/** An enumeration. */
export enum UserLogCategory {
  /** Mood Log */
  MoodLog = 'MOOD_LOG',
  /** Sleep Diary */
  SleepDiary = 'SLEEP_DIARY',
  /** Thought Checker */
  ThoughtChecker = 'THOUGHT_CHECKER'
}

/** UserLog that a user creates during use of a PDT */
export type UserLogType = {
  __typename?: 'UserLogType';
  category: UserLogCategory;
  createdAt: Scalars['DateTime'];
  date?: Maybe<Scalars['DateTime']>;
  extraData?: Maybe<Scalars['JSONString']>;
  mood?: Maybe<Scalars['String']>;
  sudsRating?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

/** PDT specific user data */
export type UserPdtDataType = {
  __typename?: 'UserPdtDataType';
  appAccessDurationDays?: Maybe<Scalars['Int']>;
  appAccessExpirationDate?: Maybe<Scalars['DateTime']>;
  appSettings?: Maybe<Scalars['JSONString']>;
  appStartDate?: Maybe<Scalars['DateTime']>;
  appVersion?: Maybe<Scalars['String']>;
  behavioralActivationTasks?: Maybe<Array<Maybe<BehavioralActivationTaskType>>>;
  currentModuleNumber: Scalars['Int'];
  currentTaskNumber: Scalars['Int'];
  devModeAllowed: Scalars['Boolean'];
  historicalAdherenceScore?: Maybe<Array<Maybe<Scalars['Int']>>>;
  initialAppVersion?: Maybe<Scalars['String']>;
  lastEngagementTime?: Maybe<Scalars['DateTime']>;
  latestAdherenceScore?: Maybe<Scalars['Int']>;
  minimumVersionNumber?: Maybe<Scalars['String']>;
  minutesSpentInApp?: Maybe<Scalars['Int']>;
  moodLogs?: Maybe<Array<Maybe<MoodLogType>>>;
  moodTrackingTimerStart?: Maybe<Scalars['DateTime']>;
  pdt: PdtType;
  textEncryptionEnabled: Scalars['Boolean'];
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
  user: UserType;
  userActivityLogs?: Maybe<Array<Maybe<UserActivityLogType>>>;
  userLogs?: Maybe<Array<Maybe<UserLogType>>>;
};

/** User account information */
export type UserType = {
  __typename?: 'UserType';
  accessAttempts?: Maybe<Scalars['Int']>;
  accountType: LimbixUserAccountType;
  ageAtEnrollment?: Maybe<Scalars['Int']>;
  baselinePhqScore?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  currentAge?: Maybe<Scalars['Int']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<LimbixUserGender>;
  isActive: Scalars['Boolean'];
  isBlindedForStudy?: Maybe<Scalars['Boolean']>;
  isOperations: Scalars['Boolean'];
  isResearch: Scalars['Boolean'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  language: LimbixUserLanguage;
  lastName?: Maybe<Scalars['String']>;
  localTimezone: LimbixUserLocalTimezone;
  parents: Array<UserType>;
  phone?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};


/** User account information */
export type UserTypeIsBlindedForStudyArgs = {
  studyUid?: InputMaybe<Scalars['String']>;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

/** Verify if an access code is valid for a given PDT UID */
export type VerifyAccessCode = {
  __typename?: 'VerifyAccessCode';
  /** Whether the access code is valid */
  valid?: Maybe<Scalars['Boolean']>;
};

/** Withdraw a study participant */
export type WithdrawParticipant = {
  __typename?: 'WithdrawParticipant';
  /** Whether the study participant was withdrawn successfully */
  success?: Maybe<Scalars['Boolean']>;
};
