import { useEffect } from 'react';

import { initializeHeap } from '../utils/heap';

declare global {
  interface Window {
    OneTrust: {
      OnConsentChanged?: (callback: (data: { detail: string[] }) => void) => void;
    };
  }
}

const ANALYTICS_CONSENT = 'C0001';

const getConsentGroups = () => {
  const OTCookieName = 'OptanonConsent';

  const OTCookieMatch = document.cookie.match(new RegExp(`(^| )${OTCookieName}=([^;]+)`));
  if (OTCookieMatch) {
    const OTCookieValue = OTCookieMatch[2];
    return OTCookieValue;
  }
  return '';
};

const initializeConsentChanged = () => {
  window.OneTrust.OnConsentChanged((data: { detail: string[] }) => {
    if (data.detail.includes(ANALYTICS_CONSENT) && !window.heap.loaded) {
      initializeHeap();
    } else if (!data.detail.includes(ANALYTICS_CONSENT) && window.heap.loaded) {
      window.location.reload();
    }
  });
  if (getConsentGroups().includes(ANALYTICS_CONSENT)) {
    initializeHeap();
  }
};

export const useCookieConsent = () => {
  useEffect(() => {
    if (window.OneTrust) {
      initializeConsentChanged();
    } else {
      document.addEventListener('onetrustloaded', () => {
        initializeConsentChanged();
      });
    }
  }, []);
};
