import React, { useContext } from 'react';
import { Box } from '@bighealth/react-limbix-ui';

import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Titles } from '../../components/Titles/Titles';
import { OnboardingFlowContext } from '../../OnboardingFlowContext';

import { OnboardingParams } from '../../types';

import LimbotHappy from '@/assets/LimbotHappy.svg';
import SleepioThumbsUp from '@/assets/SleepioThumbsUp.svg';

export const TextMessageSent: React.FC = () => {
  const { onboardingFormData } = useContext(OnboardingFlowContext);
  const { product } = useParams<OnboardingParams>();
  const { t } = useTranslation();

  let image = (
    <img
      alt="Limbot being happy"
      style={{ width: '206px' }}
      src={LimbotHappy}
    />
  );
  if (product === 'sleepio') {
    image = (
      <img
        alt="Person with thumbs up"
        style={{ width: '206px' }}
        src={SleepioThumbsUp}
      />
    );
  }
  return (
    <>
      <Titles
        title={t(`coverage.${product}.${onboardingFormData.personEnrolling}.download_app_title`)}
        subtitle={t(`coverage.${product}.${onboardingFormData.personEnrolling}.download_app_subtitle`)}
      />
      <Box
        width="100%"
        textAlign="center"
      >
        {image}
      </Box>
    </>
  );
};
