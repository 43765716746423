export const GENERIC_ERROR_STRING_KEY = 'errors.generic_error';

export const TerminatingErrors = {
  INVALID_TOKEN: 'Invalid token',
  INVALID_REFRESH_TOKEN: 'Invalid refresh token',
  EXPIRED_REFRESH_TOKEN: 'Refresh token is expired',
  INVALID_CREDENTIALS: 'Please, enter valid credentials',
  IMPROPER_PERMISSIONS: 'You do not have permission to perform this action',
  ERROR_DECODING_TOKEN: 'Error decoding signature',
  LOCKED_ACCOUNT: 'Locked out of account',
};

export const RetryErrors = {
  EXPIRED_TOKEN: 'Signature has expired',
};

export const MiscErrors = {
  PHONE_INVALID: 'Phone number is invalid',
  TOO_YOUNG: 'Spark is not intended for users under the age of 13',
};

// Denotes errors that have corresponding user-facing error strings
const userFacingErrorStringKeys = {
  [TerminatingErrors.INVALID_CREDENTIALS]: 'errors.invalid_credentials',
  [TerminatingErrors.LOCKED_ACCOUNT]: 'errors.account_locked',
  [MiscErrors.PHONE_INVALID]: 'errors.enter_valid_phone',
  [MiscErrors.TOO_YOUNG]: 'SparkRx is not intended for users under the age of 13',
  password: 'errors.invalid_password',
};

// Given an array of Error objects, gets the user-facing error string key to display
// (defaults to generic error message)
export const getErrorStringKey = (errors: readonly Error[]) => {
  let errorStringKey = GENERIC_ERROR_STRING_KEY;
  errors.forEach((error) => {
    const matchedErrorString = Object.entries(userFacingErrorStringKeys).find((errorType) => (
      error?.message?.toLowerCase().includes(errorType[0].toLowerCase())
    ));
    if (matchedErrorString) {
      [, errorStringKey] = matchedErrorString;
    }
  });
  return errorStringKey;
};
