import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputAdornment, IconButton, FormControlLabel, Checkbox,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Box, Link, Text } from '@bighealth/react-limbix-ui';
import {
  useForm, FormProvider, Controller, Control,
} from 'react-hook-form';

import Styled from './KaiserSignupForm.styles';

import { EMAIL_VALIDATION_REGEX, PHONE_VALIDATION_REGEX } from '@/utils/constants';
import { MutationResultType } from '@/types';
import { getErrorStringKey } from '@/utils/errorUtils';

export const enum KaiserSignupFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PASSWORD = 'password',
  PHONE = 'phone',
  TERMS_OF_USE = 'termsOfUse',
  PROVIDER_ACK = 'providerAck',
}
export type KaiserSignupFormType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  termsOfUse: boolean;
  providerAck: boolean;
}

type ControlledCheckboxProps = {
  control: Control<KaiserSignupFormType>;
  required?: boolean;
  name: KaiserSignupFields.TERMS_OF_USE | KaiserSignupFields.PROVIDER_ACK;
  label: React.ReactNode;
};

const ControlledCheckbox: React.FC<ControlledCheckboxProps> = (props: ControlledCheckboxProps) => (
  <Controller
    name={props.name}
    rules={{ required: props.required }}
    control={props.control}
    render={({ field: { onChange, value } }) => (
      <FormControlLabel
        style={{ marginBottom: 21 }}
        id={props.name}
        label={props.label}
        checked={value || false}
        onChange={onChange}
        control={<Checkbox style={{ paddingRight: 12 }} />}
      />
    )}
  />
);

type KaiserSignupFormProps = {
  defaultEmail: string;
  defaultFirstName: string;
  defaultLastName: string;
  onSubmit: (formData: KaiserSignupFormType) => Promise<MutationResultType>;
  loading?: boolean;
  className?: string;
};

const KaiserSignupForm : React.FC<KaiserSignupFormProps> = (props: KaiserSignupFormProps) => {
  const {
    defaultEmail,
    defaultFirstName,
    defaultLastName,
    onSubmit,
    loading,
    className,
  } = props;

  const [generalError, setGeneralError] = useState<string>(null);

  const formMethods = useForm<KaiserSignupFormType>({
    mode: 'onSubmit',
    defaultValues: {
      email: defaultEmail || '',
      password: '',
      phone: '',
      firstName: defaultFirstName || '',
      lastName: defaultLastName || '',
    },
  });

  const {
    setError,
    handleSubmit,
    control,
    register,
    formState: { errors, isValid },
  } = formMethods;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { t } = useTranslation();

  const validateForm = useCallback((formData: KaiserSignupFormType) => {
    const { email, password, phone } = formData;
    const emailValid = !!EMAIL_VALIDATION_REGEX.exec(email);
    const phoneValid = !!PHONE_VALIDATION_REGEX.exec(phone);
    const passwordValid = password.length >= 8 && password.length <= 128;
    if (!emailValid) {
      setError('email', { message: t('kaiser_signup.enter_valid_email') });
      return false;
    }
    if (!phoneValid) {
      setError('phone', { message: t('errors.enter_valid_phone') });
      return false;
    }
    if (!passwordValid) {
      setError('password', { message: t('kaiser_signup.enter_valid_password') });
      return false;
    }

    return true;
  }, []);

  const handleFormSubmit = useCallback(handleSubmit(async (formData: KaiserSignupFormType) => {
    const formIsValid = validateForm(formData);
    if (!formIsValid) {
      return;
    }
    const result = await onSubmit(formData);
    if (result?.errors) {
      const errorStringKey = getErrorStringKey(result.errors);
      const errorString = t(errorStringKey);
      if (errorStringKey.includes('email')) {
        setError(KaiserSignupFields.EMAIL, { message: errorString });
      } else if (errorStringKey.includes('phone')) {
        setError(KaiserSignupFields.PHONE, { message: errorString });
      } else {
        setGeneralError(t('kaiser_signup.general_error'));
      }
    }
  }), []);

  const submissionDisabled = loading || !isValid;

  // Need to make these controlled so that they can be autofilled after the token is decoded
  const firstNameField = (
    <Styled.FormTextField
      variant="outlined"
      margin="none"
      color="secondary"
      type="text"
      required
      fullWidth
      autoCapitalize="on"
      label={t('kaiser_signup.first_name')}
      id={KaiserSignupFields.FIRST_NAME}
      helperText={errors[KaiserSignupFields.FIRST_NAME]?.message}
      error={!!errors[KaiserSignupFields.FIRST_NAME]}
      {...register(KaiserSignupFields.FIRST_NAME, { required: true })}
    />
  );

  const lastNameField = (
    <Styled.FormTextField
      variant="outlined"
      margin="none"
      type="text"
      color="secondary"
      required
      fullWidth
      autoCapitalize="on"
      label={t('kaiser_signup.last_name')}
      id={KaiserSignupFields.LAST_NAME}
      helperText={errors[KaiserSignupFields.LAST_NAME]?.message}
      error={!!errors[KaiserSignupFields.LAST_NAME]}
      {...register(KaiserSignupFields.LAST_NAME, { required: true })}
    />
  );

  const emailHelperText = t('kaiser_signup.email_helper_text');
  const emailField = (
    <Styled.FormTextField
      variant="outlined"
      margin="none"
      type="email"
      color="secondary"
      required
      fullWidth
      autoCapitalize="none"
      label={t('kaiser_signup.login_email')}
      id={KaiserSignupFields.EMAIL}
      helperText={errors[KaiserSignupFields.EMAIL] ? errors[KaiserSignupFields.EMAIL]?.message : emailHelperText}
      error={!!errors[KaiserSignupFields.EMAIL]}
      {...register(KaiserSignupFields.EMAIL, { required: true })}
    />
  );

  const passwordHelperText = t('kaiser_signup.password_helper_text');
  const passwordField = (
    <Styled.FormTextField
      variant="outlined"
      margin="none"
      color="secondary"
      type={passwordVisible ? 'text' : 'password'}
      fullWidth
      required
      label={t('kaiser_signup.password')}
      id={KaiserSignupFields.PASSWORD}
      helperText={
        errors[KaiserSignupFields.PASSWORD] ? errors[KaiserSignupFields.PASSWORD]?.message : passwordHelperText
      }
      error={!!errors[KaiserSignupFields.PASSWORD]}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setPasswordVisible((visible) => !visible)}
            >
              {passwordVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...register(KaiserSignupFields.PASSWORD, { required: true })}
    />
  );

  const phoneHelperText = t('kaiser_signup.phone_helper_text');
  const phoneField = (
    <Styled.FormTextField
      variant="outlined"
      margin="none"
      type="text"
      color="secondary"
      required
      fullWidth
      autoFocus
      autoCapitalize="none"
      label={t('kaiser_signup.phone')}
      id={KaiserSignupFields.PHONE}
      helperText={errors[KaiserSignupFields.PHONE] ? errors[KaiserSignupFields.PHONE]?.message : phoneHelperText}
      error={!!errors[KaiserSignupFields.PHONE]}
      {...register(KaiserSignupFields.PHONE, { required: true })}
    />
  );

  const checkboxes = (
    <Box
      alignSelf="stretch"
      alignItems="flex-start"
      display="flex"
      flexDirection="column"
    >
      <ControlledCheckbox
        control={control}
        name={KaiserSignupFields.TERMS_OF_USE}
        label={(
          <Text fontSize="14px" lineHeight="20px" textAlign="left">
            {t('kaiser_signup.read_and_accept')}
            {' '}
            <Link.ButtonLink
              onClick={
                () => window.open('https://www.bighealth.com/spark-direct-privacy-policy/', '_blank', 'noopener')
              }
              underlined
            >
              {t('kaiser_signup.privacy_policy')}
            </Link.ButtonLink>
            {' & '}
            <Link.ButtonLink
              onClick={
                () => window.open('https://www.bighealth.com/spark-direct-terms-and-conditions/', '_blank', 'noopener')
              }
              underlined
            >
              {t('kaiser_signup.terms_of_use')}
            </Link.ButtonLink>
            .
          </Text>
        )}
        required
      />
      <ControlledCheckbox
        control={control}
        name={KaiserSignupFields.PROVIDER_ACK}
        label={(
          <Text fontSize="14px" lineHeight="20px" textAlign="left">
            {t('kaiser_signup.acknowledge_provider')}
          </Text>
        )}
        required
      />
    </Box>
  );

  return (
    <Box className={className}>
      <FormProvider {...formMethods}>
        <Styled.FormContainer>
          <Styled.HeaderTextContainer>
            <Text fontSize="24px" marginBottom="28px" fontWeight="600">
              {t('kaiser_signup.create_sparkrx_account')}
            </Text>
          </Styled.HeaderTextContainer>
          <Styled.FormContentContainer as="form" noValidate onSubmit={handleFormSubmit}>
            {firstNameField}
            {lastNameField}
            {emailField}
            {phoneField}
            {passwordField}
            {checkboxes}
            { !!generalError && <Text>{generalError}</Text> }
            <Styled.SubmitButton
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              disabled={submissionDisabled}
            >
              {t('kaiser_signup.submit')}
            </Styled.SubmitButton>
            <Text fontSize="14px" marginBottom="18px">
              {t('kaiser_signup.need_technical_support')}
            </Text>
            <Text fontSize="14px">
              {t('kaiser_signup.information_stored_hipaa')}
            </Text>
          </Styled.FormContentContainer>
        </Styled.FormContainer>
      </FormProvider>
    </Box>
  );
};

export default KaiserSignupForm;
