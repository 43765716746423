import styled from 'styled-components/macro';

const HoverContainer = styled.div`
    margin-bottom: 24px;
    &:hover {
        cursor: pointer;
    }
`;

export default {
  HoverContainer,
};
