import { Breadcrumbs as MuiBreadCrumbs } from '@material-ui/core';
import styled from 'styled-components';

const BreadCrumbs = styled(MuiBreadCrumbs)`
  margin-bottom: 12px;
`;

export default {
  BreadCrumbs,
};
