import React from 'react';

import { useTranslation } from 'react-i18next';

import { Box } from '@bighealth/react-limbix-ui';

import { Popover } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { CTAButton } from '../CTAButton/CTAButton';

import {
  Button,
  Paragraph,
  Title,
  SelectButton,
  MobilePopover,
  PopoverDesktopContainer,
  CloseButtonWrapper,
} from './LanguageSelector.styles';

import { EN, ES } from '@/utils/constants';

import { useWindowDimensions } from '@/hooks/useWindowDimensions';

import LanguageSvg from '@/assets/LanguageSvg.svg';

// TODO: Refactor popovers for general use and style
interface LanguagePopover {
  popoverOpen: boolean;
  setPopoverOpen: (arg0: boolean) => void;
}

const LanguagePopover: React.FC<LanguagePopover> = ({
  popoverOpen,
  setPopoverOpen,
  children,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  if (width <= 600 && popoverOpen === true) {
    return (
      <MobilePopover>
        <CloseIcon
          fontSize="large"
          style={{
            position: 'absolute',
            right: '24px',
            top: '32px',
          }}
          onClick={() => setPopoverOpen(false)}
        />
        {children}
        <CTAButton
          text={t('language_selector.confirm')}
          isDisabled={false}
          handleClick={() => setPopoverOpen(false)}
          fullWidth
        />
      </MobilePopover>
    );
  }
  return (
    <Popover
      style={{
        background: 'rgba(0, 0, 0, 0.30)',
      }}
      open={popoverOpen}
      onClose={() => setPopoverOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <PopoverDesktopContainer>
        <CloseButtonWrapper>
          <CloseIcon
            fontSize="large"
            style={{
              position: 'absolute',
              right: '24px',
              top: '32px',
            }}
            onClick={() => setPopoverOpen(false)}
          />
        </CloseButtonWrapper>
        {children}
        <CTAButton
          text={t('language_selector.confirm')}
          isDisabled={false}
          handleClick={() => setPopoverOpen(false)}
          fullWidth
        />
      </PopoverDesktopContainer>
    </Popover>
  );
};

export const LanguageSelector: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { width } = useWindowDimensions();
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const changeLanguage = (language: string) => {
    const value = language || EN;
    localStorage.setItem('language', value);
    i18n.changeLanguage(value);
  };
  let languageButton = (
    <Button onClick={() => setPopoverOpen(true)}>
      <img src={LanguageSvg} alt="Language" />
      <span>{t('language_selector.button_text')}</span>
    </Button>
  );
  if (width <= 600) {
    languageButton = (
      <Button onClick={() => setPopoverOpen(true)} style={{ minWidth: '32px', marginRight: '20px' }}>
        <img src={LanguageSvg} alt="Language" />
      </Button>
    );
  }
  return (
    <Box position="absolute" right="0">
      {languageButton}
      <LanguagePopover popoverOpen={popoverOpen} setPopoverOpen={setPopoverOpen}>
        <Box>
          <Title>{t('language_selector.modal_title')}</Title>
          <Paragraph>{t('language_selector.modal_description')}</Paragraph>
          <Box style={{
            display: 'flex', flexDirection: 'column', width: '100%', justifyItems: 'stretch',
          }}
          >
            <SelectButton isSelected={i18n.language === EN} onClick={() => changeLanguage(EN)}>
              {t('language_selector.choice_english')}
            </SelectButton>
            <SelectButton isSelected={i18n.language === ES} onClick={() => changeLanguage(ES)}>
              {t('language_selector.choice_spanish')}
            </SelectButton>
          </Box>
        </Box>
      </LanguagePopover>
    </Box>
  );
};
