import React from 'react';
import { Text } from '@bighealth/react-limbix-ui';

import Styled from './ListBoxWithIcons.styles';

interface ListBoxWithIconsProps {
    title: string;
    listItems: {
        icon: string;
        listText: string;
    }[];
}

export const ListBoxWithIcons: React.FC<ListBoxWithIconsProps> = ({
  title,
  listItems,
}) => (
  <Styled.WhatsNextBox>
    <Styled.Title>{title}</Styled.Title>
    {listItems.map((listItem) => (
      <Styled.ItemContainer key={listItem.listText}>
        <Styled.Icons src={listItem.icon} />
        <Text
          fontFamily="GT Walsheim"
          fontSize={18}
          fontWeight={400}
        >
          {listItem.listText}
        </Text>
      </Styled.ItemContainer>
    ))}
  </Styled.WhatsNextBox>
);
