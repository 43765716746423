import { DefaultTheme } from 'styled-components';

export const sparkTheme: DefaultTheme = {
  checkBox: {
    backgroundColor: '#F2F7F6',
    color: '#363334',
  },
  infoBox: {
    backgroundColor: '#F2F7F6',
    textColor: '#363334',
  },
  link: {
    color: '#00B894',
    fontWeight: 700,
  },
  button: {
    primary: {
      backgroundColor: '#067B65',
      disabledBackgroundColor: '#898989',
      textColor: '#FFFFFF',
      disabledTextColor: '#FFF',
    },
  },
  colors: {
    primaryNuetral: '#F7F6F5',
  },
};
