import React from 'react';

import { useTranslation } from 'react-i18next';
import { Text, Box } from '@bighealth/react-limbix-ui';

import Styled from './Header.styles';

import LimbixLogo from '@/assets/limbix_logo_white.svg';

import history from '@/utils/history';

type Props = {
  onClickLogout?: () => void;
  firstName: string;
}

const Header:React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const { onClickLogout, firstName } = props;

  const welcomeUser = (
    <Box margin="24px">
      <Text
        fontWeight="bold"
        color="white"
      >
        {window.innerWidth > 450
          && t('app.welcome', { firstName })}
      </Text>
    </Box>
  );

  return (
    <Styled.AppBar>
      <Styled.Toolbar>
        <Box margin="20px" textAlign="center">
          <Styled.Logo src={LimbixLogo} alt="limbix logo" onClick={() => { history.push('/'); }} />
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignContent="center"
        >
          {welcomeUser}
          <Styled.LogOutButton onClick={onClickLogout}>
            {t('app.logout.logout')}
          </Styled.LogOutButton>
        </Box>
      </Styled.Toolbar>
    </Styled.AppBar>
  );
};

export default Header;
