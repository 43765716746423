import { GraphQLError } from 'graphql';

const SERVER_EMAIL_ERROR = 'Enter a valid email address.';

export const isEmailError = (errors: readonly GraphQLError[]): boolean => {
  let hasEmailError = false;
  if (errors) {
    hasEmailError = errors.some((error) => error.message.includes(SERVER_EMAIL_ERROR));
  }
  return hasEmailError;
};
