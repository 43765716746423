import { Box } from '@bighealth/react-limbix-ui';
import styled from 'styled-components/macro';

const MobilePopover = styled(Box)`
    font-family: "GT Walsheim";
    background-color: #fff;
    position: fixed;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    top: 0;
    left: 0;
    z-index: 1000;
    font-size: 18px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 80px;
    padding-bottom: 60px;
    overflow: auto;
`;

const PopoverDesktopContainer = styled(Box)`
    padding-left: 74px;
    padding-right: 74px;
    padding-top: 100px;
    padding-bottom: 60px;
    max-width: 700px;
`;

const CloseButtonWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export default {
  MobilePopover,
  PopoverDesktopContainer,
  CloseButtonWrapper,
};
