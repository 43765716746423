import styled, { css } from 'styled-components';
import { Box } from '@bighealth/react-limbix-ui';

export const Button = styled.button`
    display: flex;
    width: fit-content;
    font-family: "GT Walsheim";
    padding: 8px 12px;
    border: none;
    background-color: ${(props) => props.theme.colors.primaryNuetral};
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    min-width: 180px;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    margin-right: 40px;
`;

export const SelectButton = styled.button<{isSelected?: boolean}>`
    display: flex;
    width: 100%;
    font-family: "GT Walsheim";
    padding: 16px;
    border: none;
    background-color: ${(props) => props.theme.colors.primaryNuetral};
    border-radius: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 8px;
    ${(props) => props.isSelected && css`
        border: 1px solid;
    `}
    &:hover {
        cursor: pointer;
    }
`;

export const Title = styled.p`
    font-size: 28px;
    font-family: "GT Walsheim";
    font-weight: 700;
    line-height: 110%;
`;
export const Paragraph = styled.p`
    font-size: 18px;
    font-family: "GT Walsheim";
    font-weight: 400;
    line-height: 135%;
`;

export const MobilePopover = styled(Box)`
    font-family: "GT Walsheim";
    background-color: #fff;
    position: fixed;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    top: 0;
    left: 0;
    z-index: 1000;
    font-size: 18px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 80px;
    padding-bottom: 60px;
    overflow: auto;
`;

export const PopoverDesktopContainer = styled(Box)`
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
    padding-bottom: 32px;
    max-width: 560px;
`;

export const CloseButtonWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
