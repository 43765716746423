import styled from 'styled-components';
import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
} from '@material-ui/core';

const LogOutButton = styled.button`
  color: white;
  margin: 24px;
  background: none !important;
  border: none;
  cursor: pointer;
  font-size: .9rem;
  display: inline;
  text-align: left;
`;

const AppBar = styled(MuiAppBar)`
  background-color: #1f2e81;  
  width: 100vw;
  z-index: 1201;
  position: absolute;
`;

const Toolbar = styled(MuiToolbar)`
  background-color: #1f2e81;  
  color: black;
  padding: 0;
  z-index: 1201;
`;

const Logo = styled.img`
  height: 24px;
  width: 113px;
  border-radius: 0px;
  cursor: pointer;
`;

export default {
  LogOutButton,
  Toolbar,
  AppBar,
  Logo,
};
