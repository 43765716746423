import styled from 'styled-components';

const CircleIcon = styled.span`
  height: 6px;
  width: 6px;
  /* Spark/Jade/1 */
  background: #00b894;
  border-radius: 33px;
  margin-right: 12px;
`;

const AssessmentTable = styled.table`
  background: #fefefe;
`;

export default {
  CircleIcon,
  AssessmentTable,
};
