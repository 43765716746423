import { Box } from '@bighealth/react-limbix-ui';
import styled from 'styled-components';

const WhatsNextBox = styled(Box)`
    background-color: ${({ theme }) => theme.infoBox.backgroundColor};
    border-radius: 8px;
    max-width: 541px;
    margin: auto;
    padding: 16px;
    padding-bottom: none;
    margin-bottom: 40px;
`;

const Title = styled(Box)`
    font-family: "GT Walsheim";
    color: ${({ theme }) => theme.infoBox.textColor};
    font-size: 18px;
    font-weight: 600;
    line-height: 24.3px;
    word-wrap: break-word;
    margin-bottom: 16px;
    text-align: center;
`;

const ItemContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
`;
const Icons = styled.img`
    width: 48px;
`;

export default {
  WhatsNextBox,
  Title,
  ItemContainer,
  Icons,
};
