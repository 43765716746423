import React from 'react';

import { useTranslation } from 'react-i18next';

import { ListBoxWithIcons } from '../../components/ListBoxWithIcons/ListBoxWithIcons';

import SleepioDownloadIcon from '@/assets/SleepioDownloadIcon.svg';
import SleepioAccountIcon from '@/assets/SleepioAccountIcon.svg';
import SleepioMailIcon from '@/assets/SleepioMailIcon.svg';
import SleepioZzzIcon from '@/assets/SleepioZzzIcon.svg';

export const SleepioWhatsNext = () => {
  const { t } = useTranslation();
  return (
    <ListBoxWithIcons
      title={t('coverage.sleepio.patient.coverage_confirmation.list_title')}
      listItems={[
        {
          icon: SleepioMailIcon,
          listText: t('coverage.sleepio.patient.coverage_confirmation.list_item0'),
        },
        {
          icon: SleepioDownloadIcon,
          listText: t('coverage.sleepio.patient.coverage_confirmation.list_item1'),
        },
        {
          icon: SleepioAccountIcon,
          listText: t('coverage.sleepio.patient.coverage_confirmation.list_item2'),
        },
        {
          icon: SleepioZzzIcon,
          listText: t('coverage.sleepio.patient.coverage_confirmation.list_item3'),
        },
      ]}
    />
  );
};
