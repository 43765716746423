import styled from 'styled-components/macro';

const RadioButtonContainer = styled.div<{$checked: boolean}>`
    display: flex;
    background-color: ${(props) => (props.$checked ? '#F2F7F6' : '#FFFFFF')};
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 12px;
    border: ${(props) => (props.$checked ? '1px solid #FFFFFF' : '1px solid #DFE1E6')};
`;

const RadioInput = styled.input`
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 20px;
    height: 20px;
    border: 2px solid #067B65;
    border-radius: 50%;
    transform: translateY(2px);
    display: grid;
    place-content: center;
    &::before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #067B65;
    }
    &:checked::before {
        transform: scale(1);
    }
`;

const RadioInputLabel = styled.label<{$checked: boolean}>`
    margin-left: 8px;
    font-size: 18px;
    font-weight: 400;
    color: ${(props) => (props.$checked ? '#067B65' : 'black')};
`;

export default {
  RadioInput,
  RadioButtonContainer,
  RadioInputLabel,
};
