import {
  DOB_VALIDATION_REGEX,
  EMAIL_VALIDATION_REGEX,
  PHONE_VALIDATION_REGEX,
  ZIPCODE_VALIDATION_REGEX,
} from './constants';

// *** VALIDATORS ***
export const validateEmail = (
  email: string,
): boolean => EMAIL_VALIDATION_REGEX.test(email); // eslint-disable-line

export const validatePhoneNumber = (phoneNumber: string): boolean => PHONE_VALIDATION_REGEX.test(phoneNumber); // eslint-disable-line

// TODO: maybe detect where they are from and use the correct regex for it? IDK.
export const validateDOB = (
  dob: string,
  // birthdays in MM/DD/YYYY
  ): boolean => DOB_VALIDATION_REGEX.test(dob); // eslint-disable-line

export const validateZipCode = (zipCode: string): boolean => ZIPCODE_VALIDATION_REGEX.test(zipCode);

// *** TYPE GAURDS ***
// maybe this is overkill but better safe than sorrrrrrry...
type oneToNine = 1|2|3|4|5|6|7|8|9;
type zeroToNine = 0|1|2|3|4|5|6|7|8|9;
type YYYY = `19${zeroToNine}${zeroToNine}` | `20${zeroToNine}${zeroToNine}`;
type MM = `0${oneToNine}` | `1${0|1|2}`;
type DD = `${0}${oneToNine}` | `${1|2}${zeroToNine}` | `3${0|1}`
export type MMDDYYYYDateType = `${MM}/${DD}/${YYYY}`;

export const isValidMMDDYYYYDate = (str: string): str is MMDDYYYYDateType => validateDOB(str);

export const getAge = (birthDateString: string) => {
  const today = new Date();
  const birthDate = new Date(birthDateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    --age; // eslint-disable-line
  }
  return age;
};
