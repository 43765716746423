import React, { CSSProperties } from 'react';
import {
  Text,
} from '@bighealth/react-limbix-ui';
import { useTranslation } from 'react-i18next';

import Styled from './KaiserSignupPage.styles';

type Props = {
  style?: CSSProperties;
};

export default ({ style }: Props) => {
  const { t } = useTranslation();
  return (
    <Styled.LabelingTextContainer style={style}>
      <Text fontWeight="600" marginBottom="16px">
        {t('kaiser_signup.labeling.safety_information')}
      </Text>
      <Text marginBottom="16px">
        {t('kaiser_signup.labeling.disclaimer')}
      </Text>
      <Text marginBottom="16px">
        <span style={{ fontWeight: 600 }}>{t('kaiser_signup.labeling.spark_direct_is')}</span>
        {' '}
        {t('kaiser_signup.labeling.talk_to_provider')}
      </Text>
      <Text marginBottom="16px">
        <span style={{ fontWeight: 600 }}>{t('kaiser_signup.labeling.reaching_out')}</span>
        {' '}
        {t('kaiser_signup.labeling.provider_will_not_see')}
      </Text>
      <Text marginBottom="16px">
        <span style={{ fontWeight: 600 }}>{t('kaiser_signup.labeling.keeping_you_safe')}</span>
        {' '}
        {t('kaiser_signup.labeling.not_designed_to_treat')}
      </Text>
      <Text marginBottom="16px">
        {t('kaiser_signup.labeling.you_can_also_call')}
      </Text>
      <Text marginBottom="16px">
        <span style={{ fontWeight: 600 }}>{t('kaiser_signup.labeling.protecting_your_privacy')}</span>
        {' '}
        {t('kaiser_signup.labeling.things_you_share')}
      </Text>
    </Styled.LabelingTextContainer>
  );
};
