import React from 'react';

import { Text } from './ErrorText.styles';

type Props = {
  error: string;
}

export const ErrorText: React.FC<Props> = ({ error }) => (
  <Text>
    {error}
  </Text>
);
