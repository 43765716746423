import { IdleTimerPayloadType } from './DataTypes';

export enum GlobalActionTypes {
  ClearStore = 'CLEAR_STORE',
}

export type GlobalAction = {
  type: GlobalActionTypes;
}

/* Navigation Action/Reducer Types */
export type NavigationType = {
  isBlocked: boolean;
  listeners: Array<keyof WindowEventMap>,
  message?: string;
};

export type ModalPayloadType = {
  NONE: null;
  IDLE_WARNING: IdleTimerPayloadType;
  GENERIC_WARNING: {
    primaryButtonText: string;
    secondaryButtonText: string;
    onClickPrimaryButton?: () => void;
    onClickSecondaryButton?: () => void;
    headerText?: string;
    body?: React.ReactNode;
  };
};

export type ModalContentType = keyof ModalPayloadType;

export type UseModalReturnType = {
  showModal: (modalType: ModalContentType, payload: ModalPayloadType[ModalContentType]) => void,
  hideModal: () => void,
}
