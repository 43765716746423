import React from 'react';
import {
  Route,
  Switch,
  RouteComponentProps,
} from 'react-router-dom';
import { Box } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { Home, Assessment } from '@/pages';

import BreadCrumbs from '@/components/Breadcrumbs/Breadcrumbs';

const Routes: React.FC = () => {
  const { t } = useTranslation();
  const commonRoutes = [
    {
      path: '/',
      component: Home,
      name: t('home.home'),
    },
    {
      path: '/:assignmentUid',
      component: Assessment,
      name: t('home.assessment'),
    },
  ];

  type ComponentType = React.FC<Partial<RouteComponentProps>>;

  type BreadCrumbType = {
    path: string,
    component: ComponentType,
    name: string,
  }

  const renderComponent = (Component: ComponentType, routeProps: RouteComponentProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Component {...routeProps} />
  );

  const makeBreadCrumbs = () => (
    (commonRoutes as BreadCrumbType[]).map((route) => (
      <Route
        exact
        path={route.path}
        key={route.name}
        render={(routeProps) => {
          const breadcrumbs = commonRoutes
            // Get all routes that contain the current one.
            .filter(({ path }) => routeProps.match.path.includes(path))
            // Swap out any dynamic routes with their param values.
            .map(({ path, name }) => ({
              path: Object.keys(routeProps.match.params).length
                ? Object.keys(routeProps.match.params).reduce(
                  (_path, param) => _path.replace(
                    `:${param}`, routeProps.match.params[param],
                  ), path,
                )
                : path,
              name,
            }));

          return (
            <Box paddingTop="12px">
              <BreadCrumbs breadcrumbs={breadcrumbs} />
              {renderComponent(route.component, routeProps)}
            </Box>
          );
        }}
      />
    )));

  return (
    <Switch>
      {makeBreadCrumbs()}
    </Switch>
  );
};

export default Routes;
