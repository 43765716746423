import {
  Button as MuiButton,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';
import styled from 'styled-components/macro';

const DialogContent = styled(MuiDialogContent)`
  padding: 32px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const NoButton = styled(MuiButton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  height: 32px;
  background: #FEFEFE;
  border: 1px solid #DADADD;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 4px;
`;

const YesButton = styled(MuiButton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  height: 32px;
  background: ${(props) => (props.disabled ? '#999999' : '#1F2E81')};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 4px;
  :hover {
    background: #303F92;
  }
`;

export default {
  DialogContent,
  NoButton,
  YesButton,
};
