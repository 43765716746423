import React from 'react';

import Styled from './RadioButton.styles';

interface RadioButtonProps {
    name: string;
    value: string;
    textValue: string;
    checked?: boolean
    onSelect: (arg1: any) => typeof arg1; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  name,
  value,
  checked,
  textValue,
  onSelect,
}) => (
  <Styled.RadioButtonContainer $checked={checked}>
    <Styled.RadioInput
      type="radio"
      checked={checked}
      id={name + value}
      name={name}
      value={value}
      onChange={(e) => {
        onSelect(e.target.value);
      }}
    />
    <Styled.RadioInputLabel htmlFor={name + value} $checked={checked}>{textValue}</Styled.RadioInputLabel>
  </Styled.RadioButtonContainer>
);
