import { isValidPhoneNumber } from 'react-phone-number-input';

import { OnboardingFormData } from '../../defaultFormData';

import { validateEmail } from '@/utils/stringValidators';

const validateSparkPatientInfoForm = (onboardingFormData: Partial<OnboardingFormData>): boolean => {
  if (onboardingFormData.personEnrolling === 'patient') {
    return validateEmail(onboardingFormData.patientEmail)
            && isValidPhoneNumber(onboardingFormData.patientPhoneNumber)
            && onboardingFormData.patientStreetAddress !== ''
            && onboardingFormData.patientCity !== ''
            && onboardingFormData.patientState !== ''
            && onboardingFormData.safetyAcknowledgement === true
            && onboardingFormData.policyAndTermsAcknowledgement === true;
  }
  return validateEmail(onboardingFormData.patientEmail)
            && isValidPhoneNumber(onboardingFormData.patientPhoneNumber)
            && onboardingFormData.patientStreetAddress !== ''
            && onboardingFormData.patientCity !== ''
            && onboardingFormData.patientState !== '';
};

export default validateSparkPatientInfoForm;
