import React, { CSSProperties, useCallback, useState } from 'react';
import {
  Link, LoadingSpinner, Text,
} from '@bighealth/react-limbix-ui';
import { useTranslation } from 'react-i18next';

import Styled from './KaiserSignupPage.styles';

import { RESEND_VERIFICATION_EMAIL } from '@/apollo/mutations';
import VerifyEmailGraphic from '@/assets/VerifyEmailGraphic.svg';
import { useMutation } from '@/hooks/apollo';
import { MutationResendEmailVerificationArgs } from '@/apollo/types';

type Props = {
  email: string;
  style?: CSSProperties;
};

export default (props: Props) => {
  const { email, style } = props;

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(false);
  const [resendVerificationEmail] = useMutation<MutationResendEmailVerificationArgs>(RESEND_VERIFICATION_EMAIL);

  const onClickResendEmail = useCallback(async () => {
    setLoading(true);
    const result = await resendVerificationEmail({ variables: { email } });
    if (result?.errors || !result?.data?.resendEmailVerification?.success) {
      setError(true);
    } else {
      setEmailSent(true);
    }
    setLoading(false);
  }, [email]);

  let resendEmailComponent = (
    <Link.ButtonLink onClick={onClickResendEmail}>
      {t('kaiser_signup.resend_email')}
    </Link.ButtonLink>
  );
  if (loading) {
    resendEmailComponent = <LoadingSpinner />;
  } else if (error) {
    resendEmailComponent = (
      <Text color="red">
        {t('kaiser_signup.resend_email_error')}
      </Text>
    );
  } else if (emailSent) {
    resendEmailComponent = (
      <Text>
        {t('kaiser_signup.email_sent')}
      </Text>
    );
  }

  return (
    <Styled.MainContainer style={style}>
      <Styled.MainContentContainer>
        <Styled.VerifyEmailGraphic src={VerifyEmailGraphic} alt="signup-success-graphic" />
        <Text textAlign="center" fontSize="32px" fontWeight={700} marginBottom="16px">
          {t('kaiser_signup.almost_done_verify_email')}
        </Text>
        <Text marginBottom="124px" fontSize="20px" textAlign="center">
          {t('kaiser_signup.check_email', { email })}
        </Text>
        { resendEmailComponent }
        <Text fontSize="14px" textAlign="center">
          {t('kaiser_signup.cant_find_email')}
        </Text>
      </Styled.MainContentContainer>
    </Styled.MainContainer>
  );
};
