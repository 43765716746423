import React, { useContext, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { CTAButton } from '../../components/CTAButton/CTAButton';
import { Titles } from '../../components/Titles/Titles';
import { RadioButton } from '../../components/RadioButton/RadioButton';
import { OnboardingFlowContext } from '../../OnboardingFlowContext';
import { defaultCaregiverFlow, defaultPatientFlow } from '../../userFlows';
import { OnboardingParams } from '../../types';

export const WhoAreYouEnrolling: React.FC = () => {
  const {
    next, updateFormData, onboardingFormData, setSteps, resetFormData,
  } = useContext(OnboardingFlowContext);
  const { product } = useParams<OnboardingParams>();
  const { t } = useTranslation();

  useEffect(() => {
    if (onboardingFormData.personEnrolling === 'caregiver') {
      setSteps(defaultCaregiverFlow);
    } else {
      setSteps(defaultPatientFlow);
    }
  });

  return (
    <div style={{ marginBottom: '40px' }}>
      <Titles
        title={t(`coverage.${product}.person_enrolling.title`)}
        subtitle={t(`coverage.${product}.person_enrolling.subtitle`)}
      />
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <RadioButton
          checked={onboardingFormData.personEnrolling === 'patient'}
          name="enrollmentPerson"
          value="patient"
          textValue={t(`coverage.${product}.person_enrolling.myself`)}
          onSelect={(selection) => {
            resetFormData();
            updateFormData({ personEnrolling: selection });
          }}
        />
        <RadioButton
          checked={onboardingFormData.personEnrolling === 'caregiver'}
          name="enrollmentPerson"
          value="caregiver"
          textValue={t(`coverage.${product}.person_enrolling.dependent`)}
          onSelect={(selection) => {
            resetFormData();
            updateFormData({ personEnrolling: selection });
          }}
        />
      </div>
      <CTAButton
        text={t('coverage.check_coverage')}
        handleClick={next}
        isDisabled={onboardingFormData.personEnrolling === undefined}
      />
    </div>
  );
};
