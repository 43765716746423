import React, { useState } from 'react';
import { TextField, useMediaQuery } from '@material-ui/core';
import { Text, Link, Box } from '@bighealth/react-limbix-ui';
import { useTranslation } from 'react-i18next';

import Styled from './Login.styles';

import { useMutation } from '@/hooks/apollo';
import { MutationTokenAuthArgs, AssignmentTypeOfClient } from '@/apollo/types';
import { REQUEST_TOKEN_MUTATION } from '@/apollo/mutations';

import { DEFAULT_LANGUAGE } from '@/utils/translate';
import { stringToBase64 } from '@/utils/stringUtils';
import history from '@/utils/history';
import { getErrorStringKey } from '@/utils/errorUtils';

import LimbixLogo from '@/assets/limbix_logo_white.svg';
import LoginPageGraphic from '@/assets/LoginPageGraphic.svg';

import { AppVersion } from '@/components';

const Login: React.FC = () => {
  const [formValues, setFormValues] = useState({ email: '', password: '' });
  const [errorString, setErrorString] = useState<string>();
  const [fieldError, setFieldError] = useState({ field: '', message: '' });
  const [requestToken] = useMutation<MutationTokenAuthArgs>(
    REQUEST_TOKEN_MUTATION, { fetchPolicy: 'no-cache', errorPolicy: 'all' },
  );
  const isMobile = useMediaQuery('(max-width:600px)');
  const { t } = useTranslation();

  const handleSubmitForm = async () => {
    const { email, password } = formValues;
    setFieldError({ field: '', message: '' });
    if (!email) {
      setFieldError({ field: 'email', message: t('login.please_enter_email') });
      return;
    } if (!password) {
      setFieldError({ field: 'password', message: t('login.please_enter_password') });
      return;
    }
    const { errors: resultErrors, data } = await requestToken({
      variables: {
        email,
        password,
        clientType: AssignmentTypeOfClient.WebPortal,
      },
    });

    if (resultErrors && resultErrors?.length > 0 && resultErrors[0]?.message) {
      setErrorString(t(getErrorStringKey(resultErrors)));
    } else if (data?.tokenAuth) {
      const { token, refreshToken, user } = data.tokenAuth;
      if (user && !user.emailVerified) {
        setErrorString(t('errors.email_not_verified'));
        return;
      }
      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
      }
      if (token) {
        const uid = user?.uid || '';
        const firstName = user?.firstName || '';
        const language = user?.language || DEFAULT_LANGUAGE;
        localStorage.setItem('tokenCreatedAt', stringToBase64(`${(new Date()).getTime()}`));
        localStorage.setItem('token', token);
        localStorage.setItem('uid', uid);
        localStorage.setItem('language', language);
        localStorage.setItem('firstName', firstName);
        history.push('/');
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmitForm();
    }
  };

  return (
    <Styled.MainContainer>
      <Styled.LeftColumn>
        <Styled.LeftColumnContentContainer>
          <Styled.LimbixLogo src={LimbixLogo} alt="limbix-logo" />
          <Box display="flex" marginBottom="27px">
            <Text
              color="white"
              fontSize={isMobile ? '36px' : '52px'}
              lineHeight={isMobile ? '48px' : '64px'}
              fontWeight="700"
            >
              {t('login.portal_name')}
            </Text>
          </Box>
          <Text
            color="white"
            fontSize={isMobile ? '20px' : '25px'}
            lineHeight={isMobile ? '30px' : '35px'}
            fontWeight="400"
          >
            {t('login.portal_blurb')}
          </Text>
        </Styled.LeftColumnContentContainer>
        {!isMobile && (
          <img src={LoginPageGraphic} alt="limbix-logo" style={{ width: '100%' }} />
        )}
      </Styled.LeftColumn>
      <Styled.RightColumn>
        <Styled.RightColumnContentContainer>
          <Styled.LoginHeaderContainer>
            <Text fontSize="32px" lineHeight="44px" fontWeight="700">
              {t('login.login')}
            </Text>
          </Styled.LoginHeaderContainer>
          <form noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('login.email_address')}
              name="email"
              error={fieldError.field === 'email'}
              helperText={fieldError.field === 'email' ? fieldError.message : ''}
              autoFocus
              onKeyDown={handleKeyDown}
              onChange={(event) => setFormValues({
                ...formValues,
                email: event.target.value,
              })}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('login.password')}
              type="password"
              error={fieldError.field === 'password'}
              helperText={fieldError.field === 'password' ? fieldError.message : ''}
              id="password"
              onKeyDown={handleKeyDown}
              onChange={(event) => setFormValues({
                ...formValues,
                password: event.target.value,
              })}
            />
            <Text color="red">
              {errorString}
            </Text>
            <Styled.SubmitButton
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleSubmitForm()}
            >
              {t('login.login')}
            </Styled.SubmitButton>
            <Box marginBottom="16px">
              <Link.RouterLink
                to="/reset-password"
                underlined
              >
                {t('login.forgot_password')}
              </Link.RouterLink>
            </Box>
            <Text color="#4C4D4F">
              {t('login.forgot_email_address')}
            </Text>
          </form>
        </Styled.RightColumnContentContainer>
        <Styled.BottomContainer>
          <Styled.LinksContainer>
            <Styled.BottomContainerLink
              target="_blank"
              href="https://www.bighealth.com/spark-direct-privacy-policy/"
              rel="noreferrer"
            >
              {t('login.privacy_policy')}
            </Styled.BottomContainerLink>
            <Styled.BottomContainerLink
              target="_blank"
              href="https://www.bighealth.com/spark-direct-terms-and-conditions/"
              rel="noreferrer"
            >
              {t('login.terms_of_use')}
            </Styled.BottomContainerLink>
          </Styled.LinksContainer>
          <AppVersion />
        </Styled.BottomContainer>
      </Styled.RightColumn>
    </Styled.MainContainer>
  );
};

export default Login;
