import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Text } from '@bighealth/react-limbix-ui';
import { generate } from 'short-uuid';

import { ThemeProvider } from 'styled-components';

import { useQuery } from '@apollo/client';

import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { OnboardingFlowContext, OnboardingFlowProvider } from './OnboardingFlowContext';
import Styled from './OnboardingPage.styles';
import { OnboardingParams } from './types';
import { getPdtUid, getTheme } from './utils/productHelpers';
import { defaultCaregiverFlow, defaultSleepioFlow } from './userFlows';

import { SPARK_PRODUCT } from './utils/constants';

import { ELIGIBILITY_CONFIGURATION_QUERY } from '@/apollo/queries';
import { useCookieConsent } from '@/hooks/useCookieConsent';

const OnboardingPage: React.FC = () => {
  useCookieConsent();
  const { slug, product } = useParams<OnboardingParams>();
  const {
    currentStepIndex,
    currentStep,
    steps,
    setUid,
    setSteps,
    eligibilityFormCustomization,
    setEligibilityFormCustomization,
  } = useContext(OnboardingFlowContext);

  const {
    loading,
    data,
  } = useQuery(ELIGIBILITY_CONFIGURATION_QUERY, {
    variables: {
      organizationSlug: slug,
      pdtUid: getPdtUid(product),
    },
  });

  useEffect(() => {
    if (data?.eligibilityConfiguration.isValid) {
      setUid(generate());
      setSteps(product === SPARK_PRODUCT ? defaultCaregiverFlow : defaultSleepioFlow);
      setEligibilityFormCustomization({
        ...eligibilityFormCustomization,
        showEmployeeId: data?.eligibilityConfiguration.hasEmployeeId,
        employeeIdFieldRename: data?.eligibilityConfiguration.employeeIdFieldRename,
      });
    } else {
      setSteps([]);
    }
  }, [data?.eligibilityConfiguration]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    return false;
  };

  if (loading) {
    return null;
  }

  return (
    <Styled.PageContainer>
      {steps.length === 0 ? (
        <Text
          marginTop="100px"
          fontSize="48px"
        >
          {/* TODO: This isn't the best error message.
          Talk to product and design about this
          error message when the slug isn't found */}
          {'It looks like we don\'t have an eligibility flow for this org yet... check your url'}
        </Text>
      ) : (
        <>
          <Header
            currentStepIndex={currentStepIndex}
            currentStep={currentStep}
            steps={steps}
          />
          <Styled.PageContentContainer>
            <Styled.StepContainer>
              <form onSubmit={(e) => handleSubmit(e)}>
                {currentStep.element}
              </form>
            </Styled.StepContainer>
          </Styled.PageContentContainer>
        </>
      )}
      <Footer />
    </Styled.PageContainer>
  );
};

// There wasn't a good place to add this context so it's add here, as a wrapper around the OnboardingPage
export const OnboardingPageWithProvider: React.FC = () => {
  const { product } = useParams<OnboardingParams>();
  return (
    <ThemeProvider theme={getTheme(product)}>
      <OnboardingFlowProvider>
        <OnboardingPage />
      </OnboardingFlowProvider>
    </ThemeProvider>
  );
};
