import { sha256 } from 'js-sha256';

import { envSwitch } from '@/utils/environmentUtils';

declare global {
  interface Window {
    heap: {
      load?: (id: string) => void;
      identify?: (email: string) => void;
      addUserProperties?: (properties: Record<string, string>) => void;
      loaded?: boolean;
    };
  }
}

const heapId = envSwitch({
  development: '',
  dev: '',
  testing: '',
  stage: '3070449590',
  stable: '',
  prod: '4157575057',
  pr: '',
});

export const initializeHeap = () => {
  if (window?.heap?.load && heapId) {
    window.heap.load(heapId);
  }
};

export const identifyUser = (email: string) => {
  if (window?.heap?.identify) {
    const hashedEmail = sha256(email);
    window.heap.identify(hashedEmail);
  }
};

export const addUserProperties = (properties: Record<string, string>) => {
  if (window?.heap?.addUserProperties) {
    window.heap.addUserProperties(properties);
  }
};
