import React, { CSSProperties } from 'react';
import {
  Link, Text,
} from '@bighealth/react-limbix-ui';
import { useTranslation } from 'react-i18next';

import Styled from './KaiserSignupPage.styles';

import SignupSuccessGraphic from '@/assets/SignupSuccessGraphic.svg';

type Props = {
  email: string;
  style?: CSSProperties;
};

export default ({ email, style }: Props) => {
  const { t } = useTranslation();
  return (
    <Styled.MainContainer style={style}>
      <Styled.MainContentContainer>
        <Styled.AccountCreatedGraphic src={SignupSuccessGraphic} alt="signup-success-graphic" />
        <Styled.AccountCreatedHeaderText>
          {t('kaiser_signup.log_in_with_email', { email })}
        </Styled.AccountCreatedHeaderText>
        <Text fontSize="20px" textAlign="center">
          {t('kaiser_signup.still_need_to_download_sparkrx')}
          {' '}
          <Link.ButtonLink
            onClick={() => window.open(
              'https://www.sparkrx.com/kaiser-welcome',
              '_blank',
              'noopener',
            )}
            fontSize="20px"
          >
            {t('kaiser_signup.click_here')}
          </Link.ButtonLink>
          .
        </Text>
      </Styled.MainContentContainer>
    </Styled.MainContainer>
  );
};
